import { inject as service } from '@ember/service';
import { readOnly, not } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import configValue from 'gigshq/computed-macros/config-value';
import CropTypes from 'gigshq/constants/crop-types';

export default Component.extend(Localizable(translations), {
  propTypes: {
    createCropMutation: T.object.isRequired,
    selectedImage: T.object.isRequired,
    goToNextStep: T.func.isRequired,
    createCrop: T.func.isRequired,
    finish: T.func.isRequired,
    resetImageSelection: T.func.isRequired
  },

  flashMessages: service('flash-messages'),

  cropRatio: configValue('CropTool.SECOND_CROP_RATIO'),

  cannotGoToOtherSteps: readOnly(
    'createCropMutation.isPending',
    'imageCropperIsNotReady'
  ),
  imageCropperIsReady: false,
  imageCropperIsNotReady: not('imageCropperIsReady'),

  actions: {
    updateCrop({crop}) {
      this.set('crop', crop);
    },

    saveAndClose() {
      const cropType = CropTypes.SQUARE;
      const crop = this.crop;
      const imageId = this.get('selectedImage.id');

      this.createCrop({cropType, imageId, ...crop})
        .then(() => this.finish())
        .catch(() =>
          this.flashMessages.error(
            this.localTranslation('errors.could_not_create_crop')
          )
        );
    },

    onImageCropperReady() {
      this.set('imageCropperIsReady', true);
    },

    onImageCropperError() {
      this.resetImageSelection();
      this.flashMessages.error(
        this.localTranslation('errors.could_not_load_image')
      );
    }
  }
});
