import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  fastboot: service(),
  router: service(),

  beforeModel() {
    const userAgent = this.fastboot.isFastBoot
      ? this.fastboot.request.headers.get('user-agent')
      : navigator.userAgent;

    // We try to match only cell phones as the map is still usable on most
    // tablets in landscape orientation.
    // For iOS it’s pretty simple, they have one model of cell phone "iPhone".
    // On Android, tablets don’t advertise themselves as "mobile" so we assume
    // that those that do are cell phones.
    const isMobile = userAgent.match(/iPhone|Android.+Mobile/);

    this.router.replaceWith(isMobile ? 'web.events.index.list' : 'web.events.index.map');
  },
});
