import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    data: T.nullable(T.object).isRequired,
    userId: T.string.isRequired,
    firstName: T.string.isRequired,
    lastName: T.string.isRequired,
    email: T.string.isRequired,
    locale: T.string.isRequired,
    save: T.func.isRequired,
    setFormValue: T.func.isRequired,
    setMemberField: T.func.isRequired,
    resetForm: T.func.isRequired
  },

  mascotState: computed('isDirty', 'errors', 'data', 'userId', function() {
    if (!this.isDirty && this.userId && this.data)
      return AssetsCatalog.GigiStates.THUMBS_UP.key;
    if (this.errors) return AssetsCatalog.GigiStates.PERPLEX.key;
    return AssetsCatalog.GigiStates.NEUTRAL.key;
  }),

  actionBoxText: computed('errors', function() {
    if (this.errors) {
      return this.localTranslation('action_box.error_text');
    }

    return this.localTranslation('action_box.editing_text');
  })
});
