import { computed } from '@ember/object';

export default (property, value) => {
  return computed(property, function() {
    const array = this.get(property);

    if (!Array.isArray(array)) return false;

    return array.includes(value);
  });
};
