import { inject as service } from '@ember/service';
import { bool, notEmpty, not } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';
import config from 'gigshq/config/environment';
import pagination from 'gigshq/constants/pagination';

const LIST_CLASS_NAME = '.gigs-tri-column-layout';

export default Component.extend(LocationStyleable, Localizable(translations), {
  flashMessages: service('flash-messages'),
  router: service(),

  propTypes: {
    groupedEvents: T.array.isRequired,
    onSendNotificationClick: T.func.isRequired,
    onDeleteClick: T.func.isRequired,
    onDuplicateEvent: T.func.isRequired,
    onCancelEvent: T.func.isRequired
  },

  classNameBindings: ['isEmpty:empty'],

  eventCancellationModalEventId: null,
  eventCancellationModalIsOpened: bool('eventCancellationModalEventId'),

  eventDuplicationModalEvent: null,
  eventDuplicationModalIsOpened: bool('eventDuplicationModalEvent'),

  eventNotificationModalEvent: null,
  eventNotificationModalIsOpened: bool('eventNotificationModalEvent'),

  hasEvents: notEmpty('groupedEvents'),
  isEmpty: not('hasEvents'),

  init() {
    this._super(...arguments);

    this.onScroll = this.onScroll.bind(this);
  },

  didInsertElement() {
    document
      .querySelector(LIST_CLASS_NAME)
      .addEventListener('scroll', this.onScroll);
  },

  willDestroyElement() {
    document
      .querySelector(LIST_CLASS_NAME)
      .removeEventListener('scroll', this.onScroll);
  },

  onScroll() {
    if (this.isLoading || !this.onBottomReached) return;

    const listElement = document.querySelector(LIST_CLASS_NAME);
    const scrollTop = listElement.scrollTop;
    const innerHeight = listElement.offsetHeight;
    const scrollHeight = listElement.scrollHeight;

    if (scrollTop + innerHeight >= scrollHeight - pagination.TRIGGER_FROM_BOTTOM_DISTANCE)
      this.onBottomReached();

  },

  actions: {
    displayEventCancellationModal(eventId) {
      this.set('eventCancellationModalEventId', eventId);
    },

    handleEventCancellationSuccess() {
      this.flashMessages.success(this.localTranslation('cancellation.success'));

      this.onCancelEvent();

      this.set('eventCancellationModalEventId', null);
    },

    handleEventCancellationFailure(error) {
      this.flashMessages.error(
        this.localTranslation(`cancellation.errors.${error}`)
      );
    },

    hideEventCancellationModal() {
      this.set('eventCancellationModalEventId', null);
    },

    displayEventDuplicationModal(event) {
      this.set('eventDuplicationModalEvent', event);
    },

    hideEventDuplicationModal() {
      this.set('eventDuplicationModalEvent', null);
    },

    handleEventDuplicationSuccess() {
      this.flashMessages.success(this.localTranslation('duplication.success'));

      this.onDuplicateEvent();

      this.set('eventDuplicationModalEvent', null);
    },

    handleEventDuplicationFailure(error) {
      this.flashMessages.error(
        this.localTranslation(`duplication.errors.${error}`)
      );
    },

    handleSendNotificationClick({id}) {
      this.onSendNotificationClick(id);
      this.set('eventNotificationModalEvent', null);
    },

    displayEventNotificationModal(event) {
      if (this._shouldShowNotificationModal(event.lastEventEmailSentAt)) {
        this.set('eventNotificationModalEvent', event);
      } else {
        this.onSendNotificationClick(event.id);
      }
    },

    hideEventNotificationModal() {
      this.set('eventNotificationModalEvent', null);
    }
  },

  _shouldShowNotificationModal(lastEventEmailSentAt) {
    const nextAvailableNotificationMoment = moment(lastEventEmailSentAt).add(
      config.APP.MIN_NOTIFICATION_DELAY_IN_MINUTES,
      'minutes'
    );
    const now = moment();

    return nextAvailableNotificationMoment.isAfter(now);
  }
});
