import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isPending: T.bool.isRequired,
    isSuccess: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    onSubmit: T.func.isRequired
  },

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  shouldDisplayInvalidTokenError: computed('errors.[]', function() {
    return this.errorsByInputName.resetPasswordToken !== undefined;
  }),

  actions: {
    updatePassword(password) {
      this.set('password', password);
    },

    updatePasswordConfirmation(password) {
      this.set('passwordConfirmation', password);
    },

    submit() {
      const { password, passwordConfirmation } = this;

      this.onSubmit({ password, passwordConfirmation }).then(data => {
        if (data === undefined) return;

        this._resetForm();
      });
    }
  },

  _resetForm() {
    this.setProperties({
      password: '',
      passwordConfirmation: ''
    });
  }
});
