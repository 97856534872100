import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';

// Actions
import {
  resetForm,
  saveArtist,
  updateGenres,
  setFormValue,
  setFormSocialProfileValue,
  markFormAsUploading,
  loadArtistImages,
  deleteImage
} from 'gigshq/actions/artists';

// Queries
import artistImageQuery from 'gigshq/graphql/queries/artist-images';

const mapStateToComputed = state => {
  const {
    artists: {
      artistImageQuery,
      artistEdition: {
        isDirty,
        isSaved,
        isPending,
        errors,
        form: {
          id,
          name,
          genres,
          availableGenres,
          profileImage,
          description,
          contactEmail,
          socialProfile,
          isUploading
        }
      }
    }
  } = state;

  return {
    isDirty,
    isSaved,
    isPending,
    errors,
    id,
    name,
    genres,
    description,
    contactEmail,
    socialProfile,
    availableGenres,
    profileImage,
    isUploading,
    artistImageQuery
  };
};

const mapDispatchToActions = dispatch => {
  return {
    resetForm: () => dispatch(resetForm({form: 'artistEdition'})),
    saveArtist: (mutate, artist) =>
      dispatch(saveArtist({form: 'artistEdition', mutate, artist})),
    updateGenres: genres =>
      dispatch(updateGenres({form: 'artistEdition', genres})),
    setFormValue: (name, value) =>
      dispatch(setFormValue({form: 'artistEdition', name, value})),
    setFormSocialProfileValue: (name, value) =>
      dispatch(setFormSocialProfileValue({form: 'artistEdition', name, value})),
    loadArtistImages: (executeQuery, artistId) =>
      dispatch(loadArtistImages(executeQuery, artistId)),
    markFormAsUploading: isUploading =>
      dispatch(markFormAsUploading({form: 'artistEdition', isUploading})),
    deleteImage: (mutate, imageId) => dispatch(deleteImage({mutate, imageId}))
  };
};

const ArtistEditionForm = ReduxContainer.extend({
  apollo: service(),

  propTypes: {
    onArtistCreate: T.func,
    artist: T.object
  },

  didInsertElement() {
    const id = this.id;

    if (id) {
      const watchQuery = this.apollo.watchQueryOperation({
        query: artistImageQuery,
        variables: {artistId: this.id}
      });

      const imageQuerySubscription = watchQuery.subscribe({
        next: result => {
          this.set('images', result.data.viewer.artist.images);
        }
      });

      this.set('imageQuerySubscription', imageQuerySubscription);
    }
  },

  willDestroyElement() {
    const imageQuerySubscription = this.imageQuerySubscription;

    if (imageQuerySubscription) {
      imageQuerySubscription.unsubscribe();
      this.set('imageQuerySubscription', null);
    }
  },

  actions: {
    save() {
      const genreIDs = this.genres.map(genre => genre.id);
      const profileImageId = this.get('profileImage.id');
      const {id, ...fields} = this;

      const variables = {
        id: !!id ? id : null,
        ...fields,
        genreIDs,
        profileImageId
      };

      this.get('actions.saveArtist')(
        this.apollo.mutationOperation(),
        variables
      ).then(result => {
        const id = result && result.artist && result.artist.id;
        if (!id) return;

        const onArtistCreate = this.onArtistCreate;
        if (onArtistCreate) onArtistCreate(id);
      });
    },

    deleteArtistImage(imageId) {
      return this.get('actions.deleteImage')(
        this.apollo.mutationOperation(),
        imageId
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(ArtistEditionForm);
