import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    profilePicture: T.string.isRequired,
    profileName: T.string.isRequired,
    message: T.nullable(T.string).isRequired,
    showSharedLinkCard: T.bool,
    coverImage: T.string,
    sharedLinkTitle: T.string,
    sharedLinkText: T.string,
    sharedLinkUrl: T.string
  },

  getDefaultProps() {
    return {
      showSharedLinkCard: true
    };
  },

  tagName: 'article',

  messageHTML: computed('message', function() {
    return htmlSafe(this.message.replace(/\n/g, '<br>'));
  })
});
