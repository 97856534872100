import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { task, timeout } from 'ember-concurrency';
import config from 'gigshq/config/environment';

// Queries
import deleteVenueMutation from 'gigshq/graphql/mutations/delete-venue';

export default Controller.extend(Localizable(translations), {
  apollo: service(),
  venuesFetcher: service('fetchers/venues-fetcher'),

  isSearchLoading: false,
  searchDebounceFunction: null,

  deleteVenueTask: task(function*(venueId) {
    const mutation = deleteVenueMutation;
    const variables = {venueId};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'deleteVenue');

      this.send('refreshModel');
      this.flashMessages.success(this.localTranslation('delete.success'));
    } catch (errors) {
      const message = this.localTranslation(
        `delete.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
  }).drop(),

  searchVenueTask: task(function*(searchQuery) {
    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);

    this.set('isSearchLoading', true);
    const response = yield this.venuesFetcher.searchManagedVenues(searchQuery, true);
    this.set('model', response);
    this.set('isSearchLoading', false);
  }).restartable(),

  handleDelete(venueId) {
    // eslint-disable-next-line no-alert
    if (window.confirm(this.localTranslation('delete.confirm'))) {
      this.deleteVenueTask.perform(venueId);
    }
  },

  actions: {
    onVenueSearchInput(value) {
      this.searchVenueTask.perform(value);
    }
  }


});
