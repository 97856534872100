import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/venue-offer-redemptions';

export default Route.extend({
  queryParams: {
    venueId: {
      refreshModel: true
    },
  },

  apollo: service(),
  redux: service(),

  model(params) {
    const variables = params.venueId ? { venueId: params.venueId } : null;
    return this.apollo.queryOperation()({ query, variables }, 'viewer');
  }
});
