import Component from '@ember/component';
import { computed } from '@ember/object';
import { sortByDate, groupByDate } from 'gigshq/utils/array';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  hasGigs: computed('event.gigs.[]', function() {
    return this.event.gigs.length > 0;
  }),

  sortedGigs: computed('event.gigs.[]', function() {
    return sortByDate(this.event.gigs, 'startedAt');
  }),

  groupedGigs: computed('event.gigs.[]', function() {
    const groupedGigsByDateTime = groupByDate(this.event.gigs, 'startedAt', true, this.event.venue.timezone);

    return groupedGigsByDateTime.map(({itemDate, items}) => {
      return {
        key: moment(itemDate),
        gigs: [...items]
      };
    });
  }),

  init() {
    this._super(...arguments);

    const gigs = this.sortedGigs;
    this.selectedGig = gigs[gigs.length - 1];
  },

  actions: {
    selectGig(gig) {
      this.set('selectedGig', gig);
    }
  }
});
