define("gigshq/graphql/mutations/persist-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "mutation",
      "name": {
        "kind": "Name",
        "value": "PersistEvent"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "doorPriceCents"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "gigs"
          }
        },
        "type": {
          "kind": "ListType",
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "GigInput"
              }
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "name"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "description"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "squareCropId"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "startedAt"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Date"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "ticketPriceCents"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "ticketPurchaseUrl"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "String"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "venueId"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "wideCropId"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "persistEvent"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "input"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "doorPriceCents"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "doorPriceCents"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "gigs"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "gigs"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "squareCropId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "squareCropId"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "startedAt"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "startedAt"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "ticketPriceCents"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "ticketPriceCents"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "ticketPurchaseUrl"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "ticketPurchaseUrl"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "venueId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "venueId"
                  }
                }
              }, {
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "wideCropId"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "wideCropId"
                  }
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "event"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "description"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "slug"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "startedAt"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "ticketPriceCents"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "doorPriceCents"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "ticketPurchaseUrl"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "venue"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "slug"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "shareableProfile"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "wide"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "widePicture"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "wideThumbnail"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "square"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "facebook"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "gigs"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "startedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "artist"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "name"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "editSlug"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "profileImage"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "alias": {
                                "kind": "Name",
                                "value": "thumbnailImageUrl"
                              },
                              "name": {
                                "kind": "Name",
                                "value": "imageUrl"
                              },
                              "arguments": [{
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "width"
                                },
                                "value": {
                                  "kind": "IntValue",
                                  "value": "120"
                                }
                              }, {
                                "kind": "Argument",
                                "name": {
                                  "kind": "Name",
                                  "value": "height"
                                },
                                "value": {
                                  "kind": "IntValue",
                                  "value": "120"
                                }
                              }],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "errors"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "path"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "messages"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 1379,
      "source": {
        "body": "\n  mutation PersistEvent(\n    $id: ID\n    $doorPriceCents: Int\n    $gigs: [GigInput!]\n    $name: String!\n    $description: String\n    $squareCropId: ID!\n    $startedAt: Date!\n    $ticketPriceCents: Int\n    $ticketPurchaseUrl: String!\n    $venueId: ID!\n    $wideCropId: ID!\n  ) {\n    persistEvent(\n      input: {\n        id: $id\n        doorPriceCents: $doorPriceCents\n        gigs: $gigs\n        name: $name\n        description: $description\n        squareCropId: $squareCropId\n        startedAt: $startedAt\n        ticketPriceCents: $ticketPriceCents\n        ticketPurchaseUrl: $ticketPurchaseUrl\n        venueId: $venueId\n        wideCropId: $wideCropId\n      }\n    ) {\n      event {\n        id\n        name\n        description\n        slug\n        startedAt\n        ticketPriceCents\n        doorPriceCents\n        ticketPurchaseUrl\n\n        venue {\n          id\n          slug\n        }\n\n        shareableProfile {\n          id\n          wide\n          widePicture\n          wideThumbnail\n          square\n          facebook\n        }\n\n        gigs {\n          id\n          startedAt\n\n          artist {\n            id\n            name\n            editSlug\n            profileImage {\n              id\n              thumbnailImageUrl: imageUrl(width: 120, height: 120)\n            }\n          }\n        }\n      }\n\n      errors {\n        path\n        messages\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
