import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { connect } from 'ember-redux';

// Actions
import { askInstructions } from 'gigshq/actions/password-reset';

const mapStateToComputed = state => {
  const {
    passwordReset: {
      askInstructionsForm: {isPending, isSuccess, errors}
    }
  } = state;

  return {
    isPending,
    isSuccess,
    errors
  };
};

const mapDispatchToActions = dispatch => {
  return {
    askInstructions: (mutate, email) =>
      dispatch(askInstructions({mutate, email}))
  };
};

const AskResetPasswordInstructionsForm = ReduxContainer.extend({
  apollo: service(),

  actions: {
    handleSubmit(email) {
      return this.get('actions.askInstructions')(
        this.apollo.mutationOperation(),
        email
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(AskResetPasswordInstructionsForm);
