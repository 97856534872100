import { notEmpty, not, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    dates: T.array.isRequired,
    autoPublishEvent: T.bool.isRequired,
    event: T.looseShape({
      publishedAt: T.nullable(T.string)
    }).isRequired,
    errors: T.nullable(T.array).isRequired,
    isPending: T.bool.isRequired,
    setFormValue: T.func.isRequired,
    onSubmit: T.func.isRequired,
    close: T.func.isRequired
  },

  eventIsPublished: notEmpty('event.publishedAt'),
  eventIsNotPublished: not('eventIsPublished'),
  displayAutoPublishEventDisabledTooltip: readOnly('eventIsNotPublished'),
  hasDateSelection: notEmpty('dates'),

  minDate: computed(() =>
    moment()
      .startOf('day')
      .toISOString()
  ),

  autoPublishEventHelpText: computed('eventIsPublished', function() {
    const key = this.eventIsPublished ? 'enable' : 'disable';

    return this.localTranslation(`tooltips.auto_publish_event.${key}`);
  }),

  duplicateButtonIsDisabled: computed(
    'hasDateSelection',
    'isPending',
    function() {
      return this.isPending || !this.hasDateSelection;
    }
  ),

  actions: {
    handleDatesUpdate(dates) {
      this.setFormValue('dates', dates.date);
    }
  }
});
