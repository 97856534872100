// Vendor
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    venue: T.object.isRequired,
    closeDropdown: T.func.isRequired
  },

  router: service(),
  tagName: 'li',
  classNames: ['gigs-web-search-bar__venue-item'],

  venueLocation: computed('venue.{name,city,province}', function() {
    return [this.venue.city, this.venue.province].filter(n => n).join(', ');
  }),

  click() {
    this.closeDropdown();
    this.router.transitionTo('web.venues.show', this.venue.slug);

  }
});
