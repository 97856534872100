import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import query from 'gigshq/graphql/queries/artist';

export default Route.extend({
  apollo: service(),

  model({slug}) {
    const variables = {slug};
    return this.apollo.queryOperation()({query, variables}, 'viewer');
  }
});
