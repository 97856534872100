import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';
import moment from 'moment';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT } from 'gigshq/constants/user-types';
import config from 'gigshq/config/environment';

// Actions
import {
  addGig,
  reloadCrops,
  removeGig,
  resetForm,
  saveEvent,
  setFormValue,
  setStartedAt,
  setVenue,
  updateGig,
  updateGigAtIndex
} from 'gigshq/actions/events';
import { resetImageSelection, resetCrops } from 'gigshq/actions/crop-tool';
import { setFormValue as setArtistCreationToolFormValue } from 'gigshq/actions/artist-creation-tool';
import { createLocalArtist } from 'gigshq/actions/artists';

const mapStateToComputed = state => {
  const {
    artists: {
      localArtistCreation: {data: localArtist, isPending: isPendingLocalArtist}
    },
    events: {
      eventCreation: {
        isDirty,
        isPending,
        data,
        errors,
        form: {
          startedAt,
          name,
          description,
          gigs,
          ticketPriceCents,
          doorPriceCents,
          ticketPurchaseUrl,
          wideCrop,
          squareCrop,
          venue
        }
      }
    }
  } = state;

  const id = state.events.eventCreation.data
    ? state.events.eventCreation.data.id
    : null;
  const slug = state.events.eventCreation.data
    ? state.events.eventCreation.data.slug
    : null;

  return {
    isDirty,
    isPending,
    data,
    localArtist,
    isPendingLocalArtist,
    errors,
    id,
    startedAt: moment(startedAt).tz(venue.timezone),
    name,
    description,
    slug,
    gigs,
    ticketPriceCents,
    doorPriceCents,
    ticketPurchaseUrl,
    venue,
    wideCrop,
    squareCrop
  };
};

const mapDispatchToActions = dispatch => {
  return {
    addGig: gig => dispatch(addGig({form: 'eventCreation', gig})),
    removeGig: gig => dispatch(removeGig({form: 'eventCreation', gig})),
    updateGig: gig => dispatch(updateGig({form: 'eventCreation', gig})),
    updateGigAtIndex: (gig, index) =>
      dispatch(updateGigAtIndex({form: 'eventCreation', index, gig})),
    saveEvent: (mutate, event) =>
      dispatch(saveEvent({form: 'eventCreation', mutate, event})),
    resetCrops: () => dispatch(resetCrops()),
    resetForm: () => dispatch(resetForm({form: 'eventCreation'})),
    resetImageSelection: () => dispatch(resetImageSelection()),
    setVenue: venue => dispatch(setVenue({form: 'eventCreation', venue})),
    setFormValue: (name, value) => {
      if (name === 'startedAt') {
        dispatch(setStartedAt({form: 'eventCreation', startedAt: value}));
      } else {
        dispatch(setFormValue({form: 'eventCreation', name, value}));
      }
    },
    createLocalArtist: (mutate, artist) =>
      dispatch(
        createLocalArtist({form: 'localArtistCreation', mutate, artist})
      ),
    setArtistCreationToolFormValue: (name, value) =>
      dispatch(setArtistCreationToolFormValue({name, value})),
    reloadCrops: (executeQuery, variables) =>
      dispatch(reloadCrops({form: 'eventCreation', executeQuery, variables}))
  };
};

const EventCreationForm = ReduxContainer.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),

  propTypes: {
    venues: T.array.isRequired,
    onEventCreate: T.func.isRequired
  },

  isUserTypeTenant: readOnly('accountManager.isUserTypeTenant'),

  userTypeEditor: computed('accountManager.isUserTypeArtist', function() {
    return this.get('accountManager.isUserTypeArtist')
      ? USER_TYPE_ARTIST
      : USER_TYPE_TENANT;
  }),

  willDestroyElement() {
    this.get('actions.resetForm')();
    this.get('actions.resetImageSelection')();
  },

  actions: {
    setVenue(venue) {
      this.get('actions.setVenue')(venue);
    },

    setGigStartedAt(gig, startedAtString) {
      const [hours, minutes] = startedAtString.split(':');

      const startedAt = moment.tz(this.startedAt, this.venue.timezone)
        .hours(hours)
        .minutes(minutes)
        .startOf('minute');

      if (startedAt.hour() < config.TimeConfiguration.NEW_DAY_CUTOFF_HOUR) {
        startedAt.add(1, 'day');
      }

      this.get('actions.updateGig')({
        ...gig,
        startedAt: startedAt.toISOString()
      });
    },

    setGigArtist(gig, index, artist) {
      this.get('actions.updateGigAtIndex')({...gig, artist}, index);
    },

    create() {
      const properties = this.getProperties(
        'name',
        'description',
        'ticketPriceCents',
        'doorPriceCents',
        'ticketPurchaseUrl'
      );

      const gigs = this.gigs
        .filter(({artist}) => !!artist.id)
        .map(gig => ({
          startedAt: gig.startedAt,
          artistId: gig.artist.id
        }));

      const variables = {
        ...properties,
        gigs,
        startedAt: gigs[0].startedAt,
        squareCropId: this.get('squareCrop.id'),
        venueId: this.get('venue.id'),
        wideCropId: this.get('wideCrop.id')
      };

      this.get('actions.saveEvent')(
        this.apollo.mutationOperation(),
        variables
      ).then(() => {
        const id = this.get('data.id');
        if (!id) return;

        this.onEventCreate(id);
      });
    },

    assignLocalArtist(gig, index, {name}) {
      if (this.isPendingLocalArtist) return;

      const variables = {name};
      this.get('actions.createLocalArtist')(
        this.apollo.mutationOperation(),
        variables
      ).then(() => {
        const artist = this.localArtist;
        if (!artist) return;

        this.get('actions.updateGigAtIndex')({...gig, artist}, index);
      });
    },

    finishCrop(crops) {
      this.get('actions.setFormValue')('wideCrop', crops.wide);
      this.get('actions.setFormValue')('squareCrop', crops.square);

      // Try reloading the crops so that maybe the image will be processed
      const variables = {
        wideCropId: crops.wide.id,
        squareCropId: crops.square.id
      };
      this.get('actions.reloadCrops')(this.apollo.queryOperation(), variables);
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(EventCreationForm);
