import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';

// Queries
import artistImageQuery from 'gigshq/graphql/queries/artist-images';

// Mutations
import persistLocalArtistMutation from 'gigshq/graphql/mutations/persist-local-artist';
import persistArtistMutation from 'gigshq/graphql/mutations/persist-artist';
import deleteImageMutation from 'gigshq/graphql/mutations/delete-image';

export const markFormAsUploading = createAction(
  'ARTISTS_MARK_FORM_AS_UPLOADING'
);

export const createLocalArtistPending = createAction(
  'ARTISTS_CREATE_LOCAL_ARTIST_PENDING'
);
export const createLocalArtistSuccess = createAction(
  'ARTISTS_CREATE_LOCAL_ARTIST_SUCCESS'
);
export const createLocalArtistFailure = createAction(
  'ARTISTS_CREATE_LOCAL_ARTIST_FAILURE'
);

export const createLocalArtist = ({form, mutate, artist}) => async dispatch => {
  dispatch(createLocalArtistPending({form}));

  try {
    const mutation = persistLocalArtistMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, artist);
    const result = await mutate({mutation, variables}, 'persistLocalArtist');

    dispatch(createLocalArtistSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(createLocalArtistFailure({form, error}));
  }
};

export const saveArtistPending = createAction('ARTISTS_SAVE_ARTIST_PENDING');
export const saveArtistSuccess = createAction('ARTISTS_SAVE_ARTIST_SUCCESS');
export const saveArtistFailure = createAction('ARTISTS_SAVE_ARTIST_FAILURE');

export const saveArtist = ({form, mutate, artist}) => async dispatch => {
  dispatch(saveArtistPending({form}));

  try {
    const mutation = persistArtistMutation;

    if (artist && artist.socialProfile && artist.socialProfile.__typename)
      delete artist.socialProfile.__typename;

    const variables = GraphQLVariablesBuilder.build(mutation, artist);
    const result = await mutate({mutation, variables}, 'persistArtist');

    dispatch(saveArtistSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(saveArtistFailure({form, error}));
  }
};

export const loadArtistImagesPending = createAction(
  'ARTISTS_ARTIST_IMAGES_PENDING'
);
export const loadArtistImagesSuccess = createAction(
  'ARTISTS_ARTIST_IMAGES_SUCCESS'
);
export const loadArtistImagesFailure = createAction(
  'ARTISTS_ARTIST_IMAGES_FAILURE'
);

export const loadArtistImages = (executeQuery, artistId) => async dispatch => {
  dispatch(loadArtistImagesPending());

  const query = artistImageQuery;
  const variables = {artistId};

  try {
    const result = await executeQuery({query, variables}, 'viewer');

    dispatch(loadArtistImagesSuccess(result));

    return result;
  } catch (error) {
    dispatch(loadArtistImagesFailure(error));
  }
};

export const deleteImagePending = createAction('ARTISTS_DELETE_IMAGE_PENDING');
export const deleteImageSuccess = createAction('ARTISTS_DELETE_IMAGE_SUCCESS');
export const deleteImageFailure = createAction('ARTISTS_DELETE_IMAGE_FAILURE');

export const deleteImage = ({mutate, imageId}) => async dispatch => {
  dispatch(deleteImagePending());

  const mutation = deleteImageMutation;
  const variables = {imageId};

  try {
    await mutate(
      {mutation, variables, refetchQueries: ['ArtistImages']},
      'deleteImage'
    );

    dispatch(deleteImageSuccess());
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const resetForm = createAction('ARTISTS_RESET_FORM');
export const setFormValue = createAction('ARTISTS_SET_FORM_VALUE');
export const setFormSocialProfileValue = createAction(
  'ARTISTS_SET_FORM_SOCIAL_PROFILE_VALUE'
);
export const fillForm = createAction('ARTISTS_FILL_FORM');
export const updateGenres = createAction('ARTISTS_UPDATE_GENRES');
