import { notEmpty, equal } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { PropTypes as T } from 'ember-prop-types';
import FacebookImporter from 'gigshq/utils/facebook-importer';


const States = {
  LOGIN: 'login',
  ACCEPT_PERMISSIONS: 'accept-permissions',
  NO_ACCOUNTS: 'no-accounts',
  COMPLETED: 'completed'
};

const REQUIRED_SCOPES = ['manage_pages', 'pages_read_user_content', 'pages_read_engagement'];

export default Component.extend(Localizable(translations), {
  propTypes: {
    fbPageAccessToken: T.object,
    setProperty: T.func.isRequired,
    didLinkFacebookEventsImporter: T.func.isRequired
  },

  hasValidLongAccessToken: false,
  facebookPageObj: null,

  permissions: [],
  hasAcceptedPermissions: computed('permissions.@each.status', function() {
    return REQUIRED_SCOPES.every(scope => {
      const permission = this.permissions.find(
        // eslint-disable-next-line max-nested-callbacks
        ({permission}) => permission === scope);

      if (!permission) return false;

      return permission.status === 'granted';
    });
  }),

  accounts: [],
  hasAccounts: notEmpty('accounts'),

  isReadyToPost: equal('state', States.POST),

  stateComponent: computed('state', function() {
    return `gigs-venue-form/form/fb-importer/${this.state}`;
  }),

  /* eslint-disable complexity */
  state: computed(
    'hasValidLongAccessToken',
    'hasAcceptedPermissions',
    'hasAccounts',
    function() {

      if (!this.hasValidLongAccessToken)
        return States.LOGIN;

      if (!this.hasValidLongAccessToken && !this.hasAcceptedPermissions)
        return States.ACCEPT_PERMISSIONS;

      if (!this.hasValidLongAccessToken && !this.hasAccounts)
        return States.NO_ACCOUNTS;

      return States.COMPLETED;
    }
  ),
  /* eslint-enable max-complexity */

  init(...args) {
    this._super(...args);

    this.onAuthResponseChange = this.onAuthResponseChange.bind(this);
  },

  didInsertElement() {
    if (window.FB) {
      window.FB.Event.subscribe(
        'auth.authResponseChange',
        this.onAuthResponseChange
      );
    }

    this.set('isLoading', true);
    this.checkIfAccessTokenValid();
  },

  willDestroyElement() {
    if (window.FB) {
      window.FB.Event.unsubscribe(
        'auth.authResponseChange',
        this.onAuthResponseChange
      );
    }
  },

  actions: {
    handleLogin() {
      const loginOptions = {scope: REQUIRED_SCOPES.join(',')};

      if (this.hasAcceptedPermissions) {
        // eslint-disable-next-line camelcase
        loginOptions.auth_type = 'rerequest';
      }

      return FacebookImporter.login(loginOptions);
    },

    handleRefreshAccounts() {
      return FacebookImporter.fetchAccounts().then(accounts =>
        this.set('accounts', accounts)
      );
    },

    handleDisconnect() {
      this.didLinkFacebookEventsImporter(null);
      this.didLogout();
    }
  },

  onAuthResponseChange({status}) {
    if (status === 'connected') {
      this.didLogin();
    } else {
      this.didLogout();
    }
  },

  didLogin() {
    return RSVP.hash({
      permissions: FacebookImporter.fetchPermissions(),
      accounts: FacebookImporter.fetchAccounts()
    }).then(result => {
      if (result.accounts[0]) {
        let fbPageId = result.accounts[0].id;
        FacebookImporter.fetchLongTermAccessToken(result.accounts[0].accessToken)
          .then(result => {
            let token = { id: fbPageId, accessToken: result.access_token };

            // set the access token local variable
            this.fbPageAccessToken = token;
            // set the Redux state token
            this.didLinkFacebookEventsImporter(token);
            this.checkIfAccessTokenValid();
          });
      }

      this.setProperties(result)
    });
  },

  checkIfAccessTokenValid() {
    if (this.fbPageAccessToken == null)
      return;

    FacebookImporter.isAccessTokenStillValid(this.fbPageAccessToken.accessToken)
      .then(response => {
        this.set('hasValidLongAccessToken', response.id != null);
        this.set('facebookPageObj', response);
      })
      .finally(() => this.set('isLoading', false));
  },

  didLogout() {
    this.setProperties({
      hasValidLongAccessToken: false,
      facebookPageObj: null,
      permissions: [],
      accounts: []
    });
  }
});
