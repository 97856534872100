import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { bool } from '@ember/object/computed';
import { USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),
  router: service(),

  offerRedemptionModalOffer: null,
  offerRedemptionModalIsOpened: bool('offerRedemptionModalOffer'),

  userTypeMember: USER_TYPE_MEMBER,

  actions: {
    showOfferRedemptionModal(offer) {
      if (!this.accountManager.hasActiveMembership) {
        window.alert(this.localTranslation('login_first'));
        return;
      }

      this.set('offerRedemptionModalOffer', offer);
    },

    handleOfferRedemptionSuccess() {
      this.flashMessages.success(this.localTranslation('redeem.success'));
      this.onVenueOfferRedemption();

      setTimeout(() => {
        this.set('offerRedemptionModalOffer', null);
      }, 1500);
    },

    handleOfferRedemptionFailure(error) {
      this.flashMessages.error(
        this.localTranslation(`redeem.errors.${error}`)
      );
    },

    hideOfferRedemptionModal() {
      this.set('offerRedemptionModalOffer', null);
    },
  }
});
