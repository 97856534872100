import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Actions
import { fillForm, resetForm } from 'gigshq/actions/artists';

// Queries
import query from 'gigshq/graphql/queries/artist-genres';

export default Route.extend({
  apollo: service(),
  can: service(),
  redux: service(),

  model() {
    return this.apollo.queryOperation()({query}, 'viewer');
  },

  afterModel(model) {
    this.redux.dispatch(resetForm({form: 'artistEdition'}));
    this.redux.dispatch(
      fillForm({
        form: 'artistEdition',
        values: {availableGenres: model.genres}
      })
    );
  },

  isPageDirty() {
    const {
      artists: {
        artistEdition: {isDirty, isUploading}
      }
    } = this.redux.getState();
    return isDirty || isUploading;
  },

  redirect() {
    if (this.can.cannot('create artist', {}))
      return this.transitionTo('app.events.index');
  }
});
