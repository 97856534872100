import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import persistVenueMutation from 'gigshq/graphql/mutations/persist-venue';
import deleteImageMutation from 'gigshq/graphql/mutations/delete-image';

export const saveVenuePending = createAction('VENUES_SAVE_VENUE_PENDING');
export const saveVenueSuccess = createAction('VENUES_SAVE_VENUE_SUCCESS');
export const saveVenueFailure = createAction('VENUES_SAVE_VENUE_FAILURE');

export const saveVenue = ({mutate, form, venue}) => async dispatch => {
  dispatch(saveVenuePending({form}));

  try {
    const mutation = persistVenueMutation;

    if (venue && venue.socialProfile && venue.socialProfile.__typename)
      delete venue.socialProfile.__typename;

    const variables = GraphQLVariablesBuilder.build(mutation, venue);

    const result = await mutate({mutation, variables}, 'persistVenue');

    dispatch(saveVenueSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(saveVenueFailure({form, error}));
  }
};

export const markFormAsUploading = createAction(
  'VENUES_MARK_FORM_AS_UPLOADING'
);

export const deleteImagePending = createAction('VENUES_DELETE_IMAGE_PENDING');
export const deleteImageSuccess = createAction('VENUES_DELETE_IMAGE_SUCCESS');
export const deleteImageFailure = createAction('VENUES_DELETE_IMAGE_FAILURE');

export const deleteImage = ({mutate, imageId}) => async dispatch => {
  dispatch(deleteImagePending());

  const mutation = deleteImageMutation;
  const variables = {imageId};

  try {
    await mutate(
      {mutation, variables, refetchQueries: ['VenueImages']},
      'deleteImage'
    );

    dispatch(deleteImageSuccess());
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};

export const setFormValue = createAction('VENUES_SET_FORM_VALUE');
export const resetForm = createAction('VENUES_RESET_FORM');
export const fillForm = createAction('VENUES_FILL_FORM');
export const setFormSocialProfileValue = createAction(
  'VENUES_SET_FORM_SOCIAL_PROFILE_VALUE'
);
