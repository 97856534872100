import { notEmpty } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { isNone } from '@ember/utils';
import Component from '@ember/component';
import { computed } from '@ember/object';
import AutoResize from 'ember-autoresize/mixins/autoresize';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(AutoResize, Localizable(translations), {
  propTypes: {
    value: T.string.isRequired,
    inputClass: T.string,
    withHelp: T.bool,
    helpText: T.oneOfType([T.string, T.safeString]),
    withCounter: T.bool,
    counterLimit: T.number,
    error: T.string,
    autoresize: T.bool
  },

  classNameBindings: ['hasError'],

  hasError: notEmpty('error'),

  // Options required for ember-autoresize to work
  autoresize: true,
  shouldResizeHeight: true,
  significantWhitespace: true,

  combinedClass: computed('inputClass', function() {
    let className = 'gigs-input__input';

    if (this.inputClass)
      className += ` ${this.inputClass}`;

    return className;
  }),

  autoResizeText: computed('value', {
    get() {
      let value = this.value;

      if (isNone(value)) {
        value = '';
      }

      return `${value}@`;
    }
  }),

  autoresizeElementDidChange: on('didInsertElement', function() {
    this.set('autoresizeElement', this.$('textarea')[0]);
  })
});
