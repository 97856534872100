import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    cancellationReason: T.string.isRequired,
    errors: T.nullable(T.array).isRequired,
    setFormValue: T.func.isRequired,
    isPending: T.bool.isRequired,
    submit: T.func.isRequired,
    close: T.func.isRequired
  }
});
