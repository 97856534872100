import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';
import config from 'gigshq/config/environment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    onSendNotificationClick: T.func.isRequired,
    onClose: T.func.isRequired
  },

  nextAvailableNotificationMoment: computed(
    'event.lastEventEmailSentAt',
    function() {
      const lastEventEmailSentAt = this.get('event.lastEventEmailSentAt');
      return moment(lastEventEmailSentAt).add(
        config.APP.MIN_NOTIFICATION_DELAY_IN_MINUTES,
        'minutes'
      );
    }
  ),

  nextAvailableNotificationDate: computed(
    'nextAvailableNotificationMoment',
    function() {
      const dateFormatKey = this.localTranslation('date_format').toString();
      return this.nextAvailableNotificationMoment.format(dateFormatKey);
    }
  )
});
