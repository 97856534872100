import Service, { inject as service } from '@ember/service';
import { getDistance } from 'geolib';

// Queries
import webSearchQuery from 'gigshq/graphql/queries/web-search';

export default Service.extend({
  apollo: service(),
  accountManager: service('managers/account-manager'),
  favouriteVenuesManager: service('managers/favourite-venues-manager'),
  sessionFetcher: service('fetchers/session-fetcher'),

  searchedArtists: [],
  searchedEvents: [],
  searchedVenues: [],

  async search(searchQuery, userLocation) {
    const query = webSearchQuery(this.sessionFetcher.determineUserRole());
    const variables = {
      searchQuery,
      // favouriteVenues: this.favouriteVenuesManager.favouriteVenueIds.map(venueId => parseInt(venueId, 10)),
    };

    const viewer = await this.apollo.queryOperation()({ query, variables }, 'viewer');
    this.accountManager.setAccount(viewer);

    this.set('searchedArtists', viewer.artists);
    this.set('searchedEvents', viewer.publicEventsSearch.map(event => {
      event.venue = this._addDistanceFromUser(event.venue, userLocation);
      return event;
    }));
    this.set('searchedVenues', viewer.publicVenues.map(venue => this._addDistanceFromUser(venue, userLocation)));

    return viewer;
  },

  clearSearch() {
    this.set('searchedArtists', null);
    this.set('searchedEvents', null);
    this.set('searchedVenues', null);
  },

  _addDistanceFromUser(venue, userLocation) {
    const distanceFromUser = userLocation
      ? getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: venue.latitude, longitude: venue.longitude }) / 1000.0
      : null;

    const distanceFromUserInWords = userLocation
      ? `${distanceFromUser.toFixed(2)} km`
      : '';

    return {
      ...venue,
      distanceFromUser,
      distanceFromUserInWords
    };
  }
});

