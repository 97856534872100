define("gigshq/graphql/queries/venue-offer-redemptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "VenueOfferRedemptions"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "venueId"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "redemptions"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "venueId"
                    },
                    "value": {
                      "kind": "Variable",
                      "name": {
                        "kind": "Name",
                        "value": "venueId"
                      }
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "venueOfferId"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "userId"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "dateRedeemed"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "venueOffer"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "offerType"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "description"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "venue"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "slug"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "name"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "city"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "user"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "firstName"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "lastName"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "email"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "member"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "gender"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "birthdate"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "contactNumber"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "address"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "city"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "province"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "postalCode"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "country"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 723,
      "source": {
        "body": "\n  query VenueOfferRedemptions($venueId: ID) {\n    viewer {\n      ... on User {\n        id\n\n        redemptions(venueId: $venueId) {\n          id\n          venueOfferId\n          userId\n          dateRedeemed\n\n          venueOffer {\n            offerType\n            description\n\n            venue {\n              slug\n              name\n              city\n            }\n          }\n\n          user {\n            firstName\n            lastName\n            email\n\n            member {\n              gender\n              birthdate\n              contactNumber\n\n              address\n              city\n              province\n              postalCode\n              country\n            }\n          }\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
