import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import config from 'gigshq/config/environment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    artist: T.looseShape({
      id: T.string,
      name: T.string,
      profileImage: T.nullable(
        T.looseShape({
          id: T.string,
          thumbnailImageUrl: T.string
        })
      )
    }).isRequired,
    venueTimezone: T.string.isRequired,
    startedAt: T.string.isRequired,
    setStartedAt: T.func.isRequired,
    setArtist: T.func.isRequired,
    setLocalArtist: T.func.isRequired,
    removeGig: T.func
  },

  times: computed(() => {
    const HALF_HOURS_IN_A_DAY = 48;

    return Array(HALF_HOURS_IN_A_DAY)
      .fill(null)
      .map((_item, index) => {
        const hours = parseInt(index / 2, 10);
        const minutes = index % 2 === 0 ? '00' : '30';

        return `${hours}:${minutes}`;
      })
      .sort((firstItem, secondItem) => {
        const firstHour = parseInt(firstItem.split(':')[0], 10);
        const secondHour = parseInt(secondItem.split(':')[0], 10);

        if (firstHour < config.TimeConfiguration.NEW_DAY_CUTOFF_HOUR) {
          if (secondHour < config.TimeConfiguration.NEW_DAY_CUTOFF_HOUR) {
            return firstHour - secondHour;
          } else {
            return 1;
          }
        } else if (secondHour < config.TimeConfiguration.NEW_DAY_CUTOFF_HOUR) {
          return -1;
        }

        return firstHour - secondHour;
      });
  }),

  startedAtTime: computed('startedAt', 'venueTimezone', function() {
    return moment(this.startedAt).tz(this.venueTimezone).format('HH:mm');
  }),

  actions: {
    handleArtistSelection(artist) {
      this.updateGigAtIndex(artist);
    },

    handleArtistCreation({name}) {
      this.setLocalArtist({name});
    }
  }
});
