import { bool, not } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    publish: T.func.isRequired
  },

  isReadyForPublication: bool('event.shareableProfile.wide'),
  notReadyForPublication: not('isReadyForPublication'),

  mascotImagePath: computed('isReadyForPublication', function() {
    if (!this.isReadyForPublication) {
      return AssetsCatalog.LargeGigiStates.PERPLEX.asset;
    }

    return AssetsCatalog.LargeGigiStates.POSTER.asset;
  })
});
