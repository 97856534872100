import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import { inject as service } from '@ember/service';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { computed } from '@ember/object';
import LocationableStyleable from 'gigshq/mixins/components/locationable-styleable';
import { task, timeout } from 'ember-concurrency';
import config from 'gigshq/config/environment';

const MIN_SEARCH_QUERY_LENGTH = 2;

export default Component.extend(Localizable(translations), LocationableStyleable, {
  propTypes: {
    fromDate: T.nullable(T.string),
    toDate: T.nullable(T.string),
    selectedVenueId: T.number,
    rangeOptional: T.bool,
    setDateRange: T.func.isRequired,
    onSetVenue: T.func.isRequired
  },

  venuesFetcher: service('fetchers/venues-fetcher'),
  isSearchingVenues: false,

  selectedVenue: computed('venues.[]', 'selectedVenueId', function() {
    if (!this.venues) return null;

    return this.venues.find((venue) => venue.id === this.selectedVenueId);
  }),

  searchVenues: task(function*(value) {
    if (value.length <= MIN_SEARCH_QUERY_LENGTH) return;

    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);
    this.set('isSearchingVenues', true);

    const response = yield this.venuesFetcher.searchManagedVenues(value);
    this.set('venues', response.venues);

    this.set('isSearchingVenues', false);
  }).restartable(),

  hasSelection: computed.bool('selectedVenue'),

  setVenueFilter(venue) {
    this.onSetVenue(venue.id);
  },

  clearSearch() {
    this.set('searchTerm', '');
    this.venuesFetcher.clearSearchVenues();
  },

  clearVenueFilter(event) {
    event.stopPropagation();

    this.venuesFetcher.clearSearchVenues();
    this.onSetVenue(null);
  },

  openDropdown() {
    setTimeout(() => {
      document.querySelector(`.gigs-app-event-list-filter__search`).focus();
    }, 250);
  },

  closeDropdown(dropdown, disabled) {
    if (!disabled) dropdown.actions.close();
  },
});
