import { inject as service } from '@ember/service';
import { not, and, readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { Ability } from 'ember-can';

export default Ability.extend({
  accountManager: service('managers/account-manager'),

  eventIsNotCancelled: computed('model.cancelledAt', function() {
    return this.get('model.cancelledAt') == null;
  }),

  eventIsNotDeleted: computed('model.deletedAt', function() {
    return this.get('model.deletedAt') == null;
  }),

  eventIsCancelled: not('eventIsNotCancelled'),
  eventIsDeleted: not('eventIsNotDeleted'),
  eventIsNotPublished: not('model.published'),
  eventIsActive: and('eventIsNotCancelled', 'eventIsNotDeleted'),

  eventIsMine: computed('model.creator.id', 'accountManager.id', function() {
    return this.get('model.creator.id') === this.get('accountManager.id');
  }),

  canCreate: computed(
    'accountManager.{isUserTypeArtist,isUserTypeTenant,isCompletedAccount}',
    function() {
      const { isUserTypeArtist, isUserTypeTenant, isCompletedAccount } = this.accountManager;
      return isCompletedAccount && (isUserTypeArtist || isUserTypeTenant);
    }
  ),

  artistCanEdit: and(
    'accountManager.isUserTypeArtist',
    'eventIsMine',
    'eventIsNotPublished'
  ),

  canEdit: computed(
    'canCreate',
    'artistCanEdit',
    'accountManager.isUserTypeTenant',
    'eventIsActive',
    function() {
      return (
        this.canCreate &&
        (this.artistCanEdit || this.get('accountManager.isUserTypeTenant')) &&
        this.eventIsActive
      );
    }
  ),

  canAccessPublicationTools: computed(
    'accountManager.{isUserTypeArtist,isUserTypeTenant}',
    'model.published',
    'canEdit',
    'eventIsActive',
    function() {
      const artistCanAccessPublicationTools =
        this.get('accountManager.isUserTypeArtist') &&
        this.get('model.published');

      return (
        (artistCanAccessPublicationTools ||
          this.get('accountManager.isUserTypeTenant')) &&
        this.canEdit &&
        this.eventIsActive
      );
    }
  ),

  canNotifyTenant: and(
    'accountManager.isUserTypeArtist',
    'eventIsActive',
    'eventIsNotPublished',
    'eventIsMine'
  ),

  canPublish: and(
    'canCreate',
    'accountManager.isUserTypeTenant',
    'eventIsActive'
  ),
  canCancel: and('canPublish', 'eventIsNotCancelled', 'model.published'),
  canDelete: and('canPublish', 'eventIsNotDeleted', 'eventIsNotPublished'),

  canDuplicate: readOnly('canPublish'),

  canViewPublic: readOnly('eventIsNotDeleted'),

  canPrint: not('accountManager.isUserTypeTenant')
});
