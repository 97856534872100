import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tabObjects: computed('tabs.[]', 'selectedTabId', function() {
    return this.tabs.map(tab => ({
      ...tab,
      isSelected: tab.id === this.selectedTabId
    }));
  })
});
