export default [
  {
    key: 'm',
    label: 'Male'
  },
  {
    key: 'f',
    label: 'Female'
  },
  {
    key: 'u',
    label: 'Unspecified'
  }
];
