import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';
import offerTypes from 'gigshq/constants/offer-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'tr',

  offerTypes: offerTypes,

  dateRedeemed: computed(function() {
    return moment(this.redemption.dateRedeemed).local().format('L');
  })
});
