import { notEmpty, reads } from '@ember/object/computed';
import $ from 'jquery';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import Draggable from 'gigshq/utils/draggable-element';
import {
  calculateWideRatio,
  calculateTallRatio
} from 'gigshq/utils/size-for-ratio-calculator';
import { constrainBox } from 'gigshq/utils/constrain-box';

const MAXIMUM_ZOOM_LEVEL = 3;
const EVENT_NAMESPACE = 'gigs-image-cropper';
const DRAGGABLE_ELEMENT = '.gigs-image-cropper__crop-area';

export default Component.extend(Localizable(translations), {
  propTypes: {
    image: T.string.isRequired,
    ratio: T.string.isRequired,
    update: T.func.isRequired,
    onReady: T.func.isRequired,
    onError: T.func.isRequired
  },

  hasSeenHint: false,
  isLoadingSourceImage: false,
  isSourceImageLoaded: notEmpty('sourceImageSize'),
  zoomLevel: reads('minimumZoomLevel'),
  imageSize: reads('initialImageSize'),
  imagePosition: reads('initialImagePosition'),

  scaleRatio: computed('imageSize.width', 'sourceImageSize.width', function() {
    return this.get('imageSize.width') / this.get('sourceImageSize.width');
  }),

  minimumZoomLevel: computed(
    'sourceImageSize.{width,height}',
    'boundingBoxSize.{width,height}',
    function() {
      const {sourceImageSize, boundingBoxSize} = this;

      return Math.max(
        boundingBoxSize.width / sourceImageSize.width,
        boundingBoxSize.height / sourceImageSize.height
      );
    }
  ),

  maximumZoomLevel: computed(
    'sourceImageSize.{width,height}',
    'boundingBoxSize.{width,height}',
    function() {
      const {sourceImageSize, boundingBoxSize} = this;

      return Math.max(
        (boundingBoxSize.width * MAXIMUM_ZOOM_LEVEL) / sourceImageSize.width,
        (boundingBoxSize.height * MAXIMUM_ZOOM_LEVEL) / sourceImageSize.height
      );
    }
  ),

  boundingBoxStyles: computed('boundingBoxSize.{width,height}', function() {
    const {width, height} = this.boundingBoxSize;

    return htmlSafe(`width: ${width}px; height: ${height}px;`);
  }),

  cropAreaStyles: computed(
    'imagePosition.{top,left}',
    'imageSize.{width,height}',
    'image',
    function() {
      const image = this.image;
      const {top, left} = this.imagePosition;
      const {width, height} = this.imageSize;

      return htmlSafe(
        [
          `background-image: url('${image}');`,
          `background-position: ${left}px ${top}px;`,
          `background-size: ${width}px ${height}px;`
        ].join('')
      );
    }
  ),

  overlayImageStyles: computed(
    'imageSize.{width,height}',
    'imagePosition.{top,left}',
    'image',
    function() {
      const image = this.image;
      const {width, height} = this.imageSize;
      const {top, left} = this.imagePosition;

      return htmlSafe(
        [
          `background-image: url('${image}');`,
          `top: ${top}px;`,
          `left: ${left}px;`,
          `width: ${width}px;`,
          `height: ${height}px;`
        ].join('')
      );
    }
  ),

  initialImageSize: computed('image', function() {
    const {boundingBoxSize, sourceImageSize} = this;

    const options = {
      width: boundingBoxSize.width,
      height: boundingBoxSize.height,
      ratioWidth: sourceImageSize.width,
      ratioHeight: sourceImageSize.height,
      overflow: true
    };

    return sourceImageSize.width > sourceImageSize.height
      ? calculateWideRatio(options)
      : calculateTallRatio(options);
  }),

  initialImagePosition: computed('image', function() {
    const {boundingBoxSize, imageSize} = this;

    const top = (boundingBoxSize.height - imageSize.height) / 2;
    const left = (boundingBoxSize.width - imageSize.width) / 2;

    return {top, left};
  }),

  boundingBoxSize: computed('elementSize.{width,height}', 'ratio', function() {
    const {elementSize, ratio} = this;
    const [ratioWidth, ratioHeight] = ratio.split(':');

    const options = {
      width:
        elementSize.width - elementSize.paddingLeft - elementSize.paddingRight,
      height:
        elementSize.height - elementSize.paddingTop - elementSize.paddingBottom,
      ratioWidth,
      ratioHeight,
      maxWidth: 1280,
      maxHeight: 1280
    };

    return elementSize.width > elementSize.height
      ? calculateWideRatio(options)
      : calculateTallRatio(options);
  }),

  loadSourceImageTask: task(function*() {
    this.set('isLoadingSourceImage', true);

    const image = new Image();

    try {
      yield new RSVP.Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
        image.src = this.image;
      });
    } catch (error) {
      this.set('isLoadingSourceImage', false);
      this.onError();
      return;
    } finally {
      this.set('isLoadingSourceImage', false);
    }

    this.setProperties({
      sourceImageSize: {width: image.width, height: image.height}
    });

    // Make sure to send initial values as "changes"
    this._updateImage({});

    // We need to wait for Ember to re-render the component
    // *with* the bounding box before we can make it draggable
    run.next(() => {
      const draggable = new Draggable({
        elementSelector: DRAGGABLE_ELEMENT,
        onDrag: (deltaX, deltaY) => this.send('drag', deltaX, deltaY)
      });

      this.set('draggable', draggable);

      this.onReady();
    });
  }).restartable(),

  didInsertElement() {
    this.loadSourceImageTask.perform();

    this._setElementSize();

    $(window).on(`resize.${EVENT_NAMESPACE}`, () => {
      this._setElementSize();
      this._updateImage({});
    });
  },

  willDestroyElement() {
    if (this.draggable) {
      this.draggable.destroy();
    }

    this.set('sourceImageSize', null);

    $(window).off(`resize.${EVENT_NAMESPACE}`);
  },

  actions: {
    setZoomLevel(zoomLevel) {
      this.set('zoomLevel', zoomLevel);

      const {width: sourceWidth, height: sourceHeight} = this.sourceImageSize;
      const {width, height} = this.imageSize;
      const {top, left} = this.imagePosition;

      const newWidth = sourceWidth * zoomLevel;
      const newHeight = sourceHeight * zoomLevel;

      const widthDiff = newWidth - width;
      const heightDiff = newHeight - height;

      this._updateImage({
        top: top - heightDiff / 2,
        left: left - widthDiff / 2,
        width: newWidth,
        height: newHeight
      });
    },

    drag(deltaX, deltaY) {
      this.set('hasSeenHint', true);

      const {top, left} = this.imagePosition;

      this._updateImage({
        top: top - deltaY,
        left: left - deltaX
      });
    }
  },

  _updateImage(opts) {
    const {imagePosition, imageSize, boundingBoxSize} = this;

    const constraints = {top: 0, left: 0, ...boundingBoxSize};

    const box = {
      top: imagePosition.top,
      left: imagePosition.left,
      width: imageSize.width,
      height: imageSize.height,
      ...opts
    };

    const {top, left, width, height} = constrainBox(constraints, box);

    this.setProperties({
      imageSize: {width, height},
      imagePosition: {top, left}
    });

    this._sendChanges();
  },

  _sendChanges() {
    const {boundingBoxSize, imagePosition, scaleRatio} = this;

    const crop = {
      cropPosition: {
        top: parseInt(-imagePosition.top / scaleRatio, 10),
        left: parseInt(-imagePosition.left / scaleRatio, 10)
      },

      cropSize: {
        width: parseInt(boundingBoxSize.width / scaleRatio, 10),
        height: parseInt(boundingBoxSize.height / scaleRatio, 10)
      }
    };

    this.update({crop});
  },

  _setElementSize() {
    const width = this.$().outerWidth();
    const height = this.$().outerHeight();
    const paddingStrings = this.$().css([
      'paddingTop',
      'paddingRight',
      'paddingBottom',
      'paddingLeft'
    ]);

    const paddings = Object.keys(paddingStrings).reduce((memo, paddingName) => {
      return {
        ...memo,
        [paddingName]: parseInt(paddingStrings[paddingName], 10)
      };
    }, {});

    this.set('elementSize', {width, height, ...paddings});
  }
});
