import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  i18n: service(),

  propTypes: {
    error: T.nullable(
      T.shape({
        property: T.string.isRequired,
        error: T.string
      })
    )
  },

  translatedError: computed('error', function() {
    const propertyKey = `errors.${this.get('error.property')}.${this.get(
      'error.error'
    )}`;
    const fallbackKey = `errors.${this.get('error.error')}`;

    return this.i18n.t(propertyKey, {default: fallbackKey});
  })
});
