import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { gte } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),

  propTypes: {
    venues: T.array.isRequired,
    onDelete: T.func.isRequired,
    onVenueSearchInput: T.func.isRequired,
    isSearchLoading: T.bool.isRequired,
  },

  hasManyVenues: gte('accountManager.totalManagedVenues', 50),
});
