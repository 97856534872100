define("gigshq/graphql/queries/event-publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "EventPublish"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "tenant"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "phoneNumber"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "countryCode"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "event"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    },
                    "value": {
                      "kind": "Variable",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      }
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "slug"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "cancelledAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "deletedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "startedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "published"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "publishedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "instagramPublishedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "printPublishedAt"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "alias": {
                        "kind": "Name",
                        "value": "hasFacebookPagePosts"
                      },
                      "name": {
                        "kind": "Name",
                        "value": "hasFacebookPosts"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "objectType"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "page"
                        }
                      }],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "alias": {
                        "kind": "Name",
                        "value": "hasFacebookEventPosts"
                      },
                      "name": {
                        "kind": "Name",
                        "value": "hasFacebookPosts"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "objectType"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "event"
                        }
                      }],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "alias": {
                        "kind": "Name",
                        "value": "upcomingFacebookPagePosts"
                      },
                      "name": {
                        "kind": "Name",
                        "value": "facebookPosts"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "filterParams"
                        },
                        "value": {
                          "kind": "ListValue",
                          "values": [{
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "scope"
                              },
                              "value": {
                                "kind": "EnumValue",
                                "value": "for_facebook_object_type"
                              }
                            }, {
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "arguments"
                              },
                              "value": {
                                "kind": "ListValue",
                                "values": [{
                                  "kind": "StringValue",
                                  "value": "page",
                                  "block": false
                                }]
                              }
                            }]
                          }, {
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "scope"
                              },
                              "value": {
                                "kind": "EnumValue",
                                "value": "with_upcoming_scheduled_post"
                              }
                            }]
                          }]
                        }
                      }],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "creator"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "gigs"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "startedAt"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "artist"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "name"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "genres"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "id"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "name"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "venue"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "name"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "slug"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "timezone"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "shareableProfile"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "wide"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "widePicture"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "wideThumbnail"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "square"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "facebook"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "instagram"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 1368,
      "source": {
        "body": "\n  query EventPublish($id: ID) {\n    viewer {\n      ... on User {\n        id\n        tenant {\n          id\n          phoneNumber\n          countryCode\n        }\n\n        event(id: $id) {\n          id\n          slug\n          name\n          cancelledAt\n          deletedAt\n          startedAt\n          published\n          publishedAt\n          instagramPublishedAt\n          printPublishedAt\n\n          hasFacebookPagePosts: hasFacebookPosts(objectType: page)\n          hasFacebookEventPosts: hasFacebookPosts(objectType: event)\n\n          upcomingFacebookPagePosts: facebookPosts(\n            filterParams: [\n              {scope: for_facebook_object_type, arguments: [\"page\"]}\n              {scope: with_upcoming_scheduled_post}\n            ]\n          ) {\n            id\n          }\n\n          creator {\n            id\n          }\n\n          gigs {\n            id\n            startedAt\n\n            artist {\n              id\n              name\n\n              genres {\n                id\n                name\n              }\n            }\n          }\n\n          venue {\n            id\n            name\n            slug\n            timezone\n          }\n\n          shareableProfile {\n            id\n            wide\n            widePicture\n            wideThumbnail\n            square\n            facebook\n            instagram\n          }\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
