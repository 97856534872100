import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  can: service(),

  redirect() {
    const event = this.modelFor('app.events.publish').event;

    if (this.can.cannot('publish event', event, {}))
      this.transitionTo('app.events.publish.print', event.id);

    this.transitionTo('app.events.publish.ghq', event.id);
  }
});
