// Vendor
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import moment from 'moment';
import { task } from 'ember-concurrency';

// Translations
import translations from './translations';

// Utils
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import { sortByDate, groupByDate } from 'gigshq/utils/array';

// Constants
import pagination from 'gigshq/constants/pagination';

// Queries
import publishedEventListingQuery from 'gigshq/graphql/queries/published-event-listing';
import unpublishedEventListingQuery from 'gigshq/graphql/queries/unpublished-event-listing';

// Mutations
import sendEventCreatedEmailMutation from 'gigshq/graphql/mutations/send-event-created-email';
import deleteEventMutation from 'gigshq/graphql/mutations/delete-event';

export default Controller.extend(Localizable(translations), {
  queryParams: {
    fromDate: 'from',
    toDate: 'to',
    selectedVenueId: 'venue',
    filterByPublished: 'published'
  },

  accountManager: service('managers/account-manager'),
  apollo: service(),
  flashMessages: service('flash-messages'),
  i18n: service(),

  fromDate: null,
  toDate: null,
  filterByPublished: true,

  selectedVenue: computed('model.venues.[]', 'selectedVenueId', function() {
    return this.venuesFetcher.searchedVenues.find(venue => venue.id === this.selectedVenueId);
  }),

  offset: pagination.INITIAL_OFFSET,
  isLoading: false,
  areAllEventsLoaded: false,

  eventsCount: readOnly('model.events.length'),

  filteredEvents: computed(
    'model.events.[]',
    'model.events.@each.deletedAt',
    'selectedVenueId',
    function() {
      const filteredEvents = this._filterOutDeletedEvents(
        this.model.events || []
      );

      const selectedVenueEvents = this._filterBySelectedVenueId(
        filteredEvents,
        this.selectedVenueId
      );

      return sortByDate(selectedVenueEvents, 'startedAt');
    }
  ),

  eventsById: computed('filteredEvents.[]', function() {
    return this.filteredEvents.reduce((memo, event) => {
      memo[event.id] = event;
      return memo;
    }, {});
  }),

  groupedEvents: computed('filteredEvents.[]', function() {
    const groupedEventsByDate = groupByDate(this.filteredEvents, 'startedAt');

    return groupedEventsByDate.map(({itemDate, items}) => {
      return {
        key: moment(itemDate).format('D MMMM YYYY'),
        events: [...items]
      };
    });
  }),

  emptyEventMessage: computed('filterByPublished', function() {
    const key = this.filterByPublished
      ? 'empty_published_event_message'
      : 'empty_unpublished_event_message';

    return this.localTranslation(key);
  }),

  loadNextPage: task(function*() {
    if (this.isLoading || this.areAllEventsLoaded) return;

    this.set('isLoading', true);

    const query = this.filterByPublished
      ? publishedEventListingQuery
      : unpublishedEventListingQuery;

    const variables = {
      offset: this.offset,
      limit: pagination.EVENTS_PER_PAGE,
      filters: this._buildFilters(),
    };

    try {
      const viewer = yield this.apollo.queryOperation()(
        {
          query,
          variables
        },
        'viewer'
      );

      this._appendNewEvents(viewer);
    } finally {
      this.set('isLoading', false);
    }
  }).drop(),

  handleSendNotificationClick: task(function*(eventId) {
    const mutation = sendEventCreatedEmailMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, {eventId});

    try {
      yield this.apollo.mutationOperation()(
        {
          mutation,
          variables
        },
        'sendEventCreatedEmail'
      );

      this.flashMessages.success(this.localTranslation('notification.success'));

      this.send('refreshModel');
    } catch (errors) {
      const message = this.localTranslation(
        `notification.errors.${errors[0].messages[0]}`
      );

      this.flashMessages.error(message);
    }
  }).drop(),

  handleDeleteClick: task(function*(eventId) {
    // eslint-disable-next-line no-alert
    if (window.confirm(this.localTranslation('delete.confirm'))) {
      const mutation = deleteEventMutation;
      const variables = GraphQLVariablesBuilder.build(mutation, {eventId});

      try {
        yield this.apollo.mutationOperation()(
          {
            mutation,
            variables
          },
          'deleteEvent'
        );

        this.flashMessages.success(this.localTranslation('delete.success'));

        this.send('refreshModel');
      } catch (errors) {
        const message = this.localTranslation(
          `delete.errors.${errors[0].messages[0]}`
        );

        this.flashMessages.error(message);
      }
    }
  }).drop(),

  setDateRange({fromDate, toDate}) {
    this.setProperties({fromDate, toDate});
  },

  setVenue(venue) {
    this.set('selectedVenueId', venue);
  },

  _appendNewEvents(viewer) {
    const {events} = viewer;

    if (events.length < pagination.EVENTS_PER_PAGE)
      this.set('areAllEventsLoaded', true);

    const uniqueEvents = events.filter(event => {
      return !this.eventsById[event.id];
    });

    const newEvents = this.filteredEvents.concat(uniqueEvents);

    this.set('model.events', newEvents);

    this.incrementProperty('offset', pagination.EVENTS_PER_PAGE);
  },

  _filterOutDeletedEvents(events) {
    return events.filter(event => !event.deletedAt);
  },

  _filterBySelectedVenueId(events, selectedVenueId) {
    if (!selectedVenueId) return events;

    return events.filter(event => selectedVenueId === event.venue.id);
  },

  // eslint-disable-next-line complexity
  _buildFilters() {
      if (!this.filterByPublished) return;

    const filters = [{scope: 'with_published'}];

    if (this.selectedVenueId) {
      filters.push({
        scope: 'for_venue_ids',
        arguments: [this.selectedVenueId]
      });
    }

    if (!this.fromDate && !this.toDate) {
      filters.push({
        scope: 'started_after_or_at',
        arguments: [
          moment()
            .startOf('day')
            .toISOString()
        ]
      });

      return filters;
    }

    if (this.fromDate) {
      filters.push({
        scope: 'started_after_or_at',
        arguments: [
          moment(this.fromDate)
            .startOf('day')
            .toISOString()
        ]
      });
    }

    if (this.toDate) {
      filters.push({
        scope: 'started_before_or_at',
        arguments: [
          moment(this.toDate)
            .endOf('day')
            .toISOString()
        ]
      });
    }

    return filters;
  }
});
