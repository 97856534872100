import { bool, and } from '@ember/object/computed';
import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venueCategoryName: T.nullable(T.string),
    capacityCount: T.nullable(T.number)
  },

  shouldDisplayVenueCategory: bool('venueCategoryName'),
  shouldDisplayCapacityCount: bool('capacityCount'),
  shouldDisplaySeparator: and(
    'shouldDisplayVenueCategory',
    'shouldDisplayCapacityCount'
  )
});
