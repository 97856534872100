import { inject as service } from '@ember/service';
import $ from 'jquery';
import WebEventsRoute from '../base-route';
import RSVP from 'rsvp';
import config from 'gigshq/config/environment';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default WebEventsRoute.extend(Localizable(translations), {
  apollo: service(),
  fastboot: service(),
  geolocationFetcher: service('fetchers/geolocation-fetcher'),
  i18n: service(),
  headData: service('head-data'),
  router: service(),
  eventsFetcher: service('fetchers/events-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  beforeModel() {
    // map view always has to start at page 1
    this.controllerFor('web.events.index').set('page', null);
    this.eventsFetcher.set('currentPage', 1);
  },

  model(_, transition) {
    const fromDate = transition.to.queryParams.from;
    const toDate = transition.to.queryParams.to;
    const city = transition.to.queryParams.city;

    const functionsToRun = [
      this.geolocationFetcher.fetch(),
      this._loadGoogleMapsScript(),
    ];

    const model = RSVP.all(functionsToRun)
      .then(([userLocation, _]) => {
        return {
          venues: this.venuesFetcher.fetchedVenues,
          userLocation,
          fromDate,
          toDate,
          city
        };
      });

    return model;
  },

  async afterModel() {
    this._setMetaTags();
  },

  titleToken() {
    return this.localTranslation('title');
  },

  _setMetaTags() {
    const {i18n, fastboot, localizablePrefix} = this;
    const name = 'map';
    const path = this.router.urlFor('web.events.index.map');

    const pageMeta = PageMetaBuilder.build({
      i18n,
      localizablePrefix,
      fastboot,
      path,
      name
    });

    this.headData.setProperties(pageMeta);
  },

  activate() {
    if (this.get('fastboot.isFastBoot')) return;

    $('html, body').css('overflow', 'hidden');
  },

  deactivate() {
    if (this.get('fastboot.isFastBoot')) return;

    $('html, body').css('overflow', 'auto');
  },
});
