import Component from '@ember/component';
import { gt } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';

const PAGE_SIZE = 30;
const MAX_PAGES = 5;

export default Component.extend({
  propTypes: {
    totalEventCount: T.number,
    totalPageCount: T.number,
    page: T.number,
    onPageSelect: T.func
  },

  hasMultiplePages: gt('totalPageCount', 1),
  maxPages: MAX_PAGES,
  pageSize: PAGE_SIZE,
});
