import { createAction } from 'redux-actions';
import artistImageQuery from 'gigshq/graphql/queries/artist-images';
import userCustomImagesQuery from 'gigshq/graphql/queries/user-custom-images';
import createCropMutation from 'gigshq/graphql/mutations/create-crop';
import pagination from 'gigshq/constants/pagination';

export const loadArtistImagesPending = createAction(
  'CROP_TOOL_LOAD_ARTIST_IMAGES_PENDING'
);
export const loadArtistImagesSuccess = createAction(
  'CROP_TOOL_LOAD_ARTIST_IMAGES_SUCCESS'
);
export const loadArtistImagesFailure = createAction(
  'CROP_TOOL_LOAD_ARTIST_IMAGES_FAILURE'
);

export const loadArtistImages = (executeQuery, artistId) => async dispatch => {
  dispatch(loadArtistImagesPending());

  const query = artistImageQuery;
  const variables = {artistId};

  try {
    const result = await executeQuery({query, variables}, 'viewer');

    dispatch(loadArtistImagesSuccess(result));

    return result;
  } catch (error) {
    dispatch(loadArtistImagesFailure(error));
  }
};

export const loadUserImagesPending = createAction(
  'CROP_TOOL_LOAD_USER_IMAGES_PENDING'
);
export const loadUserImagesSuccess = createAction(
  'CROP_TOOL_LOAD_USER_IMAGES_SUCCESS'
);
export const loadUserImagesFailure = createAction(
  'CROP_TOOL_LOAD_USER_IMAGES_FAILURE'
);

export const loadUserImages = (executeQuery, page) => async dispatch => {
  dispatch(loadUserImagesPending());

  const query = userCustomImagesQuery;
  const variables = {
    pagination: { page, size: pagination.IMAGES_PER_PAGE },
  };

  try {
    const result = await executeQuery({query, variables}, 'viewer');

    dispatch(loadUserImagesSuccess(result));

    return result;
  } catch (error) {
    dispatch(loadUserImagesFailure(error));
  }
};

export const createCropPending = createAction('CROP_TOOL_CREATE_CROP_PENDING');
export const createCropSuccess = createAction('CROP_TOOL_CREATE_CROP_SUCCESS');
export const createCropFailure = createAction('CROP_TOOL_CREATE_CROP_FAILURE');

export const createCrop = (mutate, crop) => async dispatch => {
  dispatch(createCropPending());

  const mutation = createCropMutation;
  const variables = {...crop};

  try {
    const result = await mutate({mutation, variables}, 'createCrop');

    dispatch(createCropSuccess(result));
  } catch (error) {
    dispatch(createCropFailure(error));
  }
};

export const selectImage = createAction('CROP_TOOL_SELECT_IMAGE');
export const goToNextStep = createAction('CROP_TOOL_GO_TO_NEXT_STEP');

export const resetCrops = createAction('CROP_TOOL_RESET_CROPS');
export const resetImageSelection = createAction(
  'CROP_TOOL_RESET_IMAGE_SELECTION'
);
