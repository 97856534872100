// GraphQL Query AST example
// {
//   definitions: [
//     operation: 'mutation',
//     kind: 'OperationDefinition',
//     name: {
//       kind: 'Name',
//       value: 'CreateEvent'
//     },
//     variableDefinitions: [
//       // Non-nullable variable
//       {
//         defaultValue: null,
//         kind: 'VariableDefinition',
//         type: {
//           kind: 'NonNullType',
//           type: {
//             kind: 'NamedType',
//             name: {
//               kind: 'Name',
//               value: 'String' // Type
//             }
//           }
//         },
//         variable: {
//           kind: 'Variable',
//           name: {
//             kind: 'Name',
//             value: 'name' // Variable name
//           }
//         }
//       },
//       // Nullable variable
//       {
//         defaultValue: null,
//         kind: 'VariableDefinition',
//         type: {
//           kind: 'NamedType',
//           name: {
//             kind: 'Name',
//             value: 'String' // Type
//           }
//         },
//         variable: {
//           kind: 'Variable',
//           name: {
//             kind: 'Name',
//             value: 'name' // Variable name
//           }
//         }
//       }
//     ]
//   ]
// }

import config from 'gigshq/config/environment';

const PRIMITIVE_TYPES = ['String', 'Date', 'Int', 'ID', 'Float', 'Boolean'];

export const build = (query, input) => {
  const mutation = extractMutation(query);
  const variableDefinitions = extractVariableDefitions(mutation);

  if (config.environment !== 'production') {
    const mutationName = extractMutationName(mutation);
    validateInput(mutationName, variableDefinitions, input);
  }

  return typecastVariables(variableDefinitions, input);
};

const extractMutation = query => {
  return query.definitions.find(
    definition => definition.operation === 'mutation'
  );
};

const extractMutationName = mutation => {
  return mutation.name.value;
};

const extractVariableDefitions = mutation => {
  const { variableDefinitions } = mutation;

  return variableDefinitions.reduce((memo, definition) => {
    const name = definition.variable.name.value;
    const nullable = definition.type.kind !== 'NonNullType';
    const kind = nullable ? definition.type.kind : definition.type.type.kind;
    const isListType = kind === 'ListType';

    let type;

    if (isListType) {
      type = 'Array';
    } else {
      type = nullable
        ? definition.type.name.value
        : definition.type.type.name.value;
    }

    memo[name] = { name, nullable, type };

    return memo;
  }, {});
};

const validateInput = (mutationName, variableDefinitions, input) => {
  return Object.values(variableDefinitions).every(definition => {
    if (!definition.nullable) {
      if (!input.hasOwnProperty(definition.name)) {
        console.warn(
          `"${mutationName}" requires a variable "${
            definition.name
          }" and it was not present.\n${JSON.stringify(input, null, 2)}`
        );
      }
    }

    return true;
  });
};

const typecastVariables = (variableDefinitions, input) => {
  return Object.values(variableDefinitions).reduce((memo, definition) => {
    const name = definition.name;
    let value;

    if (!PRIMITIVE_TYPES.includes(definition.type)) {
      memo[definition.name] = input[name];
      return memo;
    }

    if (!input.hasOwnProperty(name) || input[name] == null) {
      if (definition.nullable) {
        value = null;
      } else {
        value = assignDefaultTypeValue(definition.type);
      }
    } else {
      value = typecastValue(definition, input[name]);
    }

    memo[definition.name] = value;

    return memo;
  }, {});
};

/* eslint-disable complexity */
const assignDefaultTypeValue = type => {
  switch (type) {
    case 'String':
      return '';
    case 'Date':
      return '';
    case 'Int':
      return 0;
    case 'ID':
      return '';
    case 'Float':
      return 0;
    case 'Boolean':
      return false;
  }
};

const typecastValue = ({ name, type, nullable }, value) => {
  if (value === undefined && !nullable) {
    if (config.environment !== 'production') {
      console.warn(
        `Non-nullable property '${name}' of type ${type} requires a value.`
      );
    }

    value = assignDefaultTypeValue(type);
  }

  switch (type) {
    case 'String':
      return String(value);
    case 'Date':
      return typeof value === 'string' ? value : value.toISOString();
    case 'Int':
      return +value;
    case 'ID':
      return String(value);
    case 'Float':
      return +value;
    case 'Boolean':
      return !!value;
  }
};
/* eslint-enable complexity */
