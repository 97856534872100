import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import EventMetaBuilder from 'gigshq/utils/event-meta-builder';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    facebookAccounts: T.array.isRequired,
    deleteFacebookPost: T.func.isRequired
  },

  fastboot: service(),
  router: service(),

  facebookCardTitle: computed('event', function() {
    const {i18n, event} = this;

    return EventMetaBuilder.title({i18n, event});
  }),

  facebookCardDescription: computed('event', 'venue', function() {
    const {i18n, event} = this;
    const venue = event.venue;

    return EventMetaBuilder.description({i18n, event, venue});
  }),

  facebookCoverImage: computed(
    'event.shareableProfile.{wide,facebook}',
    function() {
      return (
        this.get('event.shareableProfile.facebook') ||
        this.get('event.shareableProfile.wide')
      );
    }
  ),

  facebookCardURL: computed('fastboot.isFastBoot', function() {
    return this.get('fastboot.isFastBoot')
      ? this.get('fastboot.request.host')
      : window.location.host;
  }),

  facebookSharingUrl: computed('event', function() {
    const {fastboot, router, event} = this;

    const slug = event.slug;
    const venueSlug = event.venue.slug;

    const path = router.urlFor('web.events.show', {
      // eslint-disable-next-line camelcase
      venue_slug: venueSlug,
      slug
    });

    return PageMetaBuilder.url({fastboot, path});
  }),

  sortedUpcomingFacebookPosts: computed(
    'event.upcomingFacebookPagePosts.[]',
    function() {
      return this.get('event.upcomingFacebookPagePosts').sort(
        (firstFacebookPost, secondFacebookPost) => {
          const firstDate = moment(
            firstFacebookPost.posts[0].scheduled_at
          ).valueOf();
          const secondDate = moment(
            secondFacebookPost.posts[0].scheduled_at
          ).valueOf();

          if (firstDate < secondDate) return -1;
          if (firstDate > secondDate) return 1;

          return 0;
        }
      );
    }
  ),

  actions: {
    handleDeleteFacebookPost(facebookPost) {
      const facebookAccount = this.facebookAccounts.find(
        account => account.id === facebookPost.facebookObjectId
      );

      this.deleteFacebookPost({
        facebookPostId: facebookPost.id,
        facebookAccessToken: facebookAccount.accessToken
      });
    }
  }
});
