import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import {
  duplicateEvent,
  setFormValue,
  resetForm
} from 'gigshq/actions/duplicate-event';

const mapStateToComputed = ({duplicateEvent}) => {
  const {
    isDirty,
    isPending,
    data,
    errors,
    form: {dates, autoPublishEvent}
  } = duplicateEvent;

  return {
    isDirty,
    isPending,
    data,
    errors,
    dates,
    autoPublishEvent
  };
};

const mapDispatchToActions = dispatch => {
  return {
    duplicateEvent: (mutate, variables) =>
      dispatch(duplicateEvent({mutate, ...variables})),
    setFormValue: (name, value) => dispatch(setFormValue({name, value})),
    resetForm: () => dispatch(resetForm())
  };
};

const EventDuplicationModal = ReduxContainer.extend({
  apollo: service(),
  flashMessages: service('flash-messages'),

  propTypes: {
    event: T.looseShape({
      id: T.string.isRequired
    }).isRequired,
    onEventDuplicate: T.func.isRequired,
    onEventDuplicateFailure: T.func.isRequired,
    onClose: T.func.isRequired
  },

  eventId: readOnly('event.id'),

  actions: {
    close() {
      this.get('actions.resetForm')();
      this.onClose();
    },

    duplicate() {
      const variables = this.getProperties(
        'dates',
        'eventId',
        'autoPublishEvent'
      );

      this.get('actions.duplicateEvent')(
        this.apollo.mutationOperation(),
        variables
      ).then(() => {
        if (this.errors) {
          this.onEventDuplicateFailure(this.errors[0].messages[0]);
          return;
        }

        this.get('actions.resetForm')();
        this.onEventDuplicate();
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(EventDuplicationModal);
