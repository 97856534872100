import Ember from 'ember';

export default Ember.Mixin.create({
  activate: () => {
    try {
      window.scrollTo(0, 0);
    // eslint-disable-next-line no-empty
    } catch (error ) {}
  }
});
