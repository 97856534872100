import { inject as service } from '@ember/service';
import { bool, not, equal, or } from '@ember/object/computed';
import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import EventMetaBuilder from 'gigshq/utils/event-meta-builder';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';

const DEFAULT_PUBLICATION_HOUR = '17:00';
const DEFAULT_PUBLICATION_DATE = () => {
  return moment()
    .startOf('day')
    .add(1, 'day')
    .toDate();
};

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    facebookAccounts: T.array.isRequired,
    facebookUser: T.object.isRequired,
    publish: T.func.isRequired,
    toggleCaptionToolIsOpened: T.func.isRequired
  },

  i18n: service(),
  router: service(),
  fastboot: service(),

  hasSuccessfullyPublished: false,

  characterCountLimit: 160,
  facebookMessage: '',
  isReadyForPublication: bool('event.shareableProfile.wide'),
  isNotReadyForPublication: not('isReadyForPublication'),

  selectedPublicationTimeIsNow: equal('selectedPublicationTime', 'now'),
  selectedPublicationTimeIsLater: equal('selectedPublicationTime', 'later'),
  selectedPublicationTime: 'now',
  selectedPublicationDate: DEFAULT_PUBLICATION_DATE(),
  selectedPublicationHour: DEFAULT_PUBLICATION_HOUR,

  selectedFacebookAccount: null,
  includeGHQLink: true,

  hasAlreadyPublishedToFacebook: or(
    'event.hasFacebookPagePosts',
    'event.hasFacebookEventPosts'
  ),
  hasUpcomingFacebookPosts: bool('event.upcomingFacebookPagePosts.length'),

  facebookCardTitle: computed('event', function() {
    const {i18n, event} = this;

    return EventMetaBuilder.title({i18n, event});
  }),

  facebookCardDescription: computed('event', 'venue', function() {
    const {i18n, event} = this;
    const venue = event.venue;

    return EventMetaBuilder.description({i18n, event, venue});
  }),

  facebookCoverImage: computed(
    'event.shareableProfile.{wide,facebook}',
    function() {
      return (
        this.get('event.shareableProfile.facebook') ||
        this.get('event.shareableProfile.wide')
      );
    }
  ),

  facebookCardURL: computed('fastboot.isFastBoot', function() {
    return this.get('fastboot.isFastBoot')
      ? this.get('fastboot.request.host')
      : window.location.host;
  }),

  facebookSharingUrl: computed('event', 'includeGHQLink', function() {
    if (!this.includeGHQLink) return null;

    const {fastboot, router, event} = this;

    const slug = event.slug;
    const venueSlug = event.venue.slug;

    const path = router.urlFor('web.events.show', {
      // eslint-disable-next-line camelcase
      venue_slug: venueSlug,
      slug
    });

    return PageMetaBuilder.url({fastboot, path});
  }),

  mascotImagePath: computed(
    'hasSuccessfullyPublished',
    'isReadyForPublication',
    function() {
      const {hasSuccessfullyPublished, isReadyForPublication} = this;

      if (hasSuccessfullyPublished)
        return AssetsCatalog.LargeGigiStates.THUMBS_UP.asset;
      if (!isReadyForPublication)
        return AssetsCatalog.LargeGigiStates.PERPLEX.asset;

      return AssetsCatalog.LargeGigiStates.NEUTRAL.asset;
    }
  ),

  publishButtonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.publishing')
      : this.localTranslation('buttons.publish');
  }),

  didInsertElement() {
    this.set('selectedFacebookAccount', this.facebookAccounts[0]);
  },

  resetForm() {
    this.setProperties({
      facebookMessage: '',
      selectedPublicationTime: null,
      selectedPublicationDate: DEFAULT_PUBLICATION_DATE(),
      selectedPublicationHour: DEFAULT_PUBLICATION_HOUR,
      includeGHQLink: true
    });

    $('.gigs-tri-column-layout').scrollTop(0);
  },

  actions: {
    selectPublicationTime(publicationTime) {
      this.setProperties({
        selectedPublicationDate: DEFAULT_PUBLICATION_DATE(),
        selectedPublicationHour: DEFAULT_PUBLICATION_HOUR,
        selectedPublicationTime: publicationTime
      });
    },

    selectPublicationDate(date) {
      this.set('selectedPublicationDate', date);
    },

    selectPublicationHour(hour) {
      this.set('selectedPublicationHour', hour);
    },

    selectFacebookAccount(account) {
      this.set('selectedFacebookAccount', account);
    },

    setFacebookMessage(message) {
      this.set('facebookMessage', message);
    },

    setIncludeGHQLink(value) {
      this.set('includeGHQLink', value);
    },

    handlePublish() {
      const [hours, minutes] = this.selectedPublicationHour.split(':');

      const formattedDate = moment(this.selectedPublicationDate)
        .hours(hours)
        .minutes(minutes)
        .tz('America/Montreal')
        .toISOString();

      const publishDates = this.selectedPublicationTimeIsLater
        ? [formattedDate]
        : [];

      const publishingOptions = {
        facebookAccessToken: this.get('selectedFacebookAccount.accessToken'),
        facebookPageName: this.get('selectedFacebookAccount.name'),
        facebookPagePictureUrl: this.get('selectedFacebookAccount.pictureUrl'),
        message: this.facebookMessage,
        facebookPageId: this.get('selectedFacebookAccount.id'),
        link: this.facebookSharingUrl,
        publishDates
      };

      this.set('isPending', true);

      this.publish(publishingOptions)
        .then(error => {
          if (!error) {
            this.set('hasSuccessfullyPublished', true);
            this.resetForm();
          }
        })
        .finally(() => this.set('isPending', false));
    }
  }
});
