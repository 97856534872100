import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    artists: T.object,
    showImage: T.bool
  },

  getDefaultProps() {
    return {
      showImage: true
    };
  }
});
