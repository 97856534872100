import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: {
    fromDate: 'from',
    toDate: 'to',
    page: 'page',
    selectedVenueId: 'venue',
    city: 'city'
  }
});
