import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),

  actions: {
    handleCreate(artistId) {
      this.accountManager.setCompletedAccount(true);

      this.transitionToRoute('app.artists.edit', artistId);
    }
  }
});
