import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import config from 'gigshq/config/environment';

const ENTER_KEY_CODE = 13;

export default Component.extend({
  propTypes: {
    artist: T.looseShape({
      id: T.string,
      name: T.string,
      profileImage: T.nullable(
        T.looseShape({
          id: T.string,
          thumbnailImageUrl: T.string
        })
      )
    }).isRequired,
    onSetArtist: T.func.isRequired,
    onSelectArtist: T.func.isRequired,
    onCreateArtist: T.func.isRequired
  },

  accountManager: service('managers/account-manager'),
  artistsFetcher: service('fetchers/artists-fetcher'),

  clickOutside() {
    const searchText = this._searchText;
    if (searchText && searchText !== '') {
      this.createLocalArtist({ name: searchText });
      this.set('_searchText', '');
    }
  },

  handleArtistSelection(artist) {
    this.onSelectArtist(artist);
  },

  createLocalArtist({ name }) {
    this.onCreateArtist({ name });
  },

  searchArtists: task(function*(value) {
    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);

    const response = yield this.artistsFetcher.searchArtists(value);
    return response.artists;
  }).restartable(),

  actions: {
    handleKeyPress({ searchText }, event) {
      if (event.keyCode === ENTER_KEY_CODE)
        this.createLocalArtist({ name: searchText });
    },

    handleBlur({ searchText }) {
      if (searchText !== '') this.set('_searchText', searchText);
    }
  }
});
