import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

// Queries
import transferVenueOwnership from 'gigshq/graphql/mutations/transfer-venue-ownership';

export default Component.extend({
  apollo: service(),
  router: service(),

  /* eslint-disable camelcase */
  errorMessages: {
    access_denied: 'You don’t have the necessary rights to update this venue.',
    venue_not_found:
      'We could not find your venue. Maybe it is already belonging to another user.',
    user_not_found:
      'We could not find the account of the new owner. Maybe they used another email address or they are not a venue owner.'
  },
  /* eslint-enable camelcase */

  newOwnerEmail: '',
  isPending: false,

  transferVenueOwnership: task(function*(venueId, newOwnerEmail) {
    const mutation = transferVenueOwnership;
    const variables = {venueId, newOwnerEmail};

    this.set('isPending', true);

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'transferVenueOwnership');

      this.setProperties({newOwnerEmail: ''});
      this.flashMessages.success('Venue has been successfully transfered.');
      this.router.transitionTo('admin.tenants');
    } catch (errors) {
      this.flashMessages.error(this.errorMessages[errors[0].messages[0]]);
    }
    this.set('isPending', false);
  }).drop(),

  actions: {
    updateEmail(email) {
      this.set('newOwnerEmail', email);
    },
    transferOwnership() {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you want to transfer this venue to a new owner?'
        )
      ) {
        this.transferVenueOwnership.perform(
          this.get('venue.id'),
          this.newOwnerEmail
        );
      }
    }
  }
});
