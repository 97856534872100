import { or, bool } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  selectedNetwork: 'website',

  propTypes: {
    socialProfile: T.looseShape({
      websiteUrl: T.nullable(T.string),
      facebookUrl: T.nullable(T.string),
      twitterUrl: T.nullable(T.string),
      instagramUrl: T.nullable(T.string),
      spotifyUrl: T.nullable(T.string),
      bandcampUrl: T.nullable(T.string),
      itunesUrl: T.nullable(T.string),
      soundcloudUrl: T.nullable(T.string),
      playMusicUrl: T.nullable(T.string),
      tidalUrl: T.nullable(T.string),
      youtubeUrl: T.nullable(T.string)
    })
  },

  isVisible: or(
    'displayWebsite',
    'displayFacebook',
    'displayTwitter',
    'displayInstagram',
    'displaySpotify',
    'displayBandcamp',
    'displayItunes',
    'displaySoundcloud',
    'displayPlayMusic',
    'displayTidal',
    'displayYoutube'
  ),

  displayWebsite: bool('socialProfile.websiteUrl'),
  displayFacebook: bool('socialProfile.facebookUrl'),
  displayTwitter: bool('socialProfile.twitterUrl'),
  displayInstagram: bool('socialProfile.instagramUrl'),
  displaySpotify: bool('socialProfile.spotifyUrl'),
  displayBandcamp: bool('socialProfile.bandcampUrl'),
  displayItunes: bool('socialProfile.itunesUrl'),
  displaySoundcloud: bool('socialProfile.soundcloudUrl'),
  displayPlayMusic: bool('socialProfile.playMusicUrl'),
  displayTidal: bool('socialProfile.tidalUrl'),
  displayYoutube: bool('socialProfile.youtubeUrl')
});
