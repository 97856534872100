import { PropTypes as T } from 'ember-prop-types';

export const initialize = () => {
  T.nullable = type => T.oneOfType([T.null, type]);
};

export default {
  name: 'nullable-prop-type',
  initialize
};
