import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';

export default Component.extend(Localizable(translations), {
  mascotImagePath: AssetsCatalog.LargeGigiStates.NEUTRAL.asset,

  buttonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.publishing')
      : this.localTranslation('buttons.publish');
  }),

  actions: {
    handlePublish() {
      this.set('isPending', true);

      this.publish().finally(() => this.set('isPending', false));
    }
  }
});
