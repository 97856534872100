import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import mutation from 'gigshq/graphql/mutations/venue-offer-persist';
import deleteOfferMutation from 'gigshq/graphql/mutations/venue-offer-delete';
import deleteImageMutation from 'gigshq/graphql/mutations/delete-image';

export const saveOfferPending = createAction('OFFERS_SAVE_OFFER_PENDING');
export const saveOfferSuccess = createAction('OFFERS_SAVE_OFFER_SUCCESS');
export const saveOfferFailure = createAction('OFFERS_SAVE_OFFER_FAILURE');

export const saveOffer = ({form, mutate, offer}) => async dispatch => {
  dispatch(saveOfferPending({form}));

  try {
    const variables = GraphQLVariablesBuilder.build(mutation, offer);
    const result = await mutate({mutation, variables}, 'venueOfferPersist');
    dispatch(saveOfferSuccess({form, result}));

    return result;
  } catch (error) {
    console.log(error);
    dispatch(saveOfferFailure({form, error}));
  }
};

export const deleteOfferPending = createAction('OFFERS_DELETE_OFFER_PENDING');
export const deleteOfferSuccess = createAction('OFFERS_DELETE_OFFER_SUCCESS');
export const deleteOfferFailure = createAction('OFFERS_DELETE_OFFER_FAILURE');

export const deleteOffer = ({mutate, offerId}) => async dispatch => {
  dispatch(deleteOfferPending());

  try {
    const mutation = deleteOfferMutation;
    const variables = { offerId };
    await mutate({mutation, variables}, 'venueOfferDelete');

    dispatch(deleteOfferSuccess());
  } catch (error) {
    console.log(error);
    dispatch(deleteOfferFailure(error));
  }
};

export const setFormValue = createAction('OFFERS_SET_FORM_VALUE');
export const setVenue = createAction('OFFER_SET_VENUE');
export const setOfferImage = createAction('OFFER_SET_OFFER_IMAGE');
export const setOfferType = createAction('OFFER_SET_OFFER_TYPE');
export const resetForm = createAction('OFFERS_RESET_FORM');
export const fillForm = createAction('OFFERS_FILL_FORM');

export const deleteImagePending = createAction('OFFERS_DELETE_IMAGE_PENDING');
export const deleteImageSuccess = createAction('OFFERS_DELETE_IMAGE_SUCCESS');
export const deleteImageFailure = createAction('OFFERS_DELETE_IMAGE_FAILURE');

export const deleteImage = ({mutate, imageId}) => async dispatch => {
  dispatch(deleteImagePending());

  const mutation = deleteImageMutation;
  const variables = {imageId};

  try {
    await mutate(
      {mutation, variables, refetchQueries: ['OfferImages']},
      'deleteImage'
    );

    dispatch(deleteImageSuccess());
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};
