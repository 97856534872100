import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  redux: service(),
  router: service(),

  redirect() {
    const { session } = this.redux.getState();

    if (session.data) {
      this.router.transitionTo('app');
    }
  }
});
