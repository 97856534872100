import { handleActions } from 'redux-actions';
import {
  saveVenuePending,
  saveVenueSuccess,
  saveVenueFailure,
  deleteImagePending,
  deleteImageSuccess,
  deleteImageFailure,
  setFormValue,
  resetForm,
  fillForm,
  setFormSocialProfileValue,
  markFormAsUploading
} from 'gigshq/actions/venues';

const VENUE_FORM_INITIAL_STATE = {
  deleteImageMutation: {
    isPending: false,
    errors: null
  },

  form: {
    id: '',
    name: '',
    description: '',
    capacityCount: null,
    venueCategoryId: '',
    streetAddress: '',
    city: '',
    country: '',
    postalCode: '',
    formattedAddress: '',
    latitude: 0.0,
    longitude: 0.0,
    socialProfile: {
      bandcampUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      itunesUrl: '',
      playMusicUrl: '',
      soundcloudUrl: '',
      spotifyUrl: '',
      tidalUrl: '',
      twitterUrl: '',
      youtubeUrl: ''
    },
    profileImage: {
      id: '',
      imageUrl: ''
    },
    openingHours: [
      {day: 'sun', closed: true},
      {day: 'mon', closed: true},
      {day: 'tue', closed: true},
      {day: 'wed', closed: true},
      {day: 'thu', closed: true},
      {day: 'fri', closed: true},
      {day: 'sat', closed: true}
    ]
  },
  isDirty: false,
  isPending: false,
  isUploading: false,
  data: null,
  errors: null
};

const INITIAL_STATE = {
  venueCreation: VENUE_FORM_INITIAL_STATE,
  venueEdition: VENUE_FORM_INITIAL_STATE
};

export default handleActions(
  {
    [saveVenuePending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveVenueSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isPending: false,
        data: payload.result.venue,
        errors: null
      }
    }),

    [saveVenueFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [deleteImagePending]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: true,
        errors: null
      }
    }),

    [deleteImageSuccess]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: null
      }
    }),

    [deleteImageFailure]: (state, {payload}) => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: payload
      }
    }),

    [resetForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...INITIAL_STATE[payload.form]
      }
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        form: {
          ...state[payload.form].form,
          [payload.name]: payload.value
        }
      }
    }),

    [fillForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: payload.values
      }
    }),

    [setFormSocialProfileValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        isSaved: false,
        form: {
          ...state[payload.form].form,
          socialProfile: {
            ...state[payload.form].form.socialProfile,
            [payload.name]: payload.value
          }
        }
      }
    }),

    [markFormAsUploading]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isUploading: payload.isUploading
      }
    })
  },
  INITIAL_STATE
);
