import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';

// Actions
import { logout } from 'gigshq/actions/session';

const mapStateToComputed = ({
  session: {
    data,
    loginState: {isPending, error}
  }
}) => {
  return {
    error,
    isLoggingOut: isPending,
    session: data
  };
};

const mapDispatchToActions = dispatch => {
  return {
    logout: (clearStore, session) => dispatch(logout(clearStore, session))
  };
};

const LogoutButton = ReduxContainer.extend(LocationStyleable, {
  propTypes: {
    location: T.string,
    onLogoutSuccess: T.func.isRequired
  },

  tagName: 'div'
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(LogoutButton);
