import Service, { inject as service } from '@ember/service';
import moment from 'moment';
import eventsQuery from 'gigshq/graphql/queries/web-events';
import pagination from 'gigshq/constants/pagination';

export default Service.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),
  globalSvc: service('global'),
  sessionFetcher: service('fetchers/session-fetcher'),
  sessionStorage: service('session-storage'),

  fetchedEvents: [],
  venueIds: [],
  dateRange: { fromDate: '', toDate: '' },
  currentPage: 1,
  isLoadingEvents: false,
  isAppendingEvents: false,
  totalEventCount: 0,
  totalPageCount: 0,

  async fetchEvents(forVenueIds, page, fromDate, toDate, appendEvents, fetchEventsWithPhotosOnly) {
    if (forVenueIds.length === 0 && !fetchEventsWithPhotosOnly)
      return;

    if (appendEvents)
      this.set('isAppendingEvents', true);
    else {
      this.set('isLoadingEvents', true);
    }

    page = parseInt(page, 10) || this.currentPage;

    const query = eventsQuery(this.sessionFetcher.determineUserRole());
    const filters = this._getFilters(fromDate, toDate, fetchEventsWithPhotosOnly);
    const variables = this._getVariables(filters, forVenueIds, page, fetchEventsWithPhotosOnly);

    this.set('dateRange', { fromDate, toDate });
    this.set('currentPage', page);

    return this._makeEventsFetchCall(query, variables, appendEvents);
  },

  _getFilters(fromDate, toDate, fetchEventsWithPhotosOnly) {
    if (fetchEventsWithPhotosOnly) return [];
    const filters = [
      {
        scope: 'started_after_or_at',
        arguments: [
          moment(fromDate)
            .startOf('day')
            .toISOString()
        ]
      }
    ];

    if (toDate) {
      filters.push(
        {
          scope: 'started_before_or_at',
          arguments: [
            moment(toDate)
              .endOf('day')
              .toISOString()
          ]
        });
    }

    return filters;
  },

  _getVariables(filters, forVenueIds, page, fetchEventsWithPhotosOnly) {
    const variables = {
      filters,
      pagination: { page, size: pagination.EVENTS_PER_PAGE },
      orderParams: [
        {
          attribute: 'startedAt',
          order: fetchEventsWithPhotosOnly ? 'DESC' : 'ASC'
        },
        {
          attribute: 'createdAt',
          order: 'ASC'
        }
      ],
      fetchEventsWithPhotosOnly,
    };

    if (!fetchEventsWithPhotosOnly && forVenueIds) {
      variables.filters.push({
        scope: 'for_venue_ids',
        arguments: [ forVenueIds ]
      });
      this.set('venueIds', forVenueIds);
    }

    return variables;
  },

  async _makeEventsFetchCall(query, variables, appendEvents) {
    const fetchEventsQuery = await this.apollo.queryOperation()({ query, variables }, 'viewer');
    const totalEventCount = fetchEventsQuery.publicEvents.itemCount;
    const totalPageCount = fetchEventsQuery.publicEvents.pageCount;

    const currFetchedEvents = appendEvents
      ? this.fetchedEvents.concat(fetchEventsQuery.publicEvents.items)
      : fetchEventsQuery.publicEvents.items;

    this.accountManager.setAccount(fetchEventsQuery);

    if ( !(this.get('isDestroyed') || this.get('isDestroying')) ) {
      this.set('fetchedEvents', currFetchedEvents);
      this.set('totalEventCount', totalEventCount);
      this.set('totalPageCount', totalPageCount);

      if (appendEvents)
        this.set('isAppendingEvents', false);
      else
        this.set('isLoadingEvents', false);
        this.globalSvc.resetScroll();
    }

    return currFetchedEvents;
  },
});
