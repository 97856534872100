export default [
  {
    key: 'en',
    label: 'English'
  },
  {
    key: 'fr',
    label: 'Français'
  }
];
