import { inject as service } from '@ember/service';
import { or, not, notEmpty, and, alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import pagination from 'gigshq/constants/pagination';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

const SCROLL_CONTAINER = '.at-crop-tool';

export default Component.extend(Localizable(translations), {
  propTypes: {
    allowVenueUpload: T.bool,
    venueId: T.string,
    userId: T.string.isRequired,
    artists: T.array.isRequired,
    artistImageQuery: T.shape({
      isPending: T.bool.isRequired,
      errors: T.nullable(T.array),
      data: T.nullable(T.object)
    }).isRequired,
    userImageQuery: T.shape({
      isPending: T.bool.isRequired,
      errors: T.nullable(T.array),
      data: T.nullable(T.object)
    }).isRequired,
    selectedImage: T.nullable(T.object).isRequired,
    selectImage: T.func.isRequired,
    goToNextStep: T.func.isRequired,
    loadArtistImages: T.func.isRequired,
    loadUserImages: T.func.isRequired
  },

  flashMessages: service('flash-messages'),
  accountManager: service('managers/account-manager'),

  isLoading: false,
  isPending: or('artistImageQuery.isPending', 'userImageQuery.isPending', 'isLoading'),
  isNotPending: not('isPending'),

  hasSelectedImage: notEmpty('selectedImage'),

  canGoToNextStep: and('hasSelectedImage', 'isNotPending'),
  cannotGoToNextStep: not('canGoToNextStep'),

  selectedTabId: null,
  selectedArtist: null,
  selectedArtistId: alias('selectedTabId'),

  images: [],
  imagesCurrentPage: -1,
  imagesTotalPageCount: 0,

  isDisabledUploader: computed('selectedTabId', 'allowUserUpload', function() {
    const {selectedTabId, allowUserUpload} = this;
    return !(!!selectedTabId || allowUserUpload);
  }),

  tabs: computed('artists.[]', function() {
    const artistTabs = this.artists.map(artist => ({
      id: artist.id,
      label: this.localTranslation('artist_photos', {artist: artist.name})
    }));

    if (this.get('accountManager.isUserTypeTenant')) {
      artistTabs.unshift({
        id: null,
        label: this.localTranslation('user_photo')
      });
    }

    return artistTabs;
  }),

  loadUserImagesTask: task(function*() {
    this.selectImage(null);

    if (this.imagesCurrentPage >= this.imagesTotalPageCount) return;

    if (this.imagesCurrentPage < 0)
      this.imagesCurrentPage = 0;

    yield this.loadUserImages(this.imagesCurrentPage + 1)
      .then((response) => {
        this.set('images', this.images.concat(response.images.items));
        this.set('imagesCurrentPage', this.imagesCurrentPage + 1);
        this.set('imagesTotalPageCount', response.images.totalPageCount);
        this.set('isLoading', false);
      })
      .catch(() =>
        this.flashMessages.error(
          this.localTranslation('errors.could_not_load_images')
        )
      );
  }).restartable(),

  loadArtistImagesTask: task(function*(artistId) {
    this.set('images', []);
    this.selectImage(null);

    yield this.loadArtistImages(artistId)
      .then(result => this.set('images', result.artist.images))
      .catch(() =>
        this.flashMessages.error(
          this.localTranslation('errors.could_not_load_images')
        )
      );
  }).restartable(),

  init() {
    this._super(...arguments);
    this.onScroll = this.onScroll.bind(this);
  },

  didInsertElement() {
    if (this.get('accountManager.isUserTypeArtist')) {
      this.send('selectTab', this.artists[0].id);
    } else {
      this.loadUserImagesTask.perform();
    }

    document
      .querySelector(SCROLL_CONTAINER)
      .addEventListener('scroll', this.onScroll);
  },

  willDestroyElement() {
    document
      .querySelector(SCROLL_CONTAINER)
      .removeEventListener('scroll', this.onScroll);
  },

  onScroll() {
    if (this.isLoading) return;

    const scrollContainer = document.querySelector(SCROLL_CONTAINER);
    const scrollTop = scrollContainer.scrollTop;
    const innerHeight = scrollContainer.offsetHeight;
    const scrollHeight = scrollContainer.scrollHeight;

    if (scrollTop + innerHeight >= scrollHeight - pagination.TRIGGER_FROM_BOTTOM_DISTANCE)
      this.loadMoreImages();
  },

  loadMoreImages() {
    this.set('isLoading', true);
    this.loadUserImagesTask.perform();
  },

  actions: {
    selectTab(id) {
      if (id === this.selectedTabId) return;

      this.set('selectedTabId', id);

      const selectedArtist = this.artists.find(
        artist => artist.id === this.selectedTabId
      );

      this.set('selectedArtist', selectedArtist);

      if (selectedArtist) {
        this.loadArtistImagesTask.perform(selectedArtist.id);
      } else {
        this.loadUserImagesTask.perform();
      }
    },

    removeImage(image) {
      const images = this.images;
      const index = images.indexOf(image);

      const newImages = [...images.slice(0, index), ...images.slice(index + 1)];
      this.set('images', newImages);
    }
  }
});
