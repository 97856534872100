import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';

export default Component.extend(LocationStyleable, {
  classNameBindings: ['isPending'],

  propTypes: {
    isPending: T.bool.isRequired,
    images: T.array,
    multiple: T.bool,
    hideUploader: T.bool,
    uploaderLabel: T.oneOfType([T.string, T.safeString])
  },

  getDefaultProps() {
    return {
      images: [],
      multiple: true,
      hideUploader: false,
      uploaderLabel: ''
    };
  },

  tagName: 'ul',

  actions: {
    didStartUpload(files) {
      files = files.map(file => ({
        isUploader: true,
        file
      }));

      this.set('images', files.concat(this.images));
    }
  }
});
