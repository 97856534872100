import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/event-publish';

export default Route.extend({
  apollo: service(),
  can: service(),

  model({id}) {
    const variables = {id};
    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  redirect(model) {
    if (this.can.cannot('accessPublicationTools event', model.event, {}))
      this.transitionTo('app.events.index');
    if (this.can.cannot('publish event', model.event, {}))
      this.transitionTo('app.events.publish.print', model.event.id);
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
