import Service, { inject as service } from '@ember/service';
import moment from 'moment';

export default Service.extend({
  i18n: service(),
  moment: service(),
  cookies: service(),

  locale: null,

  setLocale(locale, {persist} = {persist: false}) {
    this.locale = locale;
    moment.locale(locale);
    this.moment.setLocale(locale);
    this.set('i18n.locale', locale);

    if (persist) {
      this.cookies.write('locale', locale, {path: '/'});
    }
  }
});
