import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';
import moment from 'moment';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT } from 'gigshq/constants/user-types';
import config from 'gigshq/config/environment';

// Actions
import { resetImageSelection, resetCrops } from 'gigshq/actions/crop-tool';
import { setFormValue as setArtistCreationToolFormValue } from 'gigshq/actions/artist-creation-tool';
import {
  saveEvent,
  setFormValue,
  setVenue,
  resetForm,
  addGig,
  removeGig,
  updateGig,
  updateGigAtIndex,
  refreshGig,
  reloadCrops,
  markFormAsUploading,
  saveEventPhotoOrder,
  deleteImage
} from 'gigshq/actions/events';
import { createLocalArtist } from 'gigshq/actions/artists';

// Queries
import eventImageQuery from 'gigshq/graphql/queries/event-images';

const mapStateToComputed = state => {
  const {
    artists: {
      localArtistCreation: {data: localArtist, isPending: isPendingLocalArtist}
    },
    events: {
      eventEdition: {
        isDirty,
        isPending,
        errors,
        form: {
          id,
          startedAt,
          publishedAt,
          name,
          description,
          slug,
          ticketPriceCents,
          doorPriceCents,
          ticketPurchaseUrl,
          gigs,
          wideCrop,
          squareCrop,
          venue
        }
      }
    }
  } = state;

  return {
    isDirty,
    isPending,
    localArtist,
    isPendingLocalArtist,
    errors,
    id,
    startedAt: moment(startedAt),
    publishedAt: moment(publishedAt),
    name,
    description,
    slug,
    ticketPriceCents,
    doorPriceCents,
    ticketPurchaseUrl,
    gigs,
    venue,
    wideCrop,
    squareCrop
  };
};

const mapDispatchToActions = dispatch => {
  return {
    addGig: gig => dispatch(addGig({form: 'eventEdition', gig})),
    removeGig: gig => dispatch(removeGig({form: 'eventEdition', gig})),
    updateGig: gig => dispatch(updateGig({form: 'eventEdition', gig})),
    updateGigAtIndex: (gig, index) =>
      dispatch(updateGigAtIndex({form: 'eventEdition', index, gig})),
    saveEvent: (mutate, event) =>
      dispatch(saveEvent({form: 'eventEdition', mutate, event})),
    resetCrops: () => dispatch(resetCrops()),
    resetForm: () => dispatch(resetForm({form: 'eventEdition'})),
    resetImageSelection: () => dispatch(resetImageSelection()),
    setVenue: venue => dispatch(setVenue({form: 'eventEdition', venue})),
    setFormValue: (name, value) =>
      dispatch(setFormValue({form: 'eventEdition', name, value})),
    refreshGig: gig => dispatch(refreshGig({form: 'eventEdition', gig})),
    createLocalArtist: (mutate, artist) =>
      dispatch(
        createLocalArtist({form: 'localArtistCreation', mutate, artist})
      ),
    setArtistCreationToolFormValue: (name, value) =>
      dispatch(setArtistCreationToolFormValue({name, value})),
    reloadCrops: (executeQuery, variables) =>
      dispatch(reloadCrops({form: 'eventEdition', executeQuery, variables})),
    markFormAsUploading: isUploading =>
      dispatch(markFormAsUploading({form: 'venueEdition', isUploading})),
    saveEventPhotoOrder: (mutate, imageIds) => dispatch(saveEventPhotoOrder({mutate, imageIds})),
    deleteImage: (mutate, imageId) => dispatch(deleteImage({mutate, imageId}))
  };
};

const EventCreationForm = ReduxContainer.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),
  router: service(),

  propTypes: {
    userId: T.string.isRequired
  },

  isUserTypeTenant: readOnly('accountManager.isUserTypeTenant'),

  userTypeEditor: computed('accountManager.isUserTypeArtist', function() {
    return this.get('accountManager.isUserTypeArtist')
      ? USER_TYPE_ARTIST
      : USER_TYPE_TENANT;
  }),

  didInsertElement() {
    const sponsorImagesWatchQuery = this.apollo.watchQueryOperation({
      query: eventImageQuery,
      variables: {
        eventId: this.id,
        imageType: 'event_sponsor',
      }
    });

    const eventPhotoWatchQuery = this.apollo.watchQueryOperation({
      query: eventImageQuery,
      variables: {
        eventId: this.id,
        imageType: 'event_photo',
      }
    });

    const sponsorImagesSubscription = sponsorImagesWatchQuery.subscribe({
      next: result => {
        this.set('sponsorImages', result.data.viewer.event.images);
      }
    });

    const eventPhotoSubscription = eventPhotoWatchQuery.subscribe({
      next: result => {
        this.set('eventPhotos', result.data.viewer.event.images);
      }
    });

    this.set('sponsorImagesSubscription', sponsorImagesSubscription);
    this.set('eventPhotoSubscription', eventPhotoSubscription);
  },

  willDestroyElement() {
    this.get('actions.resetForm')();
    this.get('actions.resetImageSelection')();

    this.sponsorImagesSubscription.unsubscribe();
    this.set('sponsorImagesSubscription', null);
    this.eventPhotoSubscription.unsubscribe();
    this.set('eventPhotoSubscription', null);
  },

  actions: {
    setVenue(venue) {
      this.get('actions.setVenue')(venue);
    },

    setGigStartedAt(gig, startedAtString) {
      const [hours, minutes] = startedAtString.split(':');

      const startedAt = moment.tz(this.startedAt, this.venue.timezone)
        .hours(hours)
        .minutes(minutes)
        .startOf('minute');

      if (startedAt.hour() < config.TimeConfiguration.NEW_DAY_CUTOFF_HOUR) {
        startedAt.add(1, 'day');
      }

      this.get('actions.updateGig')({
        ...gig,
        startedAt: startedAt.toISOString()
      });
    },

    setGigArtist(gig, index, artist) {
      this.get('actions.updateGigAtIndex')({...gig, artist}, index);
    },

    save() {
      const properties = this.getProperties(
        'id',
        'startedAt',
        'name',
        'description',
        'ticketPriceCents',
        'doorPriceCents',
        'ticketPurchaseUrl'
      );

      const gigs = this.gigs
        .filter(({artist}) => !!artist.id)
        .map(gig => ({
          startedAt: gig.startedAt,
          artistId: gig.artist.id
        }));

      const variables = {
        ...properties,
        gigs,
        squareCropId: this.get('squareCrop.id'),
        venueId: this.get('venue.id'),
        wideCropId: this.get('wideCrop.id')
      };

      this.get('actions.saveEvent')(
        this.apollo.mutationOperation(),
        variables
      ).then(result => {
        if (!result) return;

        result.event.gigs.forEach(gig => this.get('actions.refreshGig')(gig));
      });
    },

    assignLocalArtist(gig, index, {name}) {
      if (this.isPendingLocalArtist) return;

      const variables = {name};
      this.get('actions.createLocalArtist')(
        this.apollo.mutationOperation(),
        variables
      ).then(() => {
        const artist = this.localArtist;
        if (!artist) return;

        this.get('actions.updateGigAtIndex')({...gig, artist}, index);
      });
    },

    finishCrop(crops) {
      this.get('actions.setFormValue')('wideCrop', crops.wide);
      this.get('actions.setFormValue')('squareCrop', crops.square);

      // Try reloading the crops so that maybe the image will be processed
      const variables = {
        wideCropId: crops.wide.id,
        squareCropId: crops.square.id
      };
      this.get('actions.reloadCrops')(this.apollo.queryOperation(), variables);
    },

    saveEventPhotoSortOrder() {
      console.log('saveEventPhotoOrder', ...arguments);

//       console.log(this.eventPhotos.map(photo => photo.id).join(','));
      this.get('actions.saveEventPhotoOrder')(
        this.apollo.mutationOperation(),
        this.eventPhotos.map(photo => photo.id),
      );
    },

    deleteEventImage(imageId) {
      return this.get('actions.deleteImage')(
        this.apollo.mutationOperation(),
        imageId
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(EventCreationForm);
