// Vendor
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    closeDropdown: T.func.isRequired
  },

  router: service(),
  tagName: 'li',
  classNames: ['gigs-web-search-bar__event-item'],

  eventVenueLocation: computed('event.venue.{name,city,province}', function() {
    return [this.event.venue.name, this.event.venue.city, this.event.venue.province]
      .filter(n => n)
      .join(', ');
  }),

  click() {
    this.closeDropdown();
    this.router.transitionTo('web.events.show', this.event.venue.slug, this.event.slug);
  }
});
