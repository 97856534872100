import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import {
  loadArtistImages,
  loadUserImages,
  selectImage,
  goToNextStep,
  createCrop,
  resetImageSelection,
  resetCrops
} from 'gigshq/actions/crop-tool';

const mapStateToComputed = state => {
  const {
    cropTool: {
      artistImageQuery,
      userImageQuery,
      createCropMutation,
      step,
      selectedImage,
      crops
    }
  } = state;

  return {
    artistImageQuery,
    userImageQuery,
    createCropMutation,
    step,
    selectedImage,
    crops
  };
};

const mapDispatchToActions = dispatch => {
  return {
    loadArtistImages: (executeQuery, artistId) =>
      dispatch(loadArtistImages(executeQuery, artistId)),
    loadUserImages: (executeQuery, page) => dispatch(loadUserImages(executeQuery, page)),
    selectImage: image => dispatch(selectImage(image)),
    createCrop: (mutate, crop) => dispatch(createCrop(mutate, crop)),
    goToNextStep: () => dispatch(goToNextStep()),
    resetImageSelection: () => dispatch(resetImageSelection()),
    resetCrops: () => dispatch(resetCrops())
  };
};

const CropTool = ReduxContainer.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),

  propTypes: {
    venueId: T.string,
    artists: T.array.isRequired,
    close: T.func.isRequired,
    finish: T.func.isRequired
  },

  userId: computed(function() {
    return this.get('accountManager.id');
  }),

  actions: {
    fetchArtistImagesWithId(artistId) {
      return this.get('actions.loadArtistImages')(
        this.apollo.queryOperation(),
        artistId
      );
    },

    fetchUserImages(page) {
      return this.get('actions.loadUserImages')(this.apollo.queryOperation(), page);
    },

    cancel() {
      this.get('actions.resetCrops')();
      this.close();
    },

    finishAndClose() {
      this.finish(this.crops);

      this.get('actions.resetCrops')();
      this.close();
    },

    persistCrop(crop) {
      return this.get('actions.createCrop')(
        this.apollo.mutationOperation(),
        crop
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(CropTool);
