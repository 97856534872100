import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { computed } from '@ember/object';
import { A } from '@ember/array';

export default Component.extend(Localizable(translations), {
  sortableImages: computed('images', function() {
    return A(this.images);
  })
});
