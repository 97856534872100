import { readOnly } from '@ember/object/computed';
import LinkComponent from '@ember/routing/link-component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default LinkComponent.extend(Localizable(translations), {
  propTypes: {
    network: T.string.isRequired,
    state: T.shape({
      pending: T.bool.isRequired,
      disabled: T.bool.isRequired,
      published: T.bool.isRequired
    }).isRequired
  },

  classNameBindings: ['active', 'isDisabled'],

  isDisabled: readOnly('state.disabled'),

  iconPath: computed('active', function() {
    const state = this.active ? 'on' : 'off';

    return `icn-network-${this.network}-${state}`;
  })
});
