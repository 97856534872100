export default [
  {
    value: 'drink',
    iconPrefix: 'fas',
    icon: 'glass-martini-alt',
  },
  {
    value: 'food',
    iconPrefix: 'fas',
    icon: 'utensils',
  },
  {
    value: 'admission',
    iconPrefix: 'fas',
    icon: 'ticket-alt',
  },
/*
  {
    value: 'discount',
    iconPrefix: 'fas',
    icon: 'money-bill-alt',
  }
*/
];
