import { inject as service } from '@ember/service';
import { not, and } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { USER_TYPE_TENANT } from 'gigshq/constants/user-types';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  i18n: service(),
  apollo: service(),
  fastboot: service(),
  flashMessages: service('flash-messages'),

  propTypes: {
    userTypeEditor: T.string,
    venues: T.array,
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    cancelledAt: T.nullable(T.string),
    deletedAt: T.nullable(T.string),
    startedAt: T.nullable(T.instanceOf(moment)).isRequired,
    published: T.bool,
    publishedAt: T.string,
    eventId: T.nullable(T.string),
    sponsorImages: T.array,
    eventPhotos: T.array,
    showSponsors: T.bool.isRequired,
    isUserTypeTenant: T.bool,
    name: T.string.isRequired,
    description: T.nullable(T.string).isRequired,
    slug: T.nullable(T.string),
    gigs: T.array.isRequired,
    ticketPriceCents: T.nullable(T.number).isRequired,
    doorPriceCents: T.nullable(T.number).isRequired,
    ticketPurchaseUrl: T.string.isRequired,
    venue: T.nullable(T.object),
    wideCrop: T.nullable(T.object).isRequired,
    setFormValue: T.func.isRequired,
    addGig: T.func.isRequired,
    removeGig: T.func.isRequired,
    setVenue: T.func.isRequired,
    setGigStartedAt: T.func.isRequired,
    setGigArtist: T.func.isRequired,
    resetCrops: T.func.isRequired,
    save: T.func.isRequired,
    finishCrop: T.func.isRequired,
    onLocalArtistCreate: T.func.isRequired,
    markFormAsUploading: T.func
  },

  getDefaultProps() {
    return {
      userTypeEditor: USER_TYPE_TENANT
    };
  },

  isReadyForPublication: not('isDirty'),
  isReadyForPreview: and('slug', 'venueSlug'),
  isPastEvent: computed('startedAt', function() {
    return this.startedAt.isBefore(moment().startOf('day'));
  }),

  event: computed('cancelledAt', 'deletedAt', 'published', function() {
    return this.getProperties('cancelledAt', 'deletedAt', 'published');
  }),

  showVenueSelector: false,
  eventDuplicationModalIsOpened: false,

  mascotState: computed('isReadyForPublication', 'errors', function() {
    if (this.isReadyForPublication)
      return AssetsCatalog.GigiStates.THUMBS_UP.key;
    if (this.errors) return AssetsCatalog.GigiStates.PERPLEX.key;
    return AssetsCatalog.GigiStates.NEUTRAL.key;
  }),

  viewButtonText: computed('publishedAt', function() {
    if (this.publishedAt)
      return this.localTranslation('action_box.actions.view');
    return this.localTranslation('action_box.actions.preview');
  }),

  eventForDuplication: computed('publishedAt', function() {
    return {
      id: this.eventId,
      publishedAt: this.publishedAt
    };
  }),

  actionBoxText: computed(
    'isReadyForPublication',
    'errors',
    'userTypeEditor',
    function() {
      if (this.isReadyForPublication) {
        const userTypeEditor = this.userTypeEditor;
        return this.localTranslation(`action_box.saved_text_${userTypeEditor}`);
      }

      if (this.errors) {
        return this.localTranslation('action_box.error_text');
      }

      return this.localTranslation('action_box.editing_text');
    }
  ),

  actions: {
    displayEventDuplicationModal() {
      this.set('eventDuplicationModalIsOpened', true);
    },

    hideEventDuplicationModal() {
      this.set('eventDuplicationModalIsOpened', false);
    },

    handleEventDuplicationSuccess() {
      this.flashMessages.success(this.localTranslation('duplication.success'));
      this.set('eventDuplicationModalIsOpened', false);
    },

    handleEventDuplicationFailure(error) {
      this.flashMessages.error(
        this.localTranslation(`duplication.errors.${error}`)
      );
    }
  }
});
