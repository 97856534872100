import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isPending: T.bool.isRequired,
    isSuccess: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    onSubmit: T.func.isRequired
  },

  shouldDisplaySuccessMessage: readOnly('isSuccess'),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  actions: {
    updateEmail(email) {
      this.set('email', email);
    },

    submit() {
      this.onSubmit(this.email).then(() => this.set('email', ''));
    }
  }
});
