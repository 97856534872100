import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  eventsFetcher: service('fetchers/events-fetcher'),
  localeManager: service('managers/locale-manager'),

  alternateLocale: computed('i18n.locale', function() {
    return this.i18n.locale === 'en' ? 'fr' : 'en';
  }),

  customBgColor: computed.readOnly('model.customBgColor'),
  customTextColor: computed.readOnly('model.customTextColor'),

  events: computed('eventsFetcher.fetchedEvents.[]', function() {
    return this.eventsFetcher.fetchedEvents;
  }),

  actions: {
    changeLocale(locale, skipReload = false) {
      this.localeManager.setLocale(locale, { persist: true });

      if (!skipReload)
        window.location.reload();
    }
  }

});
