import { PropTypes as T } from 'ember-prop-types';
import {
  registerType,
  registerValidator
} from 'gigshq/custom-prop-types/loose-shape';
import validators from 'ember-prop-types/utils/validators';

export const initialize = () => {
  validators.looseShape = registerValidator();

  T.looseShape = registerType();
};

export default {
  name: 'loose-shape-prop-type',
  initialize
};
