import { get } from '@ember/object';
import { typeOf } from '@ember/utils';
import { getDef, generateType } from 'ember-prop-types/utils/prop-types';
import logger from 'ember-prop-types/utils/logger';
import validators from 'ember-prop-types/utils/validators';

export const registerType = () => {
  return (typeDefs, options) => {
    const type = generateType('looseShape');

    if (typeOf(typeDefs) === 'object') {
      Object.keys(typeDefs).forEach(key => {
        typeDefs[key] = getDef(typeDefs[key]);
      });
    }

    type.typeDefs = typeDefs;

    if (typeOf(options) !== 'object') {
      type.isRequired.typeDefs = type.typeDefs;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };
};

export const registerValidator = () => {
  // eslint-disable-next-line complexity
  return (ctx, name, value, def, logErrors, throwErrors) => {
    const typeDefs = def.typeDefs;

    if (typeOf(typeDefs) !== 'object') {
      logger.warn(
        ctx,
        'PropTypes.shape() requires a plain object to be be passed in as an argument',
        throwErrors
      );
      return false;
    }

    if (typeOf(value) !== 'object') {
      logger.warn(
        ctx,
        `Property ${name} does not match the given shape`,
        throwErrors
      );
      return false;
    }

    let valid = Object.keys(typeDefs).every(key => {
      const typeDef = typeDefs[key];

      const objectValue = get(value, key);

      if (objectValue === undefined) {
        if (!typeDef.required) {
          return true;
        } else {
          if (logErrors) {
            logger.warn(
              ctx,
              `Property ${name} is missing a property "${key}" to satisfy its shape`,
              throwErrors
            );
          }

          return false;
        }
      }

      return validators[typeDef.type](
        ctx,
        `${name}.${key}`,
        objectValue,
        typeDef,
        logErrors,
        throwErrors
      );
    });

    valid =
      valid &&
      Object.keys(typeDefs).every(key => {
        const keyIsPresent = key in value;

        if (!keyIsPresent && logErrors) {
          logger.warn(ctx, `Property ${name} is missing: ${key}`, throwErrors);
        }

        return keyIsPresent;
      });

    if (!valid && logErrors) {
      logger.warn(
        ctx,
        `Property ${name} does not match the given shape`,
        throwErrors
      );
    }

    return valid;
  };
};
