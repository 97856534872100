export default {
  OpenGraph: {
    PRICING: {
      en: '/assets/images/img-og-about.png',
      fr: '/assets/images/img-og-about-fr.png'
    },
    ABOUT: {
      en: '/assets/images/img-og-about.png',
      fr: '/assets/images/img-og-about-fr.png'
    },
    MAP: {
      en: '/assets/images/img-og-map.png',
      fr: '/assets/images/img-og-map.png'
    }
  },

  GigiStates: {
    NEUTRAL: {
      key: 'NEUTRAL',
      asset: '/assets/images/img-gigi-small-neutral.png'
    },
    PERPLEX: {
      key: 'PERPLEX',
      asset: '/assets/images/img-gigi-small-perplex.png'
    },
    THUMBS_UP: {
      key: 'THUMBS_UP',
      asset: '/assets/images/img-gigi-small-thumbs-up.png'
    }
  },

  LargeGigiStates: {
    NEUTRAL: {
      key: 'NEUTRAL',
      asset: '/assets/images/img-gigi-large-neutral.png'
    },
    PERPLEX: {
      key: 'PERPLEX',
      asset: '/assets/images/img-gigi-large-perplex.png'
    },
    THUMBS_UP: {
      key: 'THUMBS_UP',
      asset: '/assets/images/img-gigi-large-thumbs-up.png'
    },
    POSTER: {
      key: 'POSTER',
      asset: '/assets/images/img-gigi-large-poster.png'
    }
  },

  EventCover: {
    WIDE_PLACEHOLDER: '/assets/images/img-event-cover-wide-placeholder.svg'
  },

  TextAlignment: {
    LEFT: {
      key: 'LEFT',
      asset: '/assets/images/icn-text-align-left.svg'
    },
    CENTER: {
      key: 'CENTER',
      asset: '/assets/images/icn-text-align-center.svg'
    },
    RIGHT: {
      key: 'RIGHT',
      asset: '/assets/images/icn-text-align-right.svg'
    }
  },

  WebsiteFeatures: {
    WEB_PAGE: {
      asset: '/assets/images/icn-feature-webpage.png'
    },
    EDITOR: {
      asset: '/assets/images/icn-feature-editor.png'
    },
    SHARING: {
      asset: '/assets/images/icn-feature-sharing.png'
    },
    REMINDERS: {
      asset: '/assets/images/icn-feature-reminders.png'
    },
    POSTER: {
      asset: '/assets/images/icn-feature-poster.png'
    }
  }
};
