import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import config from 'gigshq/config/environment';

export default Route.extend({
  fastboot: service(),
  router: service(),
  metrics: service(),
  localeManager: service('managers/locale-manager'),

  queryParams: {
    // eslint-disable-next-line camelcase
    fb_locale: { refreshModel: true },
    locale: { refreshModel: true }
  },

  beforeModel() {
    this.metrics.activateAdapters(config.metricsAdapters);
  },

  // eslint-disable-next-line complexity
  model(params) {
    let changeLocale = null;
    if (params.fb_locale)
      changeLocale = params.fb_locale.replace(/(_ca|_us)/i, '');
    else if (params.locale)
      changeLocale = params.locale.replace(/(_ca|_us)/i, '');
    else if (this.fastboot.isFastBoot)
      changeLocale = this.fastboot.request.headers.get('X-Facebook-Locale');

    if (changeLocale != null && changeLocale !== '')
      this.controllerFor('web').send('changeLocale', changeLocale, true);
  },

  init() {
    this._super(...arguments);

    this.router.on('routeDidChange', () => {
      if (this.get('fastboot.isFastBoot')) return;

      const page = this.router.currentURL;
      this.metrics.trackPage({ page });

      $('body').scrollTop(0);
    });
  },

  actions: {
    willTransition() {
      this.controllerFor('web').setProperties({
        artistDropdownMenuIsOpen: false,
        tenantDropdownMenuIsOpen: false,
        memberDropdownMenuIsOpen: false,
        mobileMenuIsOpen: false
      });
    }
  }
});
