import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import AssetsCatalog from 'gigshq/constants/assets-catalog';

export default Component.extend({
  classNameBindings: ['isExiting', 'type'],

  isExiting: readOnly('flash.exiting'),
  type: readOnly('flash.type'),

  mascotImagePath: computed('type', function() {
    if (this.type === 'success')
      return AssetsCatalog.GigiStates.THUMBS_UP.asset;
    return AssetsCatalog.GigiStates.PERPLEX.asset;
  }),

  actions: {
    close() {
      const flash = this.flash;

      if (flash) flash.destroyMessage();
    }
  }
});
