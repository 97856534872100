import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { task } from 'ember-concurrency';

// Queries
import deleteVenueMutation from 'gigshq/graphql/mutations/delete-venue';

export default Controller.extend(Localizable(translations), {
  apollo: service(),

  deleteVenueTask: task(function*(venueId) {
    const mutation = deleteVenueMutation;
    const variables = {venueId};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'deleteVenue');

      this.send('refreshModel');
      this.flashMessages.success(this.localTranslation('delete.success'));
    } catch (errors) {
      const message = this.localTranslation(
        `delete.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
  }).drop(),

  handleDelete(venueId) {
    // eslint-disable-next-line no-alert
    if (window.confirm(this.localTranslation('delete.confirm'))) {
      this.deleteVenueTask.perform(venueId);
    }
  }
});
