import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    multiple: T.bool,
    didStartUpload: T.func.isRequired
  },

  getDefaultProps() {
    return {
      multiple: false
    };
  },

  actions: {
    filesDidChange(files) {
      this.didStartUpload(Array.from(files));

      this.$('.gigs-image-uploader__uploader').prop('value', null);
    }
  }
});
