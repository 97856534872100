// Vendor
import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default Service.extend({
  fastboot: service(),

  read(key) {
    if (this.fastboot.isFastBoot) return null;

    const value = window.sessionStorage.getItem(key);

    if (!value) return null;

    return JSON.parse(value);
  },

  write(key, value) {
    if (this.fastboot.isFastBoot) return;

    window.sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove(key) {
    if (this.fastboot.isFastBoot) return;

    window.sessionStorage.removeItem(key);
  }
});
