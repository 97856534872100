import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { Ability } from 'ember-can';

export default Ability.extend({
  accountManager: service('managers/account-manager'),

  canCreate: readOnly('accountManager.isUserTypeArtist'),
  canEdit: readOnly('accountManager.isUserTypeArtist'),

  canRemoveFromGig: computed('model.id', 'currentArtist.id', function() {
    const artistId = this.get('model.id');
    const currentArtistId = this.get('currentArtist.id');

    return !artistId || artistId !== currentArtistId;
  })
});
