import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import config from 'gigshq/config/environment';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

import query from 'gigshq/graphql/queries/promo-code';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isSigningUp: T.bool.isRequired,
    membershipPlans: T.array.isRequired,
  },

  apollo: service(),

  isValidatingPromoCode: false,
  isPromoCodeValid: false,
  isPromoCodeInvalid: false,

  validatePromoCodeTask: task(function*(promoCode) {
    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);
    this.set('isPromoCodeValid', false);
    this.set('isPromoCodeInvalid', false);

    if (promoCode.length === 0) {
      this.membershipPlans.forEach(plan => Ember.set(plan, 'visible', plan.visibleByDefault));
      return;
    }

    this.set('isValidatingPromoCode', true);
    const response = (yield this.apollo.queryOperation()({query, variables: { promoCode }}, 'viewer')).validatePromoCode;

    if (response.promoCode != null || response.tapValid) {
      let preselectProductId = response.stripeProductId;

      this.get('membershipPlans').forEach(plan => {
        Ember.set(plan, 'visible', plan.visibleByDefault
          || plan.stripeProductId === response.stripeProductId
          || (plan.key === 'affiliate' && response.tapValid));

        if (response.tapValid && plan.key === 'affiliate')
          preselectProductId = plan.stripeProductId;
      });

      if (preselectProductId) {
        if (this.setMemberField)
          this.setMemberField('stripeProductId', preselectProductId);
        else
          this.set('stripeProductId', preselectProductId);
      }

      this.set('isPromoCodeValid', true);
    } else {
      this.membershipPlans.forEach(plan => Ember.set(plan, 'visible', plan.visibleByDefault));
      this.set('isPromoCodeInvalid', true);
    }

    this.set('isValidatingPromoCode', false);
  }).restartable(),

  actions: {
    validatePromoCode(value) {
      if (this.setMemberField)
        this.setMemberField('promoCode', value.toUpperCase());
      else
        this.set('promoCode', value.toUpperCase());

      this.validatePromoCodeTask.perform(value);
    },
  }
});
