import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  currentYear: computed(() =>
    moment()
      .startOf('day')
      .toDate()
      .getFullYear()
  )
});
