import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import moment from 'moment';
import { computed } from '@ember/object';
import config from 'gigshq/config/environment';

// Actions
import { setSession } from 'gigshq/actions/session';

export default Route.extend({
  fastboot: service(),
  headData: service('head-data'),
  localeManager: service('managers/locale-manager'),
  moment: service(),
  redux: service(),
  // service('service-worker'),
  sessionFetcher: service('fetchers/session-fetcher'),
  standaloneAppManager: service('managers/standalone-app-manager'),
  userLocaleFetcher: service('fetchers/user-locale-fetcher'),
  metrics: service(),

  router: computed(function() {
    return {
      setTitle: title => {
        this.headData.set('title', title);
      }
    };
  }),

  beforeModel(transition) {
    const isFastBoot = this.get('fastboot.isFastBoot');

    if (isFastBoot) this.applyFastBootBeforeFilters(transition);

    const session = this.sessionFetcher.fetch();

    if (session) {
      this.redux.dispatch(setSession(session));
    }

    const locale = this.userLocaleFetcher.fetchLocale();
    this.localeManager.setLocale(locale);

    this.metrics.activateAdapters(config.metricsAdapters);

    return this.standaloneAppManager.restoreLastURL();
  },

  title(tokens) {
    tokens = Array.from(tokens);
    tokens.unshift('GigsHQ');
    return tokens.reverse().join(' | ');
  },

  applyFastBootBeforeFilters(_transition) {
    this.setupTimeZone();
  },

  setupTimeZone() {
    // TODO: change this when we support venues timezone
    // This is to make sure server-side rendering outputs the
    // same date/time as the client-side rendering
    moment.tz.setDefault('America/Montreal');
    this.moment.setTimeZone('America/Montreal');
  }
});
