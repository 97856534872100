import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import config from 'gigshq/config/environment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venues: T.array,
    venue: T.object,
    readOnly: T.bool,
    onSelectVenue: T.func.isRequired
  },

  accountManager: service('managers/account-manager'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  searchVenues: task(function*(value) {
    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);

    if (this.accountManager.isUserTypeTenant) {
      const response = yield this.venuesFetcher.searchManagedVenues(value);
      return response.venues;
    } else {
      return yield this.venuesFetcher.searchVenues(value);
    }
  }).restartable(),

  actions: {
    handleVenueSelection(venue) {
      this.onSelectVenue(venue);
    }
  },
});
