import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

// Actions
import { fillForm } from 'gigshq/actions/artists';

// Queries
import query from 'gigshq/graphql/queries/artist-edition';

export default Route.extend(ConfirmationMixin, {
  apollo: service(),
  can: service(),
  redux: service(),

  model({id}) {
    return this.apollo.queryOperation()({query, variables: {id}}, 'viewer');
  },

  redirect(model) {
    if (this.can.cannot('edit artist', model.artist, {}))
      return this.transitionTo('app.events.index');
  },

  afterModel(model) {
    const {
      id,
      name,
      genres,
      contactEmail,
      socialProfile,
      profileImage
    } = model.artist;

    const availableGenres = model.genres;
    const description = model.artist.description || '';

    const values = {
      id,
      name,
      genres,
      availableGenres,
      description,
      contactEmail,
      socialProfile,
      profileImage
    };

    this.redux.dispatch(fillForm({form: 'artistEdition', values}));
  },

  isPageDirty() {
    const {
      artists: {
        artistEdition: {isDirty, isUploading}
      }
    } = this.redux.getState();
    return isDirty || isUploading;
  }
});
