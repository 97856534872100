import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Controller.extend(Localizable(translations), {
  flashMessages: service('flash-messages'),
  eventPublisher: service('publishers/event-publisher'),

  actions: {
    changeTab(tab) {
      this.transitionToRoute(
        `app.events.publish.${tab}`,
        this.get('model.event.id')
      );
    },

    publish() {
      const variables = {
        network: PublishingNetworks.GHQ.name,
        eventId: this.get('model.event.id')
      };

      return this.eventPublisher.publish(variables).then(({errors}) => {
        if (!errors) {
          this.send('refreshModel');
          return;
        }

        const message = this.localTranslation(
          `errors.${errors[0].messages[0]}`
        );
        this.flashMessages.error(message);

        return errors;
      });
    }
  }
});
