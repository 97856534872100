import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import userUpdateMutation from 'gigshq/graphql/mutations/user-update';
import membershipUpdateMutation from 'gigshq/graphql/mutations/membership-update';

export const saveUserPending = createAction('USERS_SAVE_USER_PENDING');
export const saveUserSuccess = createAction('USERS_SAVE_USER_SUCCESS');
export const saveUserFailure = createAction('USERS_SAVE_USER_FAILURE');
export const saveMembershipFieldsPending = createAction('USERS_SAVE_MEMBERSHIP_FIELDS_PENDING');
export const saveMembershipFieldsSuccess = createAction('USERS_SAVE_MEMBERSHIP_FIELDS_SUCCESS');
export const saveMembershipFieldsFailure = createAction('USERS_SAVE_MEMBERSHIP_FIELDS_FAILURE');

export const saveUser = ({mutate, form, user}) => async dispatch => {
  dispatch(saveUserPending({form}));

  try {
    const mutation = userUpdateMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, user);

    const result = await mutate({mutation, variables}, 'userUpdate');

    dispatch(saveUserSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(saveUserFailure({form, error}));
    return { user: null, errors: error };
  }
};

export const saveMembershipFields = ({mutate, form, user}) => async dispatch => {
  dispatch(saveMembershipFieldsPending({form}));

  try {
    const mutation = membershipUpdateMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, user);

    const result = await mutate({mutation, variables}, 'membershipUpdate');

    dispatch(saveMembershipFieldsSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(saveMembershipFieldsFailure({form, error}));
    return { user: null, errors: error };
  }
};

export const setFormValue = createAction('USERS_SET_FORM_VALUE');
export const setMemberField = createAction('USERS_SET_MEMBER_FIELD');
export const resetForm = createAction('USERS_RESET_FORM');
export const fillForm = createAction('USERS_FILL_FORM');
