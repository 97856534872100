// Vendor
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    artist: T.object.isRequired,
    closeDropdown: T.func.isRequired
  },

  router: service(),
  tagName: 'li',
  classNames: ['gigs-web-search-bar__artist-item'],

  click() {
    this.closeDropdown();
    this.router.transitionTo('web.artists.show', this.artist.slug);
  }
});
