import { notEmpty, equal } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { computed } from '@ember/object';
import PublishingNetworks from 'gigshq/constants/publishing-networks';
import FacebookPublisher from 'gigshq/utils/facebook-publisher';

const States = {
  LOGIN: 'login',
  ACCEPT_PERMISSIONS: 'accept-permissions',
  NO_ACCOUNTS: 'no-accounts',
  POST: 'post',
  COMPLETED: 'completed'
};

const REQUIRED_SCOPES = ['pages_show_list', 'pages_manage_posts'];

export default Component.extend({
  isLoggedIn: false,
  user: null,

  permissions: [],
  hasAcceptedPermissions: computed('permissions.@each.status', function() {
    return REQUIRED_SCOPES.every(scope => {
      const permission = this.permissions.find(
        // eslint-disable-next-line max-nested-callbacks
        ({permission}) => permission === scope
      );

      if (!permission) return false;

      return permission.status === 'granted';
    });
  }),

  accounts: [],
  hasAccounts: notEmpty('accounts'),

  isReadyToPost: equal('state', States.POST),

  stateComponent: computed('state', function() {
    return `gigs-publish-tool-facebook-gate/${this.state}`;
  }),

  /* eslint-disable complexity */
  state: computed(
    'isLoggedIn',
    'hasAcceptedPermissions',
    'hasAccounts',
    function() {
      const {isLoggedIn, hasAcceptedPermissions, hasAccounts} = this;

      if (!isLoggedIn) return States.LOGIN;
      if (!hasAcceptedPermissions) return States.ACCEPT_PERMISSIONS;
      if (!hasAccounts) return States.NO_ACCOUNTS;

      return States.POST;
    }
  ),
  /* eslint-enable max-complexity */

  init(...args) {
    this._super(...args);

    this.onAuthResponseChange = this.onAuthResponseChange.bind(this);
  },

  didInsertElement() {
    window.FB.Event.subscribe(
      'auth.authResponseChange',
      this.onAuthResponseChange
    );

    this.set('isLoading', true);

    FacebookPublisher.isLoggedIn()
      .then(() => this.didLogin())
      .finally(() => this.set('isLoading', false));
  },

  willDestroyElement() {
    window.FB.Event.unsubscribe(
      'auth.authResponseChange',
      this.onAuthResponseChange
    );
  },

  actions: {
    handleLogin() {
      const loginOptions = {scope: REQUIRED_SCOPES.join(',')};

      if (this.hasAcceptedPermissions) {
        // eslint-disable-next-line camelcase
        loginOptions.auth_type = 'rerequest';
      }

      return FacebookPublisher.login(loginOptions);
    },

    handleRefreshAccounts() {
      return FacebookPublisher.fetchAccounts().then(accounts =>
        this.set('accounts', accounts)
      );
    }
  },

  onAuthResponseChange({status}) {
    this.set('isLoggedIn', status === 'connected');

    if (this.isLoggedIn) {
      this.didLogin();
    } else {
      this.didLogout();
    }
  },

  didLogin() {
    return RSVP.hash({
      user: FacebookPublisher.fetchUser(),
      permissions: FacebookPublisher.fetchPermissions(),
      accounts: FacebookPublisher.fetchAccounts()
    }).then(result => this.setProperties(result));
  },

  didLogout() {
    this.setProperties({
      user: null,
      permissions: [],
      accounts: []
    });
  }
});
