import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import sendResetPasswordInstructionsMutation from 'gigshq/graphql/mutations/send-reset-password-instructions';
import resetPasswordMutation from 'gigshq/graphql/mutations/reset-password';

export const askInstructionsPending = createAction(
  'ASK_RESET_PASSWORD_INSTRUCTIONS_PENDING'
);
export const askInstructionsSuccess = createAction(
  'ASK_RESET_PASSWORD_INSTRUCTIONS_SUCCESS'
);
export const askInstructionsFailure = createAction(
  'ASK_RESET_PASSWORD_INSTRUCTIONS_FAILURE'
);

export const askInstructions = ({mutate, email}) => async dispatch => {
  dispatch(askInstructionsPending());

  try {
    const mutation = sendResetPasswordInstructionsMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, {email});

    const result = await mutate(
      {mutation, variables},
      'sendResetPasswordInstructions'
    );

    dispatch(askInstructionsSuccess());

    return result;
  } catch (errors) {
    dispatch(askInstructionsFailure(errors));
  }
};

export const resetPasswordPending = createAction('RESET_PASSWORD_PENDING');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');

export const resetPassword = ({
  mutate,
  resetPasswordToken,
  password,
  passwordConfirmation
}) => async dispatch => {
  dispatch(resetPasswordPending());

  try {
    const mutation = resetPasswordMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, {
      resetPasswordToken,
      password,
      passwordConfirmation
    });

    const result = await mutate({mutation, variables}, 'resetPassword');

    dispatch(resetPasswordSuccess());

    return result;
  } catch (errors) {
    dispatch(resetPasswordFailure(errors));
  }
};
