import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { task } from 'ember-concurrency';

// Queries
import transferVenueOwnership from 'gigshq/graphql/mutations/transfer-venue-ownership';

export default Component.extend(Localizable(translations), {
  apollo: service(),
  router: service(),

  propTypes: {
    venueId: T.string.isRequired
  },

  newOwnerEmail: '',
  isPending: false,

  transferVenueOwnership: task(function*(venueId, newOwnerEmail) {
    const mutation = transferVenueOwnership;
    const variables = {venueId, newOwnerEmail};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'transferVenueOwnership');

      this.flashMessages.success(this.localTranslation('transfer.success'));
      this.set('newOwnerEmail', '');
      this.router.transitionTo('app.venues');
    } catch (errors) {
      const message = this.localTranslation(
        `transfer.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
    this.set('isPending', false);
  }).drop(),

  actions: {
    updateEmail(email) {
      this.set('newOwnerEmail', email);
    },

    transfer() {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.localTranslation('transfer.confirm'))) {
        this.set('isPending', true);
        this.transferVenueOwnership.perform(this.venueId, this.newOwnerEmail);
      }
    }
  }
});
