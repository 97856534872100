import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import moment from 'moment';

const HEX_VALIDATOR = new RegExp('^(?:[0-9a-fA-F]{3}){1,2}$');

export default Route.extend({
  eventsFetcher: service('fetchers/events-fetcher'),

  queryParams: {
    lang: { refreshModel: true }
  },

  model(params, transition) {
    if (params.lang)
      this.controllerFor('calendar').send('changeLocale', params.lang, true);

    let venue = transition.to.queryParams['venue'];
    let bgColor = transition.to.queryParams['bg'];
    let textColor = transition.to.queryParams['text'];

    let fromDate = moment().startOf('day').format('YYYY-MM-DD');


    let events = this.eventsFetcher.fetchEvents(
      venue ? [venue] : null,
      1,
      fromDate);

    let customBgColor = HEX_VALIDATOR.test(bgColor) ? `#${bgColor}` : null;
    let customTextColor = HEX_VALIDATOR.test(textColor) ? `#${textColor}` : null;

    return {
      venue,
      fromDate,
      events,
      customBgColor,
      customTextColor
    };
  }
});
