import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired
  },

  isPastEvent: computed('event.startedAt', function() {
    return moment(this.event.startedAt).isBefore(moment().startOf('day'));
  })
});
