define("gigshq/initializers/ember-drag-drop-polyfill", ["exports", "ember-drag-drop-polyfill/initializers/ember-drag-drop-polyfill"], function (_exports, _emberDragDropPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberDragDropPolyfill.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _emberDragDropPolyfill.initialize;
    }
  });
});
