import { inject as service } from '@ember/service';
import { readOnly, not } from '@ember/object/computed';
import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { ability } from 'ember-can/computed';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),

  eventAbility: ability('event'),

  canCreateEvent: readOnly('eventAbility.canCreate'),
  cannotCreateEvent: not('canCreateEvent'),
});
