export default {
  devPlans: [
    {
      stripeProductId: 'price_1MkrZaIVbTL5sg1VWVmNqKGZ',
      key: 'free',
      visibleByDefault: false,
    },
    {
      stripeProductId: 'price_1NFpiYIVbTL5sg1VAzCrnmI3',
      key: 'affiliate',
      visibleByDefault: false,
    },
    {
      stripeProductId: 'price_1NFpjnIVbTL5sg1VnBZOrYam',
      key: 'monthly',
      visible: true,
      visibleByDefault: true,
    },
    {
      stripeProductId: 'price_1MAgsYIVbTL5sg1VkzhqybkP',
      key: 'annual',
      visible: true,
      visibleByDefault: true,
    }
  ],
  prdPlans: [
    {
      stripeProductId: 'price_1NFprSIVbTL5sg1VInIrngkL',
      key: 'affiliate',
      visibleByDefault: false,
    },
    {
      stripeProductId: 'price_1NANsuIVbTL5sg1V1nv2XeEn',
      key: 'monthly',
      visible: true,
      visibleByDefault: true,
    },
    {
      stripeProductId: 'price_1MpB3qIVbTL5sg1VmA0kE4Zf',
      key: 'annual',
      visible: true,
      visibleByDefault: true,
    }
  ]
};
