import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  i18n: service(),

  isEnglish: equal('i18n.locale', 'en'),

  emailLink: computed(function() {
    return `mailto:${this.i18n.t('general.application.contact.email')}`;
  }),

  emailTitle: computed(function() {
    const email = this.i18n.t('general.application.contact.email');
    return this.localTranslation('email_link_title', { email });
  })
});
