import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

// Queries
import deleteFacebookPostMutation from 'gigshq/graphql/mutations/delete-facebook-post';

export default Controller.extend(Localizable(translations), {
  apollo: service(),

  deleteFacebookPostTask: task(function*(facebookPostId, facebookAccessToken) {
    const mutation = deleteFacebookPostMutation;
    const variables = {facebookPostId, facebookAccessToken};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'deleteFacebookPost');

      this.send('refreshModel');
      this.flashMessages.success(this.localTranslation('delete.success'));
    } catch (errors) {
      const message = this.localTranslation(
        `delete.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
  }).drop(),

  actions: {
    deleteFacebookPost({facebookPostId, facebookAccessToken}) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.localTranslation('delete.confirm'))) {
        this.deleteFacebookPostTask.perform(
          facebookPostId,
          facebookAccessToken
        );
      }
    }
  }
});
