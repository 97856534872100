import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  facebookCoverStyle: computed('coverImage', function() {
    return `background-image: url('${this.coverImage}');`;
  })
});
