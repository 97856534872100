import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import config from 'gigshq/config/environment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),

  classNameBindings: [ 'disposition' ],

  artistUserType: USER_TYPE_ARTIST,
  tenantUserType: USER_TYPE_TENANT,
  memberUserType: USER_TYPE_MEMBER,

  isDevEnvironment: config.APP.ENVIRONMENT === 'dev',

  artistDropdownMenuIsOpen: false,
  tenantDropdownMenuIsOpen: false,
  memberDropdownMenuIsOpen: false,
  aboutMenuIsOpen: false,
  signupMenuIsOpen: false,
  mobileMenuIsOpen: false,
});
