import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/admin-venues';

export default Route.extend({
  apollo: service(),

  /* eslint-disable camelcase */
  model({user_id}) {
    const variables = {userId: user_id};
    /* eslint-enable camelcase */
    const watchQuery = this.apollo.watchQueryOperation({query, variables});

    const adminVenuesSubscription = watchQuery.subscribe({
      next: result => {
        if (result.stale) {
          watchQuery.refetch();
        } else {
          const {user} = result.data.viewer;
          const {venues} = user;
          this.controllerFor('admin.tenants.venues').set('model', {
            venues,
            user
          });
        }
      }
    });

    this.set('adminVenuesSubscription', adminVenuesSubscription);
  },

  deactivate() {
    if (!this.adminVenuesSubscription) return;

    this.adminVenuesSubscription.unsubscribe();
    this.set('adminVenuesSubscription', null);
  }
});
