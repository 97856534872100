import { handleActions } from 'redux-actions';
import {
  duplicateEventPending,
  duplicateEventSuccess,
  duplicateEventFailure,
  setFormValue,
  resetForm
} from 'gigshq/actions/duplicate-event';

const INITIAL_STATE = {
  form: {
    dates: [],
    autoPublishEvent: false
  },
  isDirty: false,
  isPending: false,
  data: null,
  errors: null
};

export default handleActions(
  {
    [duplicateEventPending]: state => ({
      ...state,
      isPending: true,
      data: null,
      errors: null
    }),

    [duplicateEventSuccess]: (state, {payload}) => ({
      ...state,
      isDirty: false,
      isPending: false,
      data: payload.result.events,
      errors: null
    }),

    [duplicateEventFailure]: (state, {payload}) => ({
      ...state,
      isPending: false,
      data: null,
      errors: payload.error
    }),

    [resetForm]: () => ({
      ...INITIAL_STATE
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      isDirty: true,
      form: {
        ...state.form,
        [payload.name]: payload.value
      }
    })
  },
  INITIAL_STATE
);
