import Route from '@ember/routing/route';

export default Route.extend({
  queryParams: {
    fromDate: {
      refreshModel: true,
      replace: true
    },
    toDate: {
      refreshModel: true,
      replace: true
    },
    page: {
      refreshModel: true
    },
    selectedVenueId: {
      refreshModel: true
    },
    city: {
      refreshModel: true
    }
  },
});
