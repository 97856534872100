import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import config from 'gigshq/config/environment';
import { htmlSafe } from '@ember/string';

// Queries
import onAppLoadQuery from 'gigshq/graphql/queries/on-app-load';

export default Route.extend({
  redux: service(),
  router: service(),
  apollo: service(),
  fastboot: service(),
  headData: service('head-data'),
  intercom: service(),
  localeManager: service('managers/locale-manager'),
  sessionFetcher: service('fetchers/session-fetcher'),
  accountManager: service('managers/account-manager'),

  beforeModel() {
    const {session} = this.redux.getState();

    if (!session.data)
      this.router.transitionTo('lobby.login');

    const userLocale = this.sessionFetcher.fetch().locale;
    this.localeManager.setLocale(userLocale, {persist: true});

    if (this.sessionFetcher.fetch().isAdmin)
      this.router.transitionTo('admin');
  },

  model() {
    return this.apollo.queryOperation()({
      query: onAppLoadQuery
    });
  },

  afterModel({viewer}, transition) {
    this.intercom.set('user.name', `${viewer.firstName} ${viewer.lastName}`);
    this.intercom.set('user.email', viewer.email);

    this.accountManager.setAccount(viewer);

    if (config.Fullstory)
      this.headData.setProperties({fullstoryScript: this._fullstoryScript()});

    if (this.get('accountManager.isUncompletedTenantAccount'))
      this.router.transitionTo('app.venues.new');

    if (this.get('accountManager.isUncompletedArtistAccount'))
      this.router.transitionTo('app.artists.new');

    if (this.get('accountManager.isUserTypeMember')) {
      if (transition.from && transition.from.name === 'lobby.login')
        this.router.transitionTo('web.events.index.map');
      else
        this.router.transitionTo('app.users.edit');

    }
  },

  activate() {
    if (this.get('fastboot.isFastBoot')) return;

    $('html, body').css('overflow', 'hidden');
  },

  deactivate() {
    if (this.get('fastboot.isFastBoot')) return;

    $('html, body').css('overflow', 'auto');
  },

  _fullstoryScript() {
    return htmlSafe(`
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_org'] = '${config.Fullstory.organizationId}';
      window['_fs_namespace'] = 'FS';

      (function(m,n,e,t,l,o,g,y){
          if (e in m && m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); return;}
          g=m[e]=function(a,b){g.q?g.q.push([a,b]):g._api(a,b);};g.q=[];
          o=n.createElement(t);o.async=1;o.src='https://'+_fs_host+'/s/fs.js';
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v){g(l,{uid:i});if(v)g(l,v)};g.setUserVars=function(v){g(l,v)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(c,d,i){if(!c || document.cookie.match('fs_uid=[\`;\`]*\`[\`;\`]*\`[\`;\`]*\`')){
          d=n.domain;while(1){n.cookie='fs_uid=;domain='+d+
          ';path=/;expires='+new Date(0).toUTCString();i=d.indexOf('.');if(i<0)break;d=d.slice(i+1)}}};
      })(window,document,window['_fs_namespace'],'script','user');
    `);
  },

  actions: {
    refreshModel() {
      this.refresh();
    },

    didTransition() {
      this.controllerFor('app').setProperties({
        mobileMenuIsOpen: false
      });
    }
  }
});
