import $ from 'jquery';
import { run } from '@ember/runloop';

const SIXTY_FPS = 16.666666667;

export default class DraggableElement {
  constructor(opts) {
    this.$element = $(opts.elementSelector);
    this.x = 0;
    this.y = 0;
    this.isDragging = false;
    this.onDrag = opts.onDrag;

    this.$element
      .on('mousedown.gigs-draggable', event => {
        this.handlePointerDown(event.clientX, event.clientY);
      })
      .on('touchstart.gigs-draggable', event => {
        this.handlePointerDown(
          event.touches[0].clientX,
          event.touches[0].clientY
        );
      });

    $(document)
      .on('mousemove.gigs-draggable', event => {
        if (this.isDragging) {
          event.preventDefault();
          run.throttle(
            this,
            'handlePointerMove',
            event.clientX,
            event.clientY,
            SIXTY_FPS
          );
        }
      })
      .on('touchmove.gigs-draggable', event => {
        if (this.isDragging) {
          event.preventDefault();
          run.throttle(
            this,
            'handlePointerMove',
            event.touches[0].clientX,
            event.touches[0].clientY,
            SIXTY_FPS
          );
        }
      })
      .on('mouseup.gigs-draggable', () => this.handlePointerUp())
      .on('touchend.gigs-draggable', () => this.handlePointerUp());
  }

  destroy() {
    this.$element.off('mousedown.gigs-draggable');

    $(document)
      .off('mousemove.gigs-draggable')
      .off('mouseup.gigs-draggable');
  }

  handlePointerDown(x, y) {
    this.x = x;
    this.y = y;
    this.isDragging = true;
  }

  handlePointerMove(x, y) {
    const deltaX = this.x - x;
    const deltaY = this.y - y;

    this.onDrag(deltaX, deltaY);

    this.x = x;
    this.y = y;
  }

  handlePointerUp() {
    this.isDragging = false;
  }
}
