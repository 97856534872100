import { inject as service } from '@ember/service';
import $ from 'jquery';
import { computed } from '@ember/object';
import RSVP from 'rsvp';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import S3Uploader from 'ember-uploader/uploaders/s3';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import ImageSizes from 'gigshq/constants/image-sizes';
import ImageTypes from 'gigshq/constants/image-types';
import configValue from 'gigshq/computed-macros/config-value';

// Actions
import {
  resetTextfields,
  addTextfield,
  updateTextfield,
  removeTextfield,
  createCaptionedImage
} from 'gigshq/actions/caption-tool';

const mapStateToComputed = state => {
  const {
    captionTool: { textfields }
  } = state;

  return {
    textfields
  };
};

const mapDispatchToActions = dispatch => {
  return {
    resetTextfields: () => dispatch(resetTextfields()),
    addTextfield: () => dispatch(addTextfield()),
    updateTextfield: textfield => dispatch(updateTextfield(textfield)),
    removeTextfield: textfield => dispatch(removeTextfield(textfield)),
    createCaptionedImage: (
      mutation,
      imageUrl,
      size,
      imageType,
      eventId,
      socialNetwork
    ) =>
      dispatch(
        createCaptionedImage(
          mutation,
          imageUrl,
          size,
          imageType,
          eventId,
          socialNetwork
        )
      )
  };
};

const CaptionTool = ReduxContainer.extend(Localizable(translations), {
  propTypes: {
    eventId: T.string.isRequired,
    image: T.string.isRequired,
    network: T.string.isRequired,
    captionedImageWasCreated: T.func.isRequired,
    close: T.func.isRequired
  },

  apollo: service(),
  flashMessages: service('flash-messages'),

  signingUrl: configValue('API.IMAGE_SIGNING_URL'),

  imageSize: computed('network', function() {
    return ImageSizes[this.network].imageSize;
  }),

  actions: {
    persistImage({ image, size }) {
      return new RSVP.Promise(resolve => {
        const uploader = S3Uploader.create({
          signingUrl: this.signingUrl,
          signingMethod: 'POST'
        });

        uploader.upload(image);

        uploader.on('didError', () => {
          this.flashMessages.error(
            this.localTranslation('errors.could_not_upload')
          );
        });

        uploader.on('didUpload', event => {
          const imageUrl = $(event).find('Location')[0].textContent;

          const mutation = this.apollo.mutationOperation();
          const eventId = this.eventId;
          const imageType = ImageTypes.USER_PHOTO;
          const socialNetwork = this.network;

          this.get('actions.createCaptionedImage')(
            mutation,
            imageUrl,
            size,
            imageType,
            eventId,
            socialNetwork
          )
            .then(() => this.captionedImageWasCreated())
            .then(resolve);
        });
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(CaptionTool);
