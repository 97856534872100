import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { inject as service } from '@ember/service';

export default Component.extend(Localizable(translations), {
  classNameBindings: [ 'shouldDisplayLogo:with-logo' ],

  propTypes: {
    venue: T.looseShape({
      name: T.string.isRequired,
      city: T.string.isRequired,
      phoneNumber: T.nullable(T.string),
      email: T.nullable(T.string),
      description: T.string.isRequired,
      streetAddress: T.string.isRequired,
      images: T.array.isRequired,
      profileImage: T.nullable(T.object).isRequired,
      socialProfile: T.object.isRequired,
      formattedAddress: T.string.isRequired,
      openingHours: T.array.isRequired
    }),
    showVenueLink: T.bool
  },

  accountManager: service('managers/account-manager'),
  shouldDisplayLogo: notEmpty('venue.profileImage'),

  showMapUrl: computed('venue.formattedAddress', function() {
    const address = this.get('venue.formattedAddress');

    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  })
});
