import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';

export default Component.extend({
  classNameBindings: ['isSelected'],
  attributeBindings: ['style'],

  style: computed('imageUrl', 'width', 'height', function() {
    const {imageUrl, width, height} = this;

    return htmlSafe(
      [
        `width: ${width}px;`,
        `height: ${height}px;`,
        `background-image: url('${imageUrl}');`
      ].join('')
    );
  }),

  isSelected: computed('index', 'selectedIndex', function() {
    return this.index === this.selectedIndex;
  }),

  click() {
    this.click();
  }
});
