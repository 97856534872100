import { inject as service } from '@ember/service';
import { bool, not, or, notEmpty, readOnly } from '@ember/object/computed';
import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import EventMetaBuilder from 'gigshq/utils/event-meta-builder';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';
import FacebookPublisher from 'gigshq/utils/facebook-publisher';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    facebookAccounts: T.array.isRequired,
    facebookUser: T.object.isRequired,
    publish: T.func.isRequired,
    toggleCaptionToolIsOpened: T.func.isRequired
  },

  i18n: service(),
  router: service(),
  fastboot: service(),

  hasSuccessfullyPublished: false,

  characterCountLimit: 160,
  facebookMessage: '',
  isReadyForPublication: bool('event.shareableProfile.wide'),
  isNotReadyForPublication: not('isReadyForPublication'),

  selectedFacebookAccount: null,
  includeGHQLink: true,

  hasAlreadyPublishedToFacebook: or(
    'event.hasFacebookPagePosts',
    'event.hasFacebookEventPosts'
  ),

  facebookEvents: computed(() => []),
  selectedFacebookEvent: null,
  hasSelectedAFacebookEvent: notEmpty('selectedFacebookEvent'),

  shouldShowFacebookPreview: readOnly('hasSelectedAFacebookEvent'),
  shouldDisplayCustomMessageInput: readOnly('hasSelectedAFacebookEvent'),

  facebookCardTitle: computed('event', function() {
    const {i18n, event} = this;

    return EventMetaBuilder.title({i18n, event});
  }),

  facebookCardDescription: computed('event', 'venue', function() {
    const {i18n, event} = this;
    const venue = event.venue;

    return EventMetaBuilder.description({i18n, event, venue});
  }),

  facebookCoverImage: computed(
    'event.shareableProfile.{wide,facebook}',
    function() {
      return (
        this.get('event.shareableProfile.facebook') ||
        this.get('event.shareableProfile.wide')
      );
    }
  ),

  facebookCardURL: computed('fastboot.isFastBoot', function() {
    return this.get('fastboot.isFastBoot')
      ? this.get('fastboot.request.host')
      : window.location.host;
  }),

  facebookSharingUrl: computed('event', 'includeGHQLink', function() {
    if (!this.includeGHQLink) return null;

    const {fastboot, router, event} = this;

    const slug = event.slug;
    const venueSlug = event.venue.slug;

    const path = router.urlFor('web.events.show', {
      // eslint-disable-next-line camelcase
      venue_slug: venueSlug,
      slug
    });

    return PageMetaBuilder.url({fastboot, path});
  }),

  mascotImagePath: computed(
    'hasSuccessfullyPublished',
    'isReadyForPublication',
    function() {
      const {hasSuccessfullyPublished, isReadyForPublication} = this;

      if (hasSuccessfullyPublished)
        return AssetsCatalog.LargeGigiStates.THUMBS_UP.asset;
      if (!isReadyForPublication)
        return AssetsCatalog.LargeGigiStates.PERPLEX.asset;

      return AssetsCatalog.LargeGigiStates.NEUTRAL.asset;
    }
  ),

  publishButtonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.publishing')
      : this.localTranslation('buttons.publish');
  }),

  fetchFacebookEventsTask: task(function*() {
    this.set('facebookEvents', []);

    const page = this.get('selectedFacebookAccount.id');
    const date = this.get('event.startedAt');

    FacebookPublisher.fetchEventsForPageAndDate(page, date).then(
      facebookEvents => {
        this.setProperties({
          facebookEvents,
          selectedFacebookEvent: facebookEvents[0]
        });
      }
    );
  }).restartable(),

  didInsertElement() {
    this.set('selectedFacebookAccount', this.facebookAccounts[0]);

    this.fetchFacebookEventsTask.perform();
  },

  resetForm() {
    this.setProperties({
      facebookMessage: '',
      includeGHQLink: true
    });

    $('.gigs-tri-column-layout').scrollTop(0);
  },

  actions: {
    selectFacebookAccount(account) {
      this.set('selectedFacebookAccount', account);

      this.fetchFacebookEventsTask.perform();
    },

    selectFacebookEvent(event) {
      this.set('selectedFacebookEvent', event);
    },

    setFacebookMessage(message) {
      this.set('facebookMessage', message);
    },

    setIncludeGHQLink(value) {
      this.set('includeGHQLink', value);
    },

    handlePublish() {
      const publishingOptions = {
        facebookAccessToken: this.get('selectedFacebookAccount.accessToken'),
        facebookPageName: this.get('selectedFacebookAccount.name'),
        facebookPagePictureUrl: this.get('selectedFacebookAccount.pictureUrl'),
        message: this.facebookMessage,
        facebookEventId: this.get('selectedFacebookEvent.id'),
        link: this.facebookSharingUrl,
        publishDates: []
      };

      this.set('isPending', true);

      this.publish(publishingOptions)
        .then(error => {
          if (!error) {
            this.set('hasSuccessfullyPublished', true);
            this.resetForm();
          }
        })
        .finally(() => this.set('isPending', false));
    }
  }
});
