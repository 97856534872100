import Service, { inject as service } from '@ember/service';
import Cookies from 'js-cookie';
import config from 'gigshq/config/environment';

export default Service.extend({
  fastboot: service(),

  fetch() {
    let session;

    if (this.get('fastboot.isFastBoot')) {
      session = this.get(
        `fastboot.request.cookies.${config.Session.STORAGE_KEY}`
      );
    } else {
      session = Cookies.get(config.Session.STORAGE_KEY);
    }

    if (session) return JSON.parse(session);

    return null;
  },

  determineUserRole() {
    const session = this.fetch();

    if (!session) return 'Visitor';
    if (session.isAdmin) return 'Admin';

    return 'User';
  },
});
