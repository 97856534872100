import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

// Queries
import deleteArtistMutation from 'gigshq/graphql/mutations/delete-artist';

export default Controller.extend(Localizable(translations), {
  apollo: service(),

  deleteArtistTask: task(function*(artistId) {
    const mutation = deleteArtistMutation;
    const variables = {artistId};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'deleteArtist');

      this.send('refreshModel');
      this.flashMessages.success(this.localTranslation('delete.success'));
    } catch (errors) {
      const message = this.localTranslation(
        `delete.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
  }).drop(),

  handleDelete(artistId) {
    // eslint-disable-next-line no-alert
    if (window.confirm(this.localTranslation('delete.confirm'))) {
      this.deleteArtistTask.perform(artistId);
    }
  }
});
