import { not, readOnly, and } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    enableLink: T.bool.isRequired,
    eventId: T.string.isRequired,
    network: T.string.isRequired,
    state: T.shape({
      published: T.bool.isRequired,
      pending: T.bool.isRequired
    }).isRequired,
    hasLink: T.bool,
    shouldDisplayStateIcon: T.bool
  },

  getDefaultProps() {
    return {
      hasLink: true,
      shouldDisplayStateIcon: true
    };
  },

  classNameBindings: ['disabled'],

  disabled: not('enableLink'),
  isPublished: readOnly('state.published'),
  hasPendingPosts: readOnly('state.pending'),

  shouldEnableLink: and('hasLink', 'enableLink'),

  linkRoute: computed('network', function() {
    return `app.events.publish.${this.network}`;
  }),

  iconPath: computed('isPublished', 'hasPendingPosts', 'network', function() {
    const state = this.isPublished || this.hasPendingPosts ? 'on' : 'off';

    return `icn-network-${this.network}-${state}`;
  })
});
