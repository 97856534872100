import { handleActions } from 'redux-actions';
import {
  offerRedeemPending,
  offerRedeemSuccess,
  offerRedeemFailure,
  setFormValue,
  resetForm
} from 'gigshq/actions/offer-redeem';

const INITIAL_STATE = {
  isDirty: false,
  isPending: false,
  data: null,
  errors: null
};

export default handleActions(
  {
    [offerRedeemPending]: state => ({
      ...state,
      isPending: true,
      data: null,
      errors: null
    }),

    [offerRedeemSuccess]: (state, {payload}) => ({
      ...state,
      isDirty: false,
      isPending: false,
      data: payload.result.venueOfferRedemption,
      errors: null
    }),

    [offerRedeemFailure]: (state, {payload}) => ({
      ...state,
      isPending: false,
      data: null,
      errors: payload.error
    }),

    [resetForm]: () => ({
      ...INITIAL_STATE
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      isDirty: true,
      form: {
        ...state.form,
        [payload.name]: payload.value
      }
    })
  },
  INITIAL_STATE
);
