const boxIsWiderThanConstraints = (constraints, box) => {
  return box.width >= constraints.width;
};

const boxIsTallerThanConstraints = (constraints, box) => {
  return box.height >= constraints.height;
};

const boxCoversConstraintsHorizontally = (constraints, box) => {
  return (
    box.left <= constraints.left &&
    box.left + box.width >= constraints.left + constraints.width
  );
};

const boxCoversConstraintsVertically = (constraints, box) => {
  return (
    box.top <= constraints.top &&
    box.top + box.height >= constraints.top + constraints.height
  );
};

const translateBoxHorizontally = (constraints, box) => {
  if (box.left > constraints.left) {
    const left = constraints.left;

    box = {...box, left};
  }

  if (box.left + box.width < constraints.left + constraints.width) {
    const left = box.left + (constraints.width - (box.left + box.width));

    box = {...box, left};
  }

  return {...box};
};

const translateBoxVertically = (constraints, box) => {
  if (box.top > constraints.top) {
    const top = constraints.top;

    box = {...box, top};
  }

  if (box.top + box.height < constraints.top + constraints.height) {
    const top = box.top + (constraints.height - (box.top + box.height));

    box = {...box, top};
  }

  return {...box};
};

const widenBox = (constraints, box) => {
  const width = constraints.width;
  const height = width / (box.width / box.height);

  return {...box, width, height};
};

const heightenBox = (constraints, box) => {
  const height = constraints.height;
  const width = height / (box.height / box.width);

  return {...box, width, height};
};

export const constrainBox = (constraints, box) => {
  if (!boxIsWiderThanConstraints(constraints, box)) {
    box = widenBox(constraints, box);
  }

  if (!boxIsTallerThanConstraints(constraints, box)) {
    box = heightenBox(constraints, box);
  }

  if (!boxCoversConstraintsHorizontally(constraints, box)) {
    box = translateBoxHorizontally(constraints, box);
  }

  if (!boxCoversConstraintsVertically(constraints, box)) {
    box = translateBoxVertically(constraints, box);
  }

  return {
    top: Math.round(box.top),
    left: Math.round(box.left),
    width: Math.round(box.width),
    height: Math.round(box.height)
  };
};
