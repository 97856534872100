import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

// Actions
import { fillForm } from 'gigshq/actions/users';

// Queries
import query from 'gigshq/graphql/queries/user-edition';

export default Route.extend(ConfirmationMixin, {
  apollo: service(),
  fastboot: service(),
  redux: service(),
  stripe: service('stripev3'),

  beforeModel() {
    if (this.fastboot.isFastBoot) return;

    return this.stripe.load();
  },

  model() {
    return this.apollo.queryOperation()({query}, 'viewer');
  },

  afterModel({id, ...fields}) {
    const values = {userId: id, ...fields};

    this.redux.dispatch(fillForm({form: 'userEdition', values}));
  },

  isPageDirty() {
    const {
      users: {
        userEdition: {isDirty}
      }
    } = this.redux.getState();

    return isDirty;
  }
});
