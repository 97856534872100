import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

const MapSearchModal = ReduxContainer.extend({
  propTypes: {
    onClose: T.func.isRequired
  },

  actions: {
    close() {
      this.onClose();
    }
  }
});

export default MapSearchModal;
