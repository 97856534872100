import { htmlSafe } from '@ember/template';
import { PropTypes as T } from 'ember-prop-types';

export const initialize = () => {
  // Accessing Ember.Handlebars.SafeString causes a deprecation warning,
  // since we still want to check for it, we need to get to the constructor
  // underneath what `Ember.String.htmlSafe` gives us. In addition to not polluting our logs,
  // this is future-friendly since it would still work if they decided to
  // change the type of object returned by `Ember.String.htmlSafe`
  T.safeString = T.instanceOf(htmlSafe().constructor);
};

export default {
  name: 'safe-string-prop-type',
  initialize
};
