import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    maxDate: T.string.isRequired,
    selectedPublicationDate: T.instanceOf(Date).isRequired,
    selectedPublicationHour: T.nullable(T.string).isRequired,
    selectPublicationDate: T.func.isRequired,
    selectPublicationHour: T.func.isRequired
  },

  tomorrow: computed(() =>
    moment()
      .startOf('day')
      .add(1, 'day')
      .toDate()
  ),

  times: computed(() => {
    const HALF_HOURS_IN_A_DAY = 48;

    return Array(HALF_HOURS_IN_A_DAY)
      .fill(null)
      .map((_item, index) => {
        const hours = parseInt(index / 2, 10);
        const minutes = index % 2 === 0 ? '00' : '30';

        return `${hours}:${minutes}`;
      });
  }),

  selectedHour: computed('selectedPublicationHour', function() {
    return this.selectedPublicationHour || this.times[0];
  }),

  hourAcronymAtMomentOfPublication: computed(
    'selectedPublicationDate',
    function() {
      const isDaylightSavingTime = moment(this.selectedPublicationDate).isDST();
      return isDaylightSavingTime
        ? this.localTranslation('edt_timezone')
        : this.localTranslation('est_timezone');
    }
  )
});
