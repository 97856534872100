import AssetsCatalog from 'gigshq/constants/assets-catalog';
import ImageSizes from 'gigshq/constants/image-sizes';
import configValue from 'gigshq/computed-macros/config-value';
import Controller from '@ember/controller';
import { computed } from '@ember/object';

export default {
  build({i18n, localizablePrefix, fastboot, path, name}) {
    const meta = {
      href: this.url({fastboot, path}),
      description: i18n.t(`${localizablePrefix}.description`).toString(),
      keywords: i18n.t(`${localizablePrefix}.keywords`).toString(),
      opengraph: {
        facebookAppId: configValue('Facebook.APP_ID'),
        siteName: i18n.t('opengraph.site_name')
      }
    };

    if (i18n.locale.toLowerCase().includes('fr')) {
      meta.locale = 'fr_CA';
      meta.alternateLocale = 'en_US';
    } else {
      meta.locale = 'en_US';
      meta.alternateLocale = 'fr_CA';
    }

    meta.facebookScript = `https://connect.facebook.net/${meta.locale}/all.js`;

    if (name && AssetsCatalog.OpenGraph[name.toUpperCase()]) {
      const path = AssetsCatalog.OpenGraph[name.toUpperCase()][i18n.locale];

      meta.opengraph.image = this.url({fastboot, path});
      meta.opengraph.imageWidth = ImageSizes.opengraph.imageSize.width;
      meta.opengraph.imageHeight = ImageSizes.opengraph.imageSize.height;
    }

    return meta;
  },

  url({fastboot, path}) {
    let host;

    if (fastboot.get('isFastBoot')) {
      host = fastboot.get('request.host');
    } else {
      host = window.location.host;
    }

    return `https://${host}${path}`;
  }
};
