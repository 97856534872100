import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from 'gigshq/pods/web/about/translations';

export default Route.extend(Localizable(translations), {
  i18n: service(),
  fastboot: service(),
  headData: service('head-data'),
  router: service(),

  afterModel() {
    this._setMetaTags();
  },

  titleToken() {
    return this.localTranslation('title');
  },

  _setMetaTags() {
    const {i18n, fastboot, localizablePrefix} = this;
    const name = 'about';
    const path = this.router.urlFor('web.about');

    const pageMeta = PageMetaBuilder.build({
      i18n,
      localizablePrefix,
      fastboot,
      path,
      name
    });

    this.headData.setProperties(pageMeta);
  }
});
