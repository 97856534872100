import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isOpen: T.bool.isRequired
  },

  accountManager: service('managers/account-manager'),

  actions: {
    toggleMenu() {
      this.toggleProperty('isOpen');
    },
  }
});
