import { gte, notEmpty, mapBy, or, filterBy } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import priceStringFromCents from 'gigshq/computed-macros/price-string-from-cents';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import { sortByDate } from 'gigshq/utils/array';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

const MAX_SPONSOR_IMAGES = 5;
const STARTED_AT_GIG_ATTRIBUT = 'startedAt';
const ARTIST_SELECTION_ERROR_KEY = 'artist_not_present';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venue: T.nullable(T.object),
    venues: T.array,
    isPending: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    eventId: T.nullable(T.string).isRequired,
    startedAt: T.nullable(T.instanceOf(moment)).isRequired,
    name: T.string.isRequired,
    description: T.nullable(T.string).isRequired,
    gigs: T.array.isRequired,
    ticketPriceCents: T.nullable(T.number).isRequired,
    doorPriceCents: T.nullable(T.number).isRequired,
    ticketPurchaseUrl: T.string.isRequired,
    wideCrop: T.nullable(
      T.looseShape({
        id: T.string,
        imageUrl: T.string.isRequired,
        image: T.shape({
          id: T.string.isRequired,
          imageUrl: T.string.isRequired
        })
      })
    ).isRequired,
    sponsorImages: T.array,
    eventPhotos: T.array,
    showSponsors: T.bool.isRequired,
    resetCrops: T.func.isRequired,
    setProperty: T.func.isRequired,
    addGig: T.func.isRequired,
    removeGig: T.func.isRequired,
    setVenue: T.func.isRequired,
    setGigStartedAt: T.func.isRequired,
    finishCrop: T.func.isRequired,
    onLocalArtistCreate: T.func.isRequired,
    setArtistCreationFormValue: T.func.isRequired,
    markFormAsUploading: T.func
  },

  tagName: 'form',

  pendingUploadCount: 0,
  isPastEvent: computed('startedAt', function() {
    return this.startedAt != null && this.startedAt.isBefore(moment().startOf('day'));
  }),

  atMaxSponsorImages: gte('sponsorImages.length', MAX_SPONSOR_IMAGES),

  cropToolIsOpened: false,
  characterCountLimit: 140,

  hasVenue: notEmpty('venue.id'),
  hasTicketPrice: notEmpty('ticketPriceCents'),
  hasDoorPrice: notEmpty('doorPriceCents'),

  ticketPriceString: priceStringFromCents('ticketPriceCents', function update(
    value
  ) {
    this.setProperty('ticketPriceCents', value);
  }),

  doorPriceString: priceStringFromCents('doorPriceCents', function update(
    value
  ) {
    this.setProperty('doorPriceCents', value);
  }),

  selectedArtists: mapBy('gigs', 'artist'),
  selectedArtistIds: mapBy('selectedArtists', 'id'),

  sortedGigs: computed('gigs.[]', function() {
    return sortByDate(this.gigs, STARTED_AT_GIG_ATTRIBUT);
  }),

  coverImage: computed('wideCrop', function() {
    if (this.get('wideCrop.imageUrl')) return this.get('wideCrop.imageUrl');
    if (this.get('wideCrop.image.imageUrl'))
      return this.get('wideCrop.image.imageUrl');

    return AssetsCatalog.EventCover.WIDE_PLACEHOLDER;
  }),

  baseErrors: computed('errors.[]', function() {
    return ErrorFormatter.byPath('base', this.errors);
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  hasCoverError: or('errorsByInputName.{wideCrop,squareCrop}'),
  hasGigsError: or('errorsByInputName.gigs'),

  hasArtistSelectionError: computed('baseErrors.[]', function() {
    return this.baseErrors.includes(ARTIST_SELECTION_ERROR_KEY);
  }),

  init() {
    this._super(...arguments);

    const today = moment().startOf('day');

    this.minDate = today.toISOString();

    if (this.isPastEvent)
      this.maxDate = this.startedAt.toISOString();

    this.month = this.startedAt;
  },

  actions: {
    didCompleteUpload() {
      this.decrementProperty('pendingUploadCount');
      this.markFormAsUploading(this.pendingUploadCount > 0);
    },

    didStartUpload() {
      this.incrementProperty('pendingUploadCount');
      this.markFormAsUploading(true);
    },

    toggleTicketPrice(hasTicketPrice) {
      this.setProperty('ticketPriceCents', hasTicketPrice ? 0 : null);
    },

    toggleDoorPrice(hasDoorPrice) {
      this.setProperty('doorPriceCents', hasDoorPrice ? 0 : null);
    },

    toggleCropToolIsOpened() {
      this.toggleProperty('cropToolIsOpened');

      this.resetCrops();
    },

    handleStartedAtChange(startedAt) {
      this.setProperty('startedAt', startedAt.date);
    },

    createGig() {
      const artist = {};
      const startedAtDate = this.startedAt;
      const variables = {artist, startedAtDate};

      this.addGig(variables);
    }
  }
});
