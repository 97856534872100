import ImageSizes from 'gigshq/constants/image-sizes';
import moment from 'moment';

export default {
  title({i18n, event}) {
    const dateFormat = i18n.t('opengraph.event.day_date_format');
    const eventDate = moment(event.startedAt).tz(event.venue.timezone).format(dateFormat + '');
    return `${event.name} @ ${event.venue.name} (${eventDate})`;
  },

  /* eslint-disable complexity */
  description({i18n, event, venue}) {
    const venueName = venue.name;
    const mainArtist = event.gigs.length !== 0 ? event.gigs[event.gigs.length - 1].artist : event;
    const artistName = mainArtist.name;
    const genres = mainArtist.genres != null ? mainArtist.genres.map(genre => genre.name).join(', ') : '';
    const doorPrice =
      event.doorPriceCents && event.doorPriceCents !== 0
        ? parseFloat(event.doorPriceCents) / 100
        : null;
    const ticketPrice =
      event.ticketPriceCents && event.ticketPriceCents !== 0
        ? parseFloat(event.ticketPriceCents) / 100
        : null;

    const translationAttributes = {venueName, artistName};

    if (event.ticketPriceCents === -1) {
      return i18n.t('opengraph.event.description.unknown_price', {
        ...translationAttributes
      });
    } else if (doorPrice !== null && ticketPrice !== null) {
      return i18n.t('opengraph.event.description.both_prices', {
        doorPrice,
        ticketPrice,
        ...translationAttributes
      });
    } if (ticketPrice !== null) {
      return i18n.t('opengraph.event.description.ticket_price', {
        ticketPrice,
        ...translationAttributes
      });
    } if (doorPrice !== null) {
      return i18n.t('opengraph.event.description.door_price', {
        doorPrice,
        ...translationAttributes
      });
    }

    return i18n.t('opengraph.event.description.free', translationAttributes);
  },
  /* eslint-enable complexity */

  image({shareableProfile}) {
    return shareableProfile.facebook || shareableProfile.wide;
  },

  imageWidth() {
    return ImageSizes.facebook.imageSize.width * 2;
  },

  imageHeight() {
    return ImageSizes.facebook.imageSize.height * 2;
  }
};
