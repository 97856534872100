import moment from 'moment';

export const sortByDate = (list, datePropertyName) => {
  const sortedList = list.sort((firstItem, secondIem) => {
    const momentFirstItemDate = moment(firstItem[datePropertyName]);
    const momentSecondItemDate = moment(secondIem[datePropertyName]);

    if (momentFirstItemDate.isAfter(momentSecondItemDate)) return 1;
    if (momentFirstItemDate.isBefore(momentSecondItemDate)) return -1;
    return 0;
  });

  return [...sortedList];
};

export const groupByDate = (list, datePropertyName, includeTime = false, timezone) => {
  const dates = new Map();

  return list.reduce((memo, item) => {
    const itemDate = includeTime ? moment(item[datePropertyName]).tz(timezone) :
                                   moment(item[datePropertyName]).tz(item.venue.timezone).startOf('day');
    const itemDateKey = itemDate.format('YYYYMMDD');

    if (dates.has(itemDateKey)) {
      memo[dates.get(itemDateKey)].items.push(item);
    } else {
      dates.set(itemDateKey, memo.length);
      memo[dates.get(itemDateKey)] = {itemDate, items: [item]};
    }

    return memo;
  }, []);
};
