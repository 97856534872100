import { inject as service } from '@ember/service';
import { sort, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';

// Mutations
import userUpdateMutation from 'gigshq/graphql/mutations/user-update';

export default Component.extend({
  apollo: service(),

  order: 'firstName',
  orderDirection: 'asc',

  usersSorting: computed('order', 'orderDirection', function() {
    return ['firstName:asc'];
  }),

  orderedUsers: sort('users', 'usersSorting'),

  isOrderAscending: equal('orderDirection', 'asc'),

  updateTenantTask: task(function*(variables) {
    const mutation = userUpdateMutation;
    const refetchQueries = ['AdminTenants'];

    yield this.apollo.mutationOperation()({mutation, variables, refetchQueries});
  }).drop(),

  actions: {
    orderBy(order) {
      if (this.order === order) {
        if (this.orderDirection === 'asc') {
          this.set('orderDirection', 'desc');
        } else {
          this.set('orderDirection', 'asc');
        }
      } else {
        this.setProperties({
          order,
          orderDirection: 'asc'
        });
      }
    },

    saveName({userId, firstName, lastName}) {
      return this.updateTenantTask.perform({userId, firstName, lastName});
    },

    saveEmail({userId, email}) {
      return this.updateTenantTask.perform({userId, email});
    }
  }
});
