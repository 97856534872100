import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    onClose: T.func.isRequired
  },

  content: computed(() => ({isContent: true})),
  bottomBar: computed(() => ({isBottomBar: true}))
});
