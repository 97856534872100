export default [
  {
    elementType: 'geometry',
    stylers: [{color: '#212121'}]
  },
  {
    elementType: 'labels.icon',
    stylers: [{saturation: -100}, {lightness: -50}]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{color: '#70767f'}]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{color: '#212121'}]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{color: '#70767f'}]
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{color: '#9e9e9e'}]
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [{visibility: 'off'}]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{color: '#b6bcc5'}]
  },
  {
    featureType: 'poi',
    stylers: [{visibility: 'off'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{color: '#1f1f1f'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#616161'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#1f1f1f'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{color: '#2c2c2c'}]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{color: '#8a8a8a'}]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{color: '#2c2c2c'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{color: '#2c2c2c'}]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{color: '#2c2c2c'}]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{color: '#70767f'}]
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [{color: '#70767f'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{color: '#191919'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{color: '#424549'}]
  }
];
