import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNameBindings: ['isSelected'],

  isSelected: computed('index', 'selectedIndex', function() {
    return this.index === this.selectedIndex;
  }),

  click() {
    this.click();
  }
});
