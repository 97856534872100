import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/managed-venues';

export default Route.extend({
  apollo: service(),

  model() {
    const variables = { isVenueListing: true };
    return this.apollo.queryOperation()({query, variables}, 'viewer');
  }
});
