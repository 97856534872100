import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    artists: T.array.isRequired,
    onDelete: T.func.isRequired
  }
});
