export default {
  GHQ: {
    name: 'ghq',
    conditions: []
  },
  FACEBOOK: {
    name: 'facebook',
    conditions: []
  },
  FACEBOOK_TIMELINE: {
    name: 'facebook-timeline',
    conditions: ['publishedAt']
  },
  FACEBOOK_EVENT: {
    name: 'facebook-event',
    conditions: ['publishedAt']
  },
  INSTAGRAM: {
    name: 'instagram',
    conditions: null // null = disabled
  },
  PRINT: {
    name: 'print',
    conditions: null // null = disabled
  }
};
