import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

const DEFAULT_BUTTON = 'button';

export default Component.extend({
  propTypes: {
    disabled: T.bool,
    buttonType: T.string,
    type: T.string,
    onClick: T.func
  },

  getDefaultProps() {
    return {
      type: DEFAULT_BUTTON
    };
  },

  actions: {
    handleButtonClick() {
      if (!this.onClick) return;
      this.onClick();
    }
  }
});
