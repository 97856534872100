import gql from 'graphql-tag';

export default gql`
  query ManagedVenuesWithOffers($isVenueOfferListing: Boolean, $searchQuery: String) {
    viewer {
      ... on User {
        id

        venues(
          isVenueOfferListing: $isVenueOfferListing,
          searchQuery: $searchQuery,
          orderParams: [{attribute: name, order: ASC, caseInsensitive: true}]
        ) {
          id
          name
          formattedAddress
          timezone
          maxedActiveOffers

          profileImage {
            id
            imageUrl
          }

          venueOffers(isVenueOfferListing: $isVenueOfferListing) {
            id
            offerType
            description
            fineprint
            blackoutDates
            paused
            deletedAt
          }

        }
      }
    }
  }
`;
/*
          upcomingEvent {
            id
            name
            slug
          }
*/
