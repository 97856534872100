import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  apollo: service(),

  propTypes: {
    isLoggingOut: T.bool.isRequired,
    session: T.nullable(T.object).isRequired,
    error: T.nullable(T.object).isRequired,
    onLogoutSuccess: T.func.isRequired
  },

  tagName: 'button',

  click() {
    this.logout(this.apollo.clearStoreOperation(), this.session);
  },

  didReceiveAttrs() {
    if (!this.session)
      this.onLogoutSuccess();
  }
});
