// Vendor
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, not, or } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  fastboot: service(),
  isFastBoot: readOnly('fastboot.isFastBoot'),
  isNotFastBoot: not('isFastBoot'),

  isSearchEngine: computed(function() {
    if (!this.fastboot.isFastBoot) return false;

    const userAgent = this.fastboot.request.headers.get('user-agent');

    // Google, MSN, Bing, Yahoo, DuckDuckGo, Facebook, Amazon Alexa
    return /googlebot|msnbot|bingbot|slurp|duckduckbot|facebot|facebook|ia_archiver/i.test(
      userAgent
    );
  }),

  isSearchEngineOrBrowser: or('isSearchEngine', 'isNotFastBoot')
});
