import { notEmpty, bool } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Component.extend({
  propTypes: {
    event: T.object.isRequired
  },

  publishingNetworks: PublishingNetworks,

  isPublishedOnInstagram: notEmpty('event.instagramPublishedAt'),
  isPublishedOnGHQ: notEmpty('event.publishedAt'),
  isPublishedOnFacebookPage: bool('event.hasFacebookPagePosts'),
  isPublishedOnFacebookEvent: bool('event.hasFacebookEventPosts'),
  isPublishedOnPrint: notEmpty('event.printPublishedAt'),

  hasUpcomingFacebookPagePosts: notEmpty('event.upcomingFacebookPagePosts'),

  instagramState: computed('isPublishedOnInstagram', function() {
    return {
      pending: false,
      disabled: this._isNetworkDisabled(PublishingNetworks.INSTAGRAM),
      published: this.isPublishedOnInstagram
    };
  }),

  ghqState: computed('isPublishedOnGHQ', function() {
    return {
      pending: false,
      disabled: false,
      published: this.isPublishedOnGHQ
    };
  }),

  facebookPageState: computed(
    'isPublishedOnFacebookPage',
    'hasUpcomingFacebookPagePosts',
    function() {
      return {
        pending: this.hasUpcomingFacebookPagePosts,
        disabled: this._isNetworkDisabled(PublishingNetworks.FACEBOOK_TIMELINE),
        published: this.isPublishedOnFacebookPage
      };
    }
  ),

  facebookEventState: computed('isPublishedOnFacebookEvent', function() {
    return {
      pending: false,
      disabled: this._isNetworkDisabled(PublishingNetworks.FACEBOOK_EVENT),
      published: this.isPublishedOnFacebookEvent
    };
  }),

  printState: computed('isPublishedOnPrint', function() {
    return {
      pending: false,
      disabled: false,
      published: this.isPublishedOnPrint
    };
  }),

  _isNetworkDisabled(network) {
    return network.conditions.some(condition => {
      if (this.get(`event.${condition}`) === null) return true;
    });
  }
});
