import Component from '@ember/component';
import { computed } from '@ember/object';
import genders from 'gigshq/constants/genders';
import moment from 'moment';

export default Component.extend({
  gender: computed('user.member.gender', function() {
    if (this.user.member == null) return null;

    return genders.find((gender) => gender.key === this.user.member.gender).label;
  }),

  age: computed('user.member.birthdate', function() {
    if (this.user.member == null) return 0;

    return moment().diff(this.user.member.birthdate, 'years');
  })
});
