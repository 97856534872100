import Component from '@ember/component';

export default Component.extend({
  shouldShowTransferModal: false,
  selectedVenue: null,
  actions: {
    toggleTransferOwnership(venue) {
      this.setProperties({
        selectedVenue: venue,
        shouldShowTransferModal: true
      });
    },
    cancelModal() {
      this.setProperties({
        selectedVenue: null,
        shouldShowTransferModal: false
      });
    }
  }
});
