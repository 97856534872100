import Component from '@ember/component';
import { notEmpty } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  classNameBindings: [ 'shouldDisplayProfileImage:with-profile-image' ],

  shouldDisplayProfileImage: notEmpty('artist.profileImage'),
});
