import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import config from 'gigshq/config/environment';
import moment from 'moment';

export default Service.extend({
  cookies: service(),

  favouriteVenueIds: computed(function() {
    const favouriteVenueIdsFromCookie = this.cookies.read(
      config.FavouritesConfiguration.COOKIE_NAME
    );
    return (
      (favouriteVenueIdsFromCookie && favouriteVenueIdsFromCookie.split(',')) ||
      []
    );
  }),

  add(venue) {
    const favouriteVenueIds = new Set(this.favouriteVenueIds);
    favouriteVenueIds.add(venue.id);
    this._writeCookie(favouriteVenueIds);
    this.notifyPropertyChange('favouriteVenueIds');
  },

  remove(venue) {
    const favouriteVenueIds = new Set(this.favouriteVenueIds);
    favouriteVenueIds.delete(venue.id);
    this._writeCookie(favouriteVenueIds);
    this.notifyPropertyChange('favouriteVenueIds');
  },

  toggle(venue) {
    if (this.isFavouriteVenue(venue)) {
      this.remove(venue);
    } else {
      this.add(venue);
    }
  },

  isFavouriteVenue(venue) {
    return this.favouriteVenueIds.includes(venue.id);
  },

  _writeCookie(favouriteVenueIds) {
    this.cookies.write(
      config.FavouritesConfiguration.COOKIE_NAME,
      Array.from(favouriteVenueIds).join(','),
      {
        path: '/',
        expires: moment()
          .add(config.FavouritesConfiguration.COOKIE_DURATION_IN_YEARS, 'years')
          .toDate()
      }
    );
  }
});
