import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { USER_TYPE_TENANT } from 'gigshq/constants/user-types';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  tenantUserType: USER_TYPE_TENANT,

  currentYear: computed(() =>
    moment()
      .startOf('day')
      .toDate()
      .getFullYear()
  ),

  actions: {
    openIntercom() {
      window.Intercom('show');
    }
  },

});
