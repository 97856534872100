import Route from '@ember/routing/route';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Route.extend({
  redirect() {
    const event = this.modelFor('app.events.publish').event;

    const conditionsAreMet = PublishingNetworks.FACEBOOK_EVENT.conditions.every(
      condition => {
        return event[condition] !== null;
      }
    );

    if (!conditionsAreMet)
      this.transitionTo('app.events.publish.ghq', event.id);
  }
});
