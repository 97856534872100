import Component from '@ember/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    value: T.nullable(T.oneOfType([T.string, T.number])),
    disabled: T.bool,
    readOnly: T.bool,
    autofocus: T.bool,
    withHelp: T.bool,
    helpText: T.oneOfType([T.string, T.safeString]),
    withCounter: T.bool,
    counterLimit: T.number,
    withLink: T.bool,
    linkLabel: T.oneOfType([T.string, T.safeString]),
    linkRoute: T.string,
    error: T.string,
    update: T.func
  },

  getDefaultProps() {
    return {
      disabled: false,
      readOnly: false,
      autofocus: false,
      withHelp: false,
      helpText: '',
      withCounter: false,
      counterLimit: 0,
      error: null,
      update: () => {}
    };
  },

  classNameBindings: ['hasError', 'class', 'focused'],

  hasError: notEmpty('error'),
  focused: false,
  maxForDates: computed('type', function() {
    return this.type === 'date' ? '9999-12-31' : null;
  }),

  didInsertElement() {
    const input = this.$().find('input');

    input.on('focus', () => this.set('focused', true));
    input.on('blur', () => this.set('focused', false));

    if (this.autofocus) input.focus();
  },

  willDestroyElement() {
    const input = this.$().find('input');

    input.off('focus');
    input.off('blur');
  }
});
