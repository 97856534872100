import { createAction } from 'redux-actions';
import mutation from 'gigshq/graphql/mutations/persist-artist';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';

export const createArtistPending = createAction(
  'ARTIST_CREATION_TOOL_CREATE_ARTIST_PENDING'
);
export const createArtistSuccess = createAction(
  'ARTIST_CREATION_TOOL_CREATE_ARTIST_SUCCESS'
);
export const createArtistFailure = createAction(
  'ARTIST_CREATION_TOOL_CREATE_ARTIST_FAILURE'
);

export const createArtist = ({mutate, event}) => async dispatch => {
  dispatch(createArtistPending());

  try {
    const variables = GraphQLVariablesBuilder.build(mutation, event);
    const result = await mutate({mutation, variables}, 'persistArtist');

    dispatch(createArtistSuccess({result}));

    return result;
  } catch (error) {
    dispatch(createArtistFailure({error}));
  }
};

export const setFormValue = createAction('ARTIST_CREATION_TOOL_SET_FORM_VALUE');
export const resetForm = createAction('ARTIST_CREATION_TOOL_RESET_FORM');
