import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    image: T.looseShape({
      id: T.string,
      imageUrl: T.string,
      thumbnailImageUrl: T.string
    }).isRequired,
    selectedImage: T.nullable(T.object).isRequired,
    selectImage: T.func.isRequired
  },

  tagName: 'li',

  classNameBindings: ['isSelected'],

  isSelected: computed('selectedImage', 'image', function() {
    return this.get('selectedImage.id') === this.get('image.id');
  })
});
