import { readOnly, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';

export default Component.extend(LocationStyleable, {
  propTypes: {
    state: T.looseShape({
      published: T.bool.isRequired,
      pending: T.bool.isRequired
    }).isRequired
  },

  classNameBindings: ['isPending'],

  isPublished: readOnly('state.published'),
  isPending: readOnly('state.pending'),

  isVisible: notEmpty('iconPath'),

  iconPath: computed('isPublished', 'isPending', function() {
    if (this.isPending) return 'icn-publishing-scheduled';
    if (this.isPublished) return 'icn-small-check';
  })
});
