define("gigshq/graphql/fragments/venue-fields", ["exports", "gigshq/graphql/fragments/event-details"], function (_exports, _eventDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "FragmentDefinition",
      "name": {
        "kind": "Name",
        "value": "venueFields"
      },
      "typeCondition": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Venue"
        }
      },
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "name"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "slug"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "streetAddress"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "city"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "country"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "formattedAddress"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "phoneNumber"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "email"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "latitude"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "longitude"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "description"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "openingHours"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "capacityCount"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "deletedAt"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "timezone"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "venueCategory"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "name"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "socialProfile"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "websiteUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "facebookUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "twitterUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "instagramUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "spotifyUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "bandcampUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "itunesUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "soundcloudUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "playMusicUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "tidalUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "youtubeUrl"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "images"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "filterParams"
            },
            "value": {
              "kind": "ListValue",
              "values": [{
                "kind": "ObjectValue",
                "fields": [{
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "scope"
                  },
                  "value": {
                    "kind": "EnumValue",
                    "value": "by_type"
                  }
                }, {
                  "kind": "ObjectField",
                  "name": {
                    "kind": "Name",
                    "value": "arguments"
                  },
                  "value": {
                    "kind": "ListValue",
                    "values": [{
                      "kind": "StringValue",
                      "value": "venue_photo",
                      "block": false
                    }]
                  }
                }]
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "imageUrl"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "size"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "width"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "height"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "profileImage"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "imageUrl"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 728,
      "source": {
        "body": "\n  fragment venueFields on Venue {\n    id\n    name\n    slug\n    streetAddress\n    city\n    country\n    formattedAddress\n    phoneNumber\n    email\n    latitude\n    longitude\n    description\n    openingHours\n    capacityCount\n    deletedAt\n    timezone\n\n    venueCategory {\n      name\n    }\n\n    socialProfile {\n      id\n      websiteUrl\n      facebookUrl\n      twitterUrl\n      instagramUrl\n      spotifyUrl\n      bandcampUrl\n      itunesUrl\n      soundcloudUrl\n      playMusicUrl\n      tidalUrl\n      youtubeUrl\n    }\n\n    images(filterParams: [{scope: by_type, arguments: [\"venue_photo\"]}]) {\n      id\n      imageUrl\n      size {\n        width\n        height\n      }\n    }\n\n    profileImage {\n      id\n      imageUrl\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
