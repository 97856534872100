import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Actions
import { setVenue } from 'gigshq/actions/offers';

// Queries
import query from 'gigshq/graphql/queries/venue-offer-create';

export default Route.extend({
  apollo: service(),
  redux: service(),

  model() {
    return this.apollo.queryOperation()({ query }, 'viewer');
  },

  afterModel({venues, tenant}) {
    // If user is a tenant, we automatically select the first
    // available venue.
    const venue = tenant ? venues[0] : null;

    this.redux.dispatch(setVenue({form: 'offerEdition', venue}));
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
