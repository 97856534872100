import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),
  flashMessages: service('flash-messages'),
  router: service(),

  actions: {
    publish(eventId) {
      this.router.transitionTo('app.events.publish', eventId);
    },

    handleEventCreate(eventId) {
      if (this.get('accountManager.isUserTypeArtist')) {
        const message = this.localTranslation('sent_notification_to_tenant');
        this.flashMessages.success(message, {timeout: 8000});
      }

      this.router.transitionTo('app.events.publish', eventId);
    }
  }
});
