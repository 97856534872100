import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    user: T.object.isRequired,
    saveName: T.func.isRequired,
    saveEmail: T.func.isRequired,
  },

  tagName: 'tr',

  classNameBindings: ['isEditingName', 'isEditingEmail'],

  firstName: reads('user.firstName'),
  lastName: reads('user.lastName'),
  email: reads('user.email'),
  userId: reads('user.id'),

  actions: {
    editName() {
      this.set('isEditingName', true);
    },

    editEmail() {
      this.set('isEditingEmail', true);
    },

    handleSaveName({firstName, lastName}) {
      const userId = this.get('user.id');

      this.saveName({userId, firstName, lastName});

      this.set('isEditingName', false);
    },

    handleSaveEmail(email) {
      const userId = this.get('user.id');

      this.saveEmail({userId, email});

      this.set('isEditingEmail', false);
    }
  }
});
