import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { and, bool, or } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import pagination from 'gigshq/constants/pagination';

const SIDEBAR_CONTAINER = '.gigs-web-map-sidebar__container';

export default Component.extend(Localizable(translations), {
  eventsFetcher: service('fetchers/events-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),
  isLoadingEventsOrVenues: or('eventsFetcher.isLoadingEvents', 'venuesFetcher.isLoadingVenues'),
  isAppendingEvents: bool('eventsFetcher.isAppendingEvents'),

  hasDateRange: and('fromDate', 'toDate'),

  init() {
    this._super(...arguments);
    this.onScroll = this.onScroll.bind(this);
  },

  didInsertElement() {
    document
      .querySelector(SIDEBAR_CONTAINER)
      .addEventListener('scroll', this.onScroll);
  },

  willDestroyElement() {
    document
      .querySelector(SIDEBAR_CONTAINER)
      .removeEventListener('scroll', this.onScroll);
  },

  onScroll() {
    if (!this.onBottomReached) return;

    const element = document.querySelector(SIDEBAR_CONTAINER);
    const scrollTop = element.scrollTop;
    const innerHeight = element.offsetHeight;
    const scrollHeight = element.scrollHeight;

    if (scrollTop + innerHeight >= scrollHeight - pagination.TRIGGER_FROM_BOTTOM_DISTANCE)
      this.onBottomReached();
  }
});
