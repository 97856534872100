import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'label',

  classNameBindings: ['hasError'],

  hasError: notEmpty('error'),

  onKeyDown: () => {},
  onBlur: () => {}
});
