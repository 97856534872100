import Service, { inject as service } from '@ember/service';
import config from 'gigshq/config/environment';
import RSVP from 'rsvp';

export default Service.extend({
  fastboot: service(),

  fetch() {
    if (this.fastboot.isFastBoot) return {latitude: 0, longitude: 0};

    return new RSVP.Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) resolve(config.MapConfiguration.FALLBACK_MAP_CENTER);

      navigator.geolocation.getCurrentPosition(
        ({coords}) => resolve(coords),
        () => resolve(config.MapConfiguration.FALLBACK_MAP_CENTER),
        {
          timeout: 10000 // 10 seconds
        }
      );
    });
  }
});
