import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import moment from 'moment';

// Actions
import { addGig, setStartedAt, setVenue } from 'gigshq/actions/events';

// Queries
import eventCreationQuery from 'gigshq/graphql/queries/event-creation';

export default Route.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),
  can: service(),
  redux: service(),

  model() {
    return this.apollo.queryOperation()({query: eventCreationQuery}, 'viewer');
  },

  redirect() {
    if (this.can.cannot('create event', {})) {
      return this.transitionTo('app.events.index');
    }
  },

  afterModel({managedArtists, venues, tenant}) {
    const today = moment()
      .startOf('hour')
      .toISOString();

    // We always automatically assign the first managed artist to
    // the new event.
    const artist = managedArtists[0];

    // If user is a tenant, we automatically select the first
    // available venue.
    const venue = tenant ? venues[0] : null;

    if (this.get('accountManager.isUserTypeArtist'))
      this._addArtistToEvent(artist, today);

    this.redux.dispatch(
      setStartedAt({form: 'eventCreation', startedAt: today})
    );

    this.redux.dispatch(setVenue({form: 'eventCreation', venue}));
  },

  _addArtistToEvent(artist, today) {
    const gig = {
      startedAtDate: today,
      artist
    };

    this.redux.dispatch(addGig({form: 'eventCreation', gig}));
  }
});
