import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/event-facebook-scheduled-posts';

export default Route.extend({
  apollo: service(),

  model() {
    const variables = {
      id: this.modelFor('app.events.publish').event.id
    };

    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  afterModel(model) {
    if (model.event.upcomingFacebookPagePosts.length === 0) {
      this.transitionTo(
        'app.events.publish.facebook-timeline.new',
        model.event.id
      );
    }
  }
});
