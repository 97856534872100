export default {
  ARTIST_PROFILE: 'artistProfile',
  ARTIST_PHOTO: 'artistPhoto',
  EVENT_SPONSOR: 'eventSponsor',
  EVENT_PHOTO: 'eventPhoto',
  USER_PHOTO: 'userPhoto',
  VENUE_PROFILE: 'venueProfile',
  VENUE_PHOTO: 'venuePhoto',
  VENUE_OFFER_PHOTO: 'venueOfferPhoto'
};
