import { readOnly, bool, or, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    gig: T.object.isRequired
  },

  artist: readOnly('gig.artist'),
  artistProfileImage: readOnly('artist.profileImage.imageUrl'),
  displayArtistProfileImage: bool('artistProfileImage'),
  hasGenres: notEmpty('genres'),
  hasDescription: notEmpty('artist.description'),

  isVisible: or('displayArtistProfileImage', 'hasGenres', 'hasDescription'),

  genres: computed('artist.genres.@each.name', function() {
    return this.get('artist.genres')
      .map(genre => genre.name)
      .join(', ');
  })
});
