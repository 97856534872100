import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import { resetPassword } from 'gigshq/actions/password-reset';

const mapStateToComputed = state => {
  const {
    passwordReset: {
      resetPasswordForm: {isPending, isSuccess, errors}
    }
  } = state;

  return {
    isPending,
    isSuccess,
    errors
  };
};

const mapDispatchToActions = dispatch => {
  return {
    resetPassword: (mutate, variables) =>
      dispatch(resetPassword({mutate, ...variables}))
  };
};

const ResetPasswordForm = ReduxContainer.extend({
  apollo: service(),

  propTypes: {
    resetPasswordToken: T.string.isRequired,
    onResetPasswordSuccess: T.func.isRequired
  },

  actions: {
    handleSubmit({password, passwordConfirmation}) {
      const variables = {
        password,
        passwordConfirmation,
        resetPasswordToken: this.resetPasswordToken
      };

      return this.get('actions.resetPassword')(
        this.apollo.mutationOperation(),
        variables
      ).then(data => {
        if (data === undefined) return;

        this.onResetPasswordSuccess();
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(ResetPasswordForm);
