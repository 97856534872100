define("gigshq/graphql/queries/artist-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ArtistImages"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "artistId"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "artist"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "publicArtist"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    },
                    "value": {
                      "kind": "Variable",
                      "name": {
                        "kind": "Name",
                        "value": "artistId"
                      }
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "images"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "filterParams"
                        },
                        "value": {
                          "kind": "ListValue",
                          "values": [{
                            "kind": "ObjectValue",
                            "fields": [{
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "scope"
                              },
                              "value": {
                                "kind": "EnumValue",
                                "value": "by_type"
                              }
                            }, {
                              "kind": "ObjectField",
                              "name": {
                                "kind": "Name",
                                "value": "arguments"
                              },
                              "value": {
                                "kind": "ListValue",
                                "values": [{
                                  "kind": "StringValue",
                                  "value": "artist_photo",
                                  "block": false
                                }]
                              }
                            }]
                          }]
                        }
                      }],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "imageUrl"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "alias": {
                            "kind": "Name",
                            "value": "thumbnailImageUrl"
                          },
                          "name": {
                            "kind": "Name",
                            "value": "imageUrl"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "width"
                            },
                            "value": {
                              "kind": "IntValue",
                              "value": "184"
                            }
                          }, {
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "height"
                            },
                            "value": {
                              "kind": "IntValue",
                              "value": "138"
                            }
                          }],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "alias": {
                            "kind": "Name",
                            "value": "wideCrop"
                          },
                          "name": {
                            "kind": "Name",
                            "value": "suggestedCrop"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "wide",
                              "block": false
                            }
                          }],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "imageUrl"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }, {
                          "kind": "Field",
                          "alias": {
                            "kind": "Name",
                            "value": "squareCrop"
                          },
                          "name": {
                            "kind": "Name",
                            "value": "suggestedCrop"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "square",
                              "block": false
                            }
                          }],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "imageUrl"
                              },
                              "arguments": [],
                              "directives": []
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 610,
      "source": {
        "body": "\n  query ArtistImages($artistId: ID!) {\n    viewer {\n      ... on User {\n        id\n\n        artist: publicArtist(id: $artistId) {\n          id\n\n          images(\n            filterParams: [{scope: by_type, arguments: [\"artist_photo\"]}]\n          ) {\n            id\n            imageUrl\n            thumbnailImageUrl: imageUrl(width: 184, height: 138)\n\n            wideCrop: suggestedCrop(type: \"wide\") {\n              id\n              imageUrl\n            }\n\n            squareCrop: suggestedCrop(type: \"square\") {\n              id\n              imageUrl\n            }\n          }\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
