import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';

// Actions
import { signUp } from 'gigshq/actions/session';

const mapStateToComputed = ({
  session: {
    data,
    signUpState: {isPending, errors}
  }
}) => {
  return {
    errors,
    isSigningUp: isPending,
    isSignedUp: !!data
  };
};

const mapDispatchToActions = dispatch => {
  return {
    signUp: (executeQuery, mutate, formFields) =>
      dispatch(signUp(executeQuery, mutate, formFields))
  };
};

const SignUpForm = ReduxContainer.extend({
  propTypes: {
    userType: T.string.isRequired,
    onSignUpSuccess: T.func.isRequired
  },

  apollo: service(),

  actions: {
    doSignUp(formFields) {
      this.get('actions.signUp')(
        this.apollo.queryOperation(),
        this.apollo.mutationOperation(),
        formFields
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(SignUpForm);
