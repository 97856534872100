import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import {
  createArtist,
  setFormValue,
  resetForm
} from 'gigshq/actions/artist-creation-tool';

const mapStateToComputed = ({artistCreationTool}) => {
  const {
    isDirty,
    isPending,
    data,
    errors,
    form: {name, contactEmail}
  } = artistCreationTool;

  return {
    isDirty,
    isPending,
    data,
    errors,
    name,
    contactEmail
  };
};

const mapDispatchToActions = dispatch => {
  return {
    createArtist: (mutate, event) => dispatch(createArtist({mutate, event})),
    resetForm: () => dispatch(resetForm()),
    setFormValue: (name, value) => dispatch(setFormValue({name, value}))
  };
};

const ArtistCreationTool = ReduxContainer.extend({
  apollo: service(),

  propTypes: {
    onArtistCreate: T.func.isRequired,
    onClose: T.func.isRequired
  },

  willDestroyElement() {
    this.get('actions.resetForm')();
  },

  actions: {
    close() {
      this.onClose();
    },

    create() {
      const variables = this.getProperties('name', 'contactEmail');

      this.get('actions.createArtist')(
        this.apollo.mutationOperation(),
        variables
      ).then(result => {
        if (!result) return;

        const {
          artist: {id, name, editSlug}
        } = result;

        this.onArtistCreate({id, name, editSlug});
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(ArtistCreationTool);
