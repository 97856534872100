import { handleActions } from 'redux-actions';
import {
  loadArtistImagesPending,
  loadArtistImagesSuccess,
  loadArtistImagesFailure,
  createLocalArtistPending,
  createLocalArtistSuccess,
  createLocalArtistFailure,
  saveArtistPending,
  saveArtistSuccess,
  saveArtistFailure,
  deleteImagePending,
  deleteImageSuccess,
  deleteImageFailure,
  fillForm,
  resetForm,
  updateGenres,
  setFormValue,
  setFormSocialProfileValue,
  markFormAsUploading
} from 'gigshq/actions/artists';

const INITIAL_STATE = {
  artistImageQuery: {
    isPending: false,
    data: null,
    errors: null
  },

  deleteImageMutation: {
    isPending: false,
    errors: null
  },

  artistEdition: {
    form: {
      name: '',
      genres: [],
      availableGenres: [],
      description: '',
      contactEmail: '',
      socialProfile: {
        facebookUrl: '',
        twitterUrl: '',
        instagramUrl: '',
        spotifyUrl: '',
        bandcampUrl: '',
        itunesUrl: '',
        soundcloudUrl: '',
        playMusicUrl: '',
        tidalUrl: '',
        youtubeUrl: ''
      },
      profileImage: {
        id: '',
        imageUrl: ''
      }
    },
    isDirty: false,
    isSaved: false,
    isPending: false,
    isUploading: false,
    data: null,
    errors: null
  },

  localArtistCreation: {
    isSaved: false,
    isPending: false,
    isUploading: false,
    data: null,
    errors: null
  }
};

export default handleActions(
  {
    [loadArtistImagesPending]: state => ({
      ...state,
      artistImageQuery: {
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [loadArtistImagesSuccess]: (state, {payload}) => ({
      ...state,
      artistImageQuery: {
        isPending: false,
        data: payload,
        errors: null
      }
    }),

    [loadArtistImagesFailure]: (state, {payload}) => ({
      ...state,
      artistImageQuery: {
        isPending: false,
        data: null,
        errors: payload
      }
    }),

    [createLocalArtistPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [createLocalArtistSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isSaved: true,
        isPending: false,
        data: payload.result.artist,
        errors: null
      }
    }),

    [createLocalArtistFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [saveArtistPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveArtistSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isSaved: true,
        isPending: false,
        data: payload.result.Artist,
        errors: null
      }
    }),

    [saveArtistFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [deleteImagePending]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: true,
        errors: null
      }
    }),

    [deleteImageSuccess]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: null
      }
    }),

    [deleteImageFailure]: (state, {payload}) => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: payload
      }
    }),

    [resetForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...INITIAL_STATE[payload.form]
      }
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        isSaved: false,
        form: {
          ...state[payload.form].form,
          [payload.name]: payload.value
        }
      }
    }),

    [setFormSocialProfileValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        isSaved: false,
        form: {
          ...state[payload.form].form,
          socialProfile: {
            ...state[payload.form].form.socialProfile,
            [payload.name]: payload.value
          }
        }
      }
    }),

    [updateGenres]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        isSaved: false,
        form: {
          ...state[payload.form].form,
          genres: payload.genres
        }
      }
    }),

    [fillForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: payload.values
      }
    }),

    [markFormAsUploading]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isUploading: payload.isUploading
      }
    })
  },
  INITIAL_STATE
);
