define("gigshq/graphql/queries/managed-artists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ManagedArtists"
      },
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "managedArtists"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "orderParams"
                    },
                    "value": {
                      "kind": "ListValue",
                      "values": [{
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "attribute"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "name"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "order"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "ASC"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "caseInsensitive"
                          },
                          "value": {
                            "kind": "BooleanValue",
                            "value": true
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "attribute"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "id"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "order"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "ASC"
                          }
                        }]
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "description"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "profileImage"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "imageUrl"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "width"
                            },
                            "value": {
                              "kind": "IntValue",
                              "value": "300"
                            }
                          }, {
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "height"
                            },
                            "value": {
                              "kind": "IntValue",
                              "value": "300"
                            }
                          }],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "upcomingGig"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "event"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "name"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "slug"
                              },
                              "arguments": [],
                              "directives": []
                            }, {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "venue"
                              },
                              "arguments": [],
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "id"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "slug"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 646,
      "source": {
        "body": "\n  query ManagedArtists {\n    viewer {\n      ... on User {\n        id\n\n        managedArtists(\n          orderParams: [\n            {attribute: name, order: ASC, caseInsensitive: true}\n            {attribute: id, order: ASC}\n          ]\n        ) {\n          id\n          name\n          description\n\n          profileImage {\n            id\n            imageUrl(width: 300, height: 300)\n          }\n\n          upcomingGig {\n            id\n            event {\n              id\n              name\n              slug\n\n              venue {\n                id\n                slug\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
