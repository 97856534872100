import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  sessionFetcher: service('fetchers/session-fetcher'),

  redirect() {
    const session = this.sessionFetcher.fetch();

    if (!session || !session.isAdmin) {
      this.transitionTo('app');
    }
  }
});
