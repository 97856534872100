export default {
  opengraph: {
    imageSize: {
      width: 600,
      height: 315
    }
  },
  facebook: {
    imageSize: {
      width: 476,
      height: 248
    }
  },
  instagram: {
    imageSize: {
      width: 502,
      height: 502
    }
  }
};
