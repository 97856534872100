import Service, { inject as service } from '@ember/service';

// Queries
import artistsQuery from 'gigshq/graphql/queries/web-artists';

export default Service.extend({
  apollo: service(),
  sessionFetcher: service('fetchers/session-fetcher'),

  searchArtists(searchQuery) {
    const query = artistsQuery(this.sessionFetcher.determineUserRole());
    const variables = { searchQuery };
    return this.apollo.queryOperation()({ query, variables }, 'viewer');
  }
});

