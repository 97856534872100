/*
import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';

// Constants
const ServiceWorkerActions = {
  SKIP_WAITING: 'skipWaiting',
  CHECK_FOR_UPDATES: 'checkForUpdates'
};

export default Service.extend({
  fastboot: service(),

  registration: null,
  updateReady: false,
  updateCallbacks: computed(() => []),

  register() {
    if (this.get('fastboot.isFastBoot')) return this;
    if (!navigator.serviceWorker) return this;

    this.listenToControllerChange();
    this.listenToUpdates();

    return this;
  },

  checkForUpdates() {
    if (this.updateReady) return;
    if (!this.registration) return;
    if (!this.get('registration.active')) return;

    this.get('registration.active').postMessage({
      action: ServiceWorkerActions.CHECK_FOR_UPDATES
    });
  },

  onUpdateReady(callback) {
    this.set('updateCallbacks', [ ...this.updateCallbacks, callback ]);
  },

  update() {
    if (!this.registration) return;
    if (!this.get('registration.waiting')) return;

    this.get('registration.waiting').postMessage({
      action: ServiceWorkerActions.SKIP_WAITING
    });
  },

  async listenToUpdates() {
    const registration = await navigator.serviceWorker.getRegistration();

    if (!registration) return;

    this.set('registration', registration);

    // The window client isn’t currently controlled so it’s a new
    // ServiceWorker that will activate immediately
    if (!navigator.serviceWorker.controller) return;

    if (registration.waiting) {
      this.notifyUpdateReady();
      return;
    }

    if (registration.installing) {
      this.trackInstallingWorker(registration.installing);
      return;
    }

    this.listenToUpdateFound(registration);
  },

  listenToControllerChange() {
    // When the user asks to refresh the UI, we’ll need to reload the window
    // but we have to make sure to refresh only once.
    // This works around a bug in “Update on reload”.
    let preventDevToolsReloadLoop;

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (preventDevToolsReloadLoop) return;

      preventDevToolsReloadLoop = true;

      window.location.reload();
    });
  },

  listenToUpdateFound(registration) {
    registration.addEventListener('updatefound', () => {
      if (!registration.installing) return;

      this.trackInstallingWorker(registration.installing);
    });
  },

  notifyUpdateReady() {
    this.updateCallbacks.forEach(callback => callback.call(this));
  },

  trackInstallingWorker(serviceWorker) {
    serviceWorker.addEventListener('statechange', () => {
      if (serviceWorker.state === 'installed') {
        this.notifyUpdateReady();
      }
    });
  }
});
*/
