import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';
import $ from 'jquery';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

const GALLERY_IMAGE_MARGIN = 10;
const RESIZE_THROTTLE_DELAY = 50;
const VERTICAL_SWIPE_THRESHOLD = 50;

export default Component.extend(Localizable(translations), {
  propTypes: {
    images: T.array.isRequired
  },

  visibleImageIndex: 0,

  containerStyle: computed('imageOffsets', 'visibleImageIndex', function() {
    if (!this.imageOffsets) return;

    return htmlSafe(
      `transform: translateX(-${this.imageOffsets[this.visibleImageIndex]}px)`
    );
  }),

  mappedImages: computed('images', 'viewportSize.{width,height}', function() {
    const {images, viewportSize} = this;

    if (!viewportSize) return;

    const viewportWidth = viewportSize.width;
    const viewportHeight = viewportSize.height;

    return images.map(image => {
      const width = (image.size.width * viewportHeight) / image.size.height;

      return {
        imageUrl: image.imageUrl,
        width: Math.min(width, viewportWidth),
        height: viewportHeight
      };
    });
  }),

  imageOffsets: computed('mappedImages', 'viewportSize.width', function() {
    const {mappedImages, viewportSize} = this;

    if (!mappedImages) return;

    let offset = 0;
    const imageOffsets = mappedImages.reduce((memo, image) => {
      memo.push(offset);

      offset += image.width + GALLERY_IMAGE_MARGIN;

      return memo;
    }, []);

    // If the last image is narrower than the viewport
    // we need to move the gallery a bit less to make the image
    // stick to the viewport’s right edge
    const lastOffset = imageOffsets[imageOffsets.length - 1];
    const lastImage = mappedImages[mappedImages.length - 1];
    imageOffsets[imageOffsets.length - 1] =
      lastOffset + lastImage.width - viewportSize.width;

    return imageOffsets;
  }),

  init() {
    this._super(...arguments);

    this.onResize = this.onResize.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  },

  didInsertElement() {
    this.set('$viewport', this.$('.gigs-photo-gallery__viewport'));
    const $viewport = this.$viewport;

    this.setViewportSize();

    $(window).on('resize', this.onResize);
    $viewport.on('touchstart', this.onTouchStart);
    $viewport.on('touchend', this.onTouchEnd);

    // scroll straight to event photo gallery, if present
    if ($("#event-photo-gallery").length !== 0) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#event-photo-gallery").offset().top
      }, 200);
    }
  },

  willDestroyElement() {
    const $viewport = this.$viewport;

    $(window).off('resize', this.onResize);
    $viewport.off('touchstart', this.onTouchStart);
    $viewport.off('touchend', this.onTouchEnd);
  },

  onResize() {
    run.throttle(this, 'setViewportSize', RESIZE_THROTTLE_DELAY);
  },

  onTouchStart(event) {
    this.setProperties({
      touchStartX: event.changedTouches[0].screenX,
      touchStartY: event.changedTouches[0].screenY
    });
  },

  onTouchEnd(event) {
    this.setProperties({
      touchEndX: event.changedTouches[0].screenX,
      touchEndY: event.changedTouches[0].screenY
    });

    this.handleGesure();
  },

  setViewportSize() {
    const $viewport = this.$viewport;

    this.set('viewportSize', {
      width: $viewport.width(),
      height: $viewport.height()
    });
  },

  /* eslint-disable complexity */
  handleGesure() {
    const {touchStartX, touchStartY, touchEndX, touchEndY} = this;

    if (Math.abs(touchStartY - touchEndY) > VERTICAL_SWIPE_THRESHOLD) return;

    if (touchEndX < touchStartX) {
      if (this.visibleImageIndex >= this.get('images.length') - 1) return;

      this.incrementProperty('visibleImageIndex');
    }

    if (touchEndX > touchStartX) {
      if (this.visibleImageIndex <= 0) return;

      this.decrementProperty('visibleImageIndex');
    }
  },
  /* eslint-enable complexity */

  actions: {
    goToIndex(index) {
      this.set('visibleImageIndex', index);
    }
  }
});
