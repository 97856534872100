import { handleActions } from 'redux-actions';
import {
  createArtistPending,
  createArtistSuccess,
  createArtistFailure,
  setFormValue,
  resetForm
} from 'gigshq/actions/artist-creation-tool';

const INITIAL_STATE = {
  form: {
    name: '',
    contactEmail: ''
  },
  isDirty: false,
  isPending: false,
  data: null,
  errors: null
};

export default handleActions(
  {
    [createArtistPending]: state => ({
      ...state,
      isPending: true,
      data: null,
      errors: null
    }),

    [createArtistSuccess]: (state, {payload}) => ({
      ...state,
      isDirty: false,
      isPending: false,
      data: payload.result.artist,
      errors: null
    }),

    [createArtistFailure]: (state, {payload}) => ({
      ...state,
      isPending: false,
      data: null,
      errors: payload.error
    }),

    [resetForm]: () => ({
      ...INITIAL_STATE
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      isDirty: true,
      form: {
        ...state.form,
        [payload.name]: payload.value
      }
    })
  },
  INITIAL_STATE
);
