import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { Ability } from 'ember-can';

export default Ability.extend({
  accountManager: service('managers/account-manager'),

  venueIsNotDeleted: computed('model.deletedAt', function() {
    return this.get('model.deletedAt') == null;
  }),

  canCreate: computed(
    'accountManager.isUserTypeTenant',
    function() {
      return this.get('accountManager.isUserTypeTenant');
    }
  ),

  canEdit: computed(
    'accountManager.id',
    function() {
      return this.get('accountManager.id') === this.get('model.userId')
        || this.accountManager.isAlainOrKevin;
    }
  ),

  canView: readOnly('venueIsNotDeleted'),

});
