import fetch from 'fetch';
import Cookies from 'js-cookie';
import config from 'gigshq/config/environment';

export default {
  async destroy(session) {
    return fetch(config.API.REVOKE_AUTHENTICATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.token}`
      }
    }).finally(() => Cookies.remove(config.Session.STORAGE_KEY));
  }
};
