import gql from 'graphql-tag';
import userFields from '../fragments/user-fields';

export default userRole => {
  if (userRole === 'Visitor') {
    return gql`
      query WebSearch ($searchQuery: String!, $favouriteVenues: [Int]) {
        viewer {
          ... on Visitor {
            artists(searchQuery: $searchQuery) {
              id
              name
              slug
            }

            publicVenues(searchQuery: $searchQuery, favouriteVenues: $favouriteVenues) {
              id
              name
              slug
              city
              province
              latitude
              longitude
              formattedAddress
              timezone
            }

            publicEventsSearch(searchQuery: $searchQuery) {
              id
              name
              slug
              startedAt
              doorPriceCents
              ticketPriceCents
              cancelledAt

              venue {
                id
                name
                slug
                city
                province
                latitude
                longitude
                timezone
              }
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query WebSearch ($searchQuery: String!, $favouriteVenues: [Int]) {
        viewer {
          ... on ${userRole} {
            ...userFields

            artists(searchQuery: $searchQuery) {
              id
              name
              slug
            }

            publicVenues(searchQuery: $searchQuery, favouriteVenues: $favouriteVenues) {
              id
              name
              slug
              city
              province
              latitude
              longitude
              formattedAddress
              timezone
            }

            publicEventsSearch(searchQuery: $searchQuery) {
              id
              name
              slug
              startedAt
              doorPriceCents
              ticketPriceCents
              cancelledAt

              venue {
                id
                name
                slug
                city
                province
                latitude
                longitude
                timezone
              }
            }
          }
        }
      }

      ${userFields}
    `;
  }
};
