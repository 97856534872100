import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';

// Actions
import {
  saveVenue,
  resetForm,
  setFormValue,
  setFormSocialProfileValue
} from 'gigshq/actions/venues';

const mapStateToComputed = state => {
  const {
    venues: {
      venueCreation: {
        isDirty,
        isPending,
        data,
        errors,
        form: {
          name,
          description,
          streetAddress,
          city,
          country,
          postalCode,
          formattedAddress,
          phoneNumber,
          email,
          latitude,
          longitude,
          capacityCount,
          venueCategoryId,
          socialProfile,
          openingHours
        }
      }
    }
  } = state;

  const id = state.venues.venueCreation.data
    ? state.venues.venueCreation.data.id
    : null;

  return {
    isDirty,
    isPending,
    data,
    errors,
    id,
    name,
    description,
    streetAddress,
    city,
    country,
    postalCode,
    formattedAddress,
    phoneNumber,
    email,
    latitude,
    longitude,
    capacityCount,
    venueCategoryId,
    socialProfile,
    openingHours
  };
};

const mapDispatchToActions = dispatch => ({
  saveVenue: (mutate, venue) =>
    dispatch(saveVenue({form: 'venueCreation', mutate, venue})),
  resetForm: () => dispatch(resetForm({form: 'venueCreation'})),
  setFormValue: (name, value) =>
    dispatch(setFormValue({form: 'venueCreation', name, value})),
  setFormSocialProfileValue: (name, value) =>
    dispatch(setFormSocialProfileValue({form: 'venueCreation', name, value}))
});

const VenueCreationForm = ReduxContainer.extend({
  propTypes: {
    venueCategories: T.array.isRequired,
    onVenueCreate: T.func.isRequired
  },

  apollo: service(),

  willDestroyElement() {
    this.get('actions.resetForm')();
  },

  actions: {
    create() {
      const properties = this.getProperties(
        'name',
        'description',
        'streetAddress',
        'city',
        'country',
        'postalCode',
        'formattedAddress',
        'phoneNumber',
        'email',
        'latitude',
        'longitude',
        'capacityCount',
        'venueCategoryId',
        'socialProfile',
        'openingHours'
      );

      const variables = {...properties};

      this.get('actions.saveVenue')(
        this.apollo.mutationOperation(),
        variables
      ).then(result => {
        const id = result && result.venue && result.venue.id;
        if (!id) return;

        this.onVenueCreate(id);
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(VenueCreationForm);
