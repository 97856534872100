import { handleActions } from 'redux-actions';
import {
  saveEventPending,
  saveEventSuccess,
  saveEventFailure,
  saveEventPhotoOrderPending,
  saveEventPhotoOrderSuccess,
  saveEventPhotoOrderFailure,
  deleteImagePending,
  deleteImageSuccess,
  deleteImageFailure,
  setFormValue,
  setStartedAt,
  setVenue,
  resetForm,
  fillForm,
  addGig,
  removeGig,
  updateGig,
  updateGigAtIndex,
  refreshGig,
  reloadCropsSuccess,
  markFormAsUploading
} from 'gigshq/actions/events';
import moment from 'moment';
import { sortByDate } from 'gigshq/utils/array';

const GIG_DEFAULT_START_TIME = 19;

const EVENT_FORM_INITIAL_STATE = {
  saveEventPhotoOrderMutation: {
    isPending: false,
    errors: null
  },

  deleteImageMutation: {
    isPending: false,
    errors: null
  },

  form: {
    startedAt: '',
    name: '',
    description: '',
    slug: '',
    ticketPriceCents: null,
    doorPriceCents: null,
    ticketPurchaseUrl: '',
    gigs: [],
    wideCrop: {
      id: '',
      imageUrl: '',
      image: {
        id: '',
        imageUrl: ''
      }
    },
    squareCrop: {
      id: '',
      imageUrl: '',
      image: {
        id: '',
        imageUrl: ''
      }
    },
    venue: {
      id: '',
      slug: ''
    }
  },
  isDirty: false,
  isPending: false,
  isUploading: false,
  data: null,
  errors: null
};

const INITIAL_STATE = {
  eventCreation: EVENT_FORM_INITIAL_STATE,
  eventEdition: EVENT_FORM_INITIAL_STATE
};

export default handleActions(
  {
    [saveEventPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveEventSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isPending: false,
        data: payload.result.event,
        errors: null
      }
    }),

    [saveEventFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [saveEventPhotoOrderPending]: state => ({
      ...state,
      saveEventPhotoOrderMutation: {
        isPending: true,
        errors: null
      }
    }),

    [saveEventPhotoOrderSuccess]: state => ({
      ...state,
      saveEventPhotoOrderMutation: {
        isPending: false,
        errors: null
      }
    }),

    [saveEventPhotoOrderFailure]: (state, {payload}) => ({
      ...state,
      saveEventPhotoOrderMutation: {
        isPending: false,
        errors: payload
      }
    }),

    [deleteImagePending]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: true,
        errors: null
      }
    }),

    [deleteImageSuccess]: state => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: null
      }
    }),

    [deleteImageFailure]: (state, {payload}) => ({
      ...state,
      deleteImageMutation: {
        isPending: false,
        errors: payload
      }
    }),

    [resetForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...INITIAL_STATE[payload.form]
      }
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        form: {
          ...state[payload.form].form,
          [payload.name]: payload.value
        }
      }
    }),

    [setStartedAt]: (state, {payload}) => {
      const newGigs = state[payload.form].form.gigs.map(gig => {
        const gigDate = moment(gig.startedAt);

        const startedAt = moment(payload.startedAt)
          .hours(gigDate.hours())
          .minutes(gigDate.minutes())
          .startOf('minute')
          .toISOString();

        return {
          ...gig,
          startedAt
        };
      });

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            startedAt: payload.startedAt,
            gigs: newGigs
          }
        }
      };
    },

    [setVenue]: (state, {payload}) => {
      const venue = state[payload.form].form.venue;
      const newVenue = payload.venue;

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            venue: {
              ...venue,
              ...newVenue
            }
          }
        }
      };
    },

    [addGig]: (state, {payload}) => {
      const gigs = state[payload.form].form.gigs;
      const artistId = payload.gig.artist.id;

      if (artistId && gigs.find(gig => gig.artist.id === artistId))
        return state;

      const lastGig = gigs[gigs.length - 1];

      const venue = state[payload.form].form.venue;

      const startedAt = lastGig
        ? moment(lastGig.startedAt)
            .add(1, 'hour')
            .toISOString()
        : moment.tz(payload.gig.startedAtDate, venue.timezone)
            .hours(GIG_DEFAULT_START_TIME)
            .startOf('hour')
            .toISOString();

      const newGigs = gigs.concat({startedAt, ...payload.gig});

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            gigs: newGigs
          }
        }
      };
    },

    [removeGig]: (state, {payload}) => {
      const gigs = state[payload.form].form.gigs;
      const artistId = payload.gig.artist.id;

      const index = gigs.findIndex(gig => gig.artist.id === artistId);

      if (index < 0) return state;

      const newGigs = [...gigs.slice(0, index), ...gigs.slice(index + 1)];

      const newOrderedGigs = sortByDate(newGigs, 'startedAt');
      const venue = state[payload.form].form.venue;

      let startedAt = null;
      if (newOrderedGigs.length) {
        const [{startedAt: firstGigStartedAt}] = newOrderedGigs;
        startedAt = moment(firstGigStartedAt).toISOString();
      } else
        startedAt = moment().tz(venue)
          .hours(GIG_DEFAULT_START_TIME)
          .startOf('hour')
          .toISOString();

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            startedAt,
            gigs: newOrderedGigs
          }
        }
      };
    },

    [updateGig]: (state, {payload}) => {
      const gigs = state[payload.form].form.gigs;
      const artistId = payload.gig.artist.id;
      const venue = state[payload.form].form.venue;

      const index = gigs.findIndex(gig => gig.artist.id === artistId);

      if (index < 0) return state;

      const newGigs = [
        ...state[payload.form].form.gigs.slice(0, index),
        {...gigs[index], ...payload.gig},
        ...state[payload.form].form.gigs.slice(index + 1)
      ];

      const newOrderedGigs = sortByDate(newGigs, 'startedAt');

      const [{startedAt: firstGigStartedAt}] = newOrderedGigs;
      const startedAt = moment(firstGigStartedAt).tz(venue.timezone).toISOString();

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            startedAt,
            gigs: newOrderedGigs
          }
        }
      };
    },

    [updateGigAtIndex]: (state, {payload}) => {
      const gigs = state[payload.form].form.gigs;
      const index = payload.index;

      const newGigs = [
        ...state[payload.form].form.gigs.slice(0, index),
        {...gigs[index], ...payload.gig},
        ...state[payload.form].form.gigs.slice(index + 1)
      ];

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            gigs: sortByDate(newGigs, 'startedAt')
          }
        }
      };
    },

    [refreshGig]: (state, {payload}) => {
      const gigs = state[payload.form].form.gigs;
      const artistId = payload.gig.artist.id;

      const index = gigs.findIndex(gig => gig.artist.id === artistId);

      if (index < 0) return state;

      const newGigs = [
        ...state[payload.form].form.gigs.slice(0, index),
        {...gigs[index], ...payload.gig},
        ...state[payload.form].form.gigs.slice(index + 1)
      ];

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          form: {
            ...state[payload.form].form,
            gigs: newGigs
          }
        }
      };
    },

    [fillForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: payload.values
      }
    }),

    [reloadCropsSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: {
          ...state[payload.form].form,
          wideCrop: payload.result.wideCrop,
          squareCrop: payload.result.squareCrop
        }
      }
    }),

    [markFormAsUploading]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isUploading: payload.isUploading
      }
    })
  },
  INITIAL_STATE
);
