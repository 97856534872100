import Component from '@ember/component';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import mapStyles from 'gigshq/constants/map-styles';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { getDistance } from 'geolib';

const EXPEDIA_PARTNERIZE_REFERRAL_URL = 'https://prf.hn/click/camref:1011lrdgb/destination:';
const GEOLOCATION_MARKER_PRECISION = 4;
const GEOLOCATION_CONFLICT_ADJUSTMENT = 0.0001;

const MAP_OPTIONS = {
  styles: mapStyles,
  backgroundColor: '#1f1f1f',
  zoom: 16,
  clickableIcons: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  keyboardShortcuts: false,
  mapTypeControl: false,
  signInControl: false,
  streetViewControl: false,
};

const CUSTOM_CLUSTERER_STYLES = [{
  width: 30,
  height: 30,
  className: 'custom-clustericon-1'
},
{
  width: 20,
  height: 20,
  className: 'custom-clustericon-2'
},
{
  width: 50,
  height: 50,
  className: 'custom-clustericon-3'
},
{
  width: 60,
  height: 60,
  className: 'custom-clustericon-4'
},
{
  width: 70,
  height: 70,
  className: 'custom-clustericon-5'
}];

export default Component.extend(Localizable(translations), {
  mapCenter: computed('venue', function () {
    return Promise.resolve(this.venue)
      .then((result) => {
        return {
          latitude: result.latitude,
          longitude: result.longitude
        };
      });
  }).volatile(),

  renderMapTask: task(function* () {
    yield this.renderMap(yield this.mapCenter);
  }).restartable(),

  didInsertElement() {
    this.get('renderMapTask').perform();
  },

  willDestroyElement() {
    this.destroyMarkers();
  },

  renderMap(coordinates) {
    const center = new window.google.maps.LatLng(
      coordinates.latitude,
      coordinates.longitude
    );

    const element = this.$('.gigs-hotel-listings__map').get(0);

    const map = new window.google.maps.Map(element, {
      ...MAP_OPTIONS,
      center
    });

    const zoomListener = map.addListener('zoom_changed', () =>
      this.updateHotelsInView()
    );
    const centerListener = map.addListener('center_changed', () =>
      this.updateHotelsInView()
    );


    this.set('map', map);
    this.refreshMarkers();

    return;
  },

  centerMap(coordinates) {
    this.map.setCenter(coordinates);
  },

  updateHotelsInView() {
    const hotels = [];
    for (let i=0; i < this.venue.nearbyHotels.length; i++) {
      if (this.map.getBounds().contains(this.venue.nearbyHotels[i].position))
        hotels.push(this.venue.nearbyHotels[i]);
    }
    const sortedHotels = hotels.sort((hotelA, hotelB) => hotelA.name.localeCompare(hotelB.name));
    this.set('hotelsInView', sortedHotels);
  },

  async renderMarkers() {
    const venueMarker = this._drawVenueMarker();
    this._resolveHotelLocationConflicts();
    let hotelsNearby = this.venue.nearbyHotels;

    if (this.hotelMarkers) {
      hotelsNearby = hotelsNearby.filter((hotel) => {
        return !this.hotelMarkers.find(marker => marker.hotel.id === hotel.id);
      });
    }

    const eventDate = this.eventDate ? moment(this.eventDate) : moment().add(1, 'day');
    const expediaUrl = this.venue.country.toUpperCase() === 'CA' || this.venue.country.toUpperCase() === 'CANADA'
      ? 'expedia.ca'
      : 'expedia.com';

    const newMarkers = hotelsNearby.map(hotel => {
      const position = new window.google.maps.LatLng(hotel.latitude, hotel.longitude);

      const marker = new MarkerWithLabel({
        position,
        clickable: true,
        optimized: false,
        map: this.map,
        icon: {
          anchor: new window.google.maps.Point(-10, 56),
          size: new window.google.maps.Size(51.666666667, 56),
          scaledSize: new window.google.maps.Size(51.666666667, 56),
          url: `${window.location.protocol}//${window.location.host}/assets/images/img-map-pin-expedia2.png`
        },
        labelContent:
          ((hotel.name.length > 16) ? `${hotel.name.substring(0, 16)}...` : hotel.name) +
          ((hotel.price) ? `<br/><span class="gigs-hotel-listings__marker-label__price">${hotel.price}</span>` : ''),
        labelAnchor: new google.maps.Point(-14, 0),
        labelClass: "gigs-hotel-listings__marker-label", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });

      hotel.position = position;
      hotel.distanceFromVenue = (getDistance(
        { latitude: hotel.latitude, longitude: hotel.longitude },
        { latitude: this.venue.latitude, longitude: this.venue.longitude }) / 1000.0).toFixed(1);
      hotel.link = EXPEDIA_PARTNERIZE_REFERRAL_URL
        + `https://${expediaUrl}/h${hotel.id}.Hotel-Information?`
        + `startDate=${eventDate.format('YYYY-MM-DD')}`
        + `&endDate=${eventDate.add(1, 'day').format('YYYY-MM-DD')}`;


      marker.addListener('click', () => {
        window.open(hotel.link, '_blank');
      });

      return { marker, hotel };
    });

    if (this.hotelMarkers) {
      newMarkers.forEach(m => {
        if (this.hotelMarkers.indexOf(m) < 0)
          this.hotelMarkers.push(m);
      });
    } else {
      this.set('hotelMarkers', newMarkers);
      let markerClusterer = new MarkerClusterer(this.map,
        this.hotelMarkers.map(m => m.marker),
        {
          averageCenter: true,
          minimumClusterSize: this.hotelMarkers.length < 400 ? 5 : 2,
          clusterClass: 'custom-clustericon',
          styles: CUSTOM_CLUSTERER_STYLES,
          gridSize: 80,
          ignoreHidden: true
        });

      markerClusterer.setCalculator(function (hotelMarkers, maxIndex) {
        return {
          text: hotelMarkers.length,
          index: 1
        }
      });
      this.set('markerClusterer', markerClusterer);
    }

    this.set('hotelMarkers',
      this.hotelMarkers.map(m => {
        if (this.markerClusterer.getMarkers().indexOf(m.marker) < 0)
            this.markerClusterer.addMarker(m.marker, true);

        return m;
      })
    );

    // ensure map shows the closest 5 hotels to the venue
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(venueMarker.position);

    for (let i=0; i < this.hotelMarkers.length && i < 5; i++)
      bounds.extend(this.hotelMarkers[i].marker.position);

    this.map.fitBounds(bounds);

    this.markerClusterer.setMinimumClusterSize(this.hotelMarkers.length < 400 ? 5 : 2);
    this.markerClusterer.repaint();
  },

  _drawVenueMarker() {
    const position = new window.google.maps.LatLng(this.venue.latitude, this.venue.longitude);

    return new window.google.maps.Marker({
      clickable: false,
      optimized: false,
      icon: {
        anchor: new window.google.maps.Point(14, 56),
        size: new window.google.maps.Size(51.666666667, 56),
        scaledSize: new window.google.maps.Size(51.666666667, 56),
        url: `${window.location.protocol}//${window.location.host}/assets/images/img-map-pin.png`
      },
      label: {
        text: (this.venue.name.length > 16) ? `${this.venue.name.substring(0, 16)}...` : this.venue.name,
        className: 'gigs-hotel-listings__marker-label',
        color: 'white',
        fontSize: '14px'
      },
      map: this.map,
      position,
    });
  },

  _resolveHotelLocationConflicts() {
    const mappedHotels = [];

    this.venue.nearbyHotels.forEach(hotel => {
      let hotelPlaced = false;

      while (!hotelPlaced) {
        const position = `${hotel.latitude.toFixed(GEOLOCATION_MARKER_PRECISION)}:${hotel.longitude.toFixed(GEOLOCATION_MARKER_PRECISION)}`;

        if (mappedHotels[position]) {
          // another hotel has the same position, alter it slightly
          hotel.latitude += GEOLOCATION_CONFLICT_ADJUSTMENT;
          hotel.longitude += GEOLOCATION_CONFLICT_ADJUSTMENT;
        } else {
          mappedHotels[position] = hotel;
          hotelPlaced = true;
        }
      }
    });
  },

  destroyMarkers() {
    const mapBounds = this.map.getBounds();
    if (mapBounds && this.hotelMarkers) {
      const markersToKeep = [];

      this.hotelMarkers.forEach(marker => {
        if (marker.listener) {
          marker.listener.remove();
        }
        marker.marker.setMap(null);
        this.markerClusterer.removeMarker(marker.marker, true);
      }, this);

      this.set('hotelMarkers', markersToKeep.length > 0 ? markersToKeep : null);
      this.markerClusterer.repaint();
    }
  },

  refreshMarkers() {
    this.destroyMarkers();
    this.renderMarkers();
  },
});
