import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import S3Uploader from 'ember-uploader/uploaders/s3';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import configValue from 'gigshq/computed-macros/config-value';
import ImageTypes from 'gigshq/constants/image-types';

// Mutations
import createImageMutation from 'gigshq/graphql/mutations/create-image';

const THUMBNAIL_WIDTH = 184;
const THUMBNAIL_HEIGHT = 138;

export default Component.extend(Localizable(translations), {
  propTypes: {
    artistId: T.nullable(T.string).isRequired,
    file: T.instanceOf(File).isRequired,
    selectedImage: T.nullable(T.string).isRequired,
    selectImage: T.func.isRequired,
    removeImage: T.func.isRequired
  },

  apollo: service(),
  sessionFetcher: service('fetchers/session-fetcher'),
  signingUrl: configValue('API.IMAGE_SIGNING_URL'),
  flashMessages: service('flash-messages'),

  classNameBindings: ['isSelected'],

  isSelected: computed('selectedImage.id', 'image.id', function() {
    if (!this.get('image.id')) return false;

    return this.get('selectedImage.id') === this.get('image.id');
  }),

  isPending: true,
  progress: 0,

  progressStyles: computed('progress', function() {
    return htmlSafe(`width: ${this.progress}%;`);
  }),

  didInsertElement() {
    const session = this.sessionFetcher.fetch();

    const uploader = S3Uploader.create({
      signingUrl: this.signingUrl,
      signingMethod: 'POST',

      signingAjaxSettings: {
        headers: {Authorization: `Bearer ${session.token}`}
      }
    });

    this.set('uploader', uploader);

    this.startUpload();
  },

  willDestroyElement() {
    this.uploader.destroy();
  },

  actions: {
    abortUpload() {
      this.uploader.abort();

      this.removeImage();
    }
  },

  startUpload() {
    const {file, uploader} = this;

    uploader.upload(file).catch(() => {
      // Do nothing, ember-uploader rejects the promise
      // when aborting, we don’t care about that
    });

    uploader.on('progress', event => {
      this.set('progress', event.percent);
    });

    uploader.on('didUpload', event => {
      this.didUpload(event);
    });

    uploader.on('didError', () => {
      this.flashMessages.error(
        this.localTranslation('errors.could_not_upload')
      );
    });
  },

  didUpload(event) {
    this.set('isPending', false);

    const imageUrl = $(event).find('Location')[0].textContent;

    this.set('imageUrl', imageUrl);

    this.getImageSize(imageUrl).then(imageSize => {
      const mutation = createImageMutation;
      const artistId = this.artistId;
      const imageType = artistId
        ? ImageTypes.ARTIST_PHOTO
        : ImageTypes.USER_PHOTO;

      const variables = {
        artistId,
        imageUrl,
        imageSize,
        imageType,
        thumbnailImageWidth: THUMBNAIL_WIDTH,
        thumbnailImageHeight: THUMBNAIL_HEIGHT
      };

      return this.apollo
        .mutationOperation()({mutation, variables}, 'createImage')
        .then(result => {
          this.set('image', result.image);
        });
    });
  },

  getImageSize(imageUrl) {
    return new RSVP.Promise(resolve => {
      const image = new Image();

      image.onload = () => resolve({width: image.width, height: image.height});

      image.src = imageUrl;
    });
  }
});
