import { bool, not } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import ErrorFormatter from 'gigshq/utils/error-formatter';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    phoneNumber: T.nullable(T.string),
    countryCode: T.nullable(T.number),
    countryCodes: T.array.isRequired,
    publish: T.func.isRequired,
    toggleCaptionToolIsOpened: T.func.isRequired
  },

  characterCountLimit: 160,
  instagramMessage: '',

  isReadyForPublication: bool('event.shareableProfile.square'),
  notReadyForPublication: not('isReadyForPublication'),

  mascotImagePath: computed('isReadyForPublication', function() {
    if (!this.isReadyForPublication) {
      return AssetsCatalog.LargeGigiStates.PERPLEX.asset;
    }

    return AssetsCatalog.LargeGigiStates.NEUTRAL.asset;
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  instagramImage: computed(
    'event.shareableProfile.{square,instagram}',
    function() {
      return (
        this.get('event.shareableProfile.instagram') ||
        this.get('event.shareableProfile.square')
      );
    }
  ),

  selectedCountryCode: computed('countryCode', function() {
    return this.countryCodes.find(
      countryCode => countryCode.value === this.countryCode
    );
  }),

  buttonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.publishing')
      : this.localTranslation('buttons.publish');
  }),

  actions: {
    selectCountryCode(countryCode) {
      this.set('countryCode', countryCode.value);
    },

    setInstagramMessage(message) {
      this.set('instagramMessage', message);
    },

    setPhoneNumber(phoneNumber) {
      this.set('phoneNumber', phoneNumber);
    },

    handlePublish() {
      this.set('isPending', true);

      this.publish(
        this.instagramMessage,
        this.phoneNumber,
        this.get('selectedCountryCode.value')
      )
        .then(errors => this.set('errors', errors))
        .finally(() => this.set('isPending', false));
    }
  }
});
