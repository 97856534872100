import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import S3Uploader from 'ember-uploader/uploaders/s3';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import configValue from 'gigshq/computed-macros/config-value';
import ImageTypes from 'gigshq/constants/image-types';

// Mutations
import createImageMutation from 'gigshq/graphql/mutations/create-image';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venueId: T.string.isRequired,
    image: T.nullable(T.object).isRequired,
    label: T.oneOfType([T.string, T.safeString]).isRequired,
    didCreateImage: T.func.isRequired
  },

  apollo: service(),
  flashMessages: service('flash-messages'),

  signingUrl: configValue('API.IMAGE_SIGNING_URL'),

  isPending: false,
  progress: 0,
  uploader: null,

  progressStyles: computed('progress', function() {
    return htmlSafe(`width: ${this.progress}%;`);
  }),

  didInsertElement() {
    this.createUploader();
  },

  createUploader() {
    const uploader = S3Uploader.create({
      signingUrl: this.signingUrl,
      signingMethod: 'POST'
    });

    this.set('uploader', uploader);
  },

  didUpload(event) {
    this.set('isPending', false);

    const imageUrl = $(event).find('Location')[0].textContent;

    this.getImageSize(imageUrl)
      .then(imageSize => {
        const mutation = createImageMutation;
        const variables = {
          imageUrl,
          imageSize,
          venueId: this.venueId,
          imageType: ImageTypes.VENUE_PROFILE,
          thumbnailImageWidth: imageSize.width,
          thumbnailImageHeight: imageSize.height
        };

        return this.apollo
          .mutationOperation()({mutation, variables}, 'createImage')
          .then(result => {
            this.didCreateImage(result.image);
            this.createUploader();
          });
      })
      .catch(() => this.showUploadError());
  },

  getImageSize(imageUrl) {
    return new RSVP.Promise(resolve => {
      const image = new Image();

      image.onload = () => resolve({width: image.width, height: image.height});

      image.src = imageUrl;
    });
  },

  showUploadError() {
    this.flashMessages.error(this.localTranslation('errors.could_not_upload'));
  },

  actions: {
    didReceiveFile(files) {
      this.setProperties({
        isPending: true,
        progress: 0
      });

      const uploader = this.uploader;

      uploader.upload(files[0]);

      uploader.on('progress', event => {
        this.set('progress', event.percent);
      });

      uploader.on('didError', () => {
        this.showUploadError();
      });

      uploader.on('didUpload', event => {
        this.didUpload(event);
      });
    }
  }
});
