import Component from '@ember/component';

export default Component.extend({
  actions: {
    updateOpeningDay(openingDay) {
      const openingHours = this.openingHours.map(openingHour => {
        return openingDay.day === openingHour.day ? openingDay : openingHour;
      });

      this.update(openingHours);
    }
  }
});
