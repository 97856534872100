/* global FastBoot */

import config from 'gigshq/config/environment';

export const initialize = () => {
  if (typeof FastBoot !== 'undefined') return;

  window.fbAsyncInit = function() {
    FB.init({
      appId: config.Facebook.APP_ID,
      xfbml: false,
      version: 'v3.2'
    });
  };
};

export default {
  name: 'facebook-sdk',
  initialize
};
