import { handleActions } from 'redux-actions';
import {
  loadArtistImagesPending,
  loadArtistImagesSuccess,
  loadArtistImagesFailure,
  loadUserImagesPending,
  loadUserImagesSuccess,
  loadUserImagesFailure,
  selectImage,
  createCropPending,
  createCropSuccess,
  createCropFailure,
  goToNextStep,
  resetImageSelection,
  resetCrops
} from 'gigshq/actions/crop-tool';

const INITIAL_STATE = {
  artistImageQuery: {
    isPending: false,
    data: null,
    errors: null
  },

  userImageQuery: {
    isPending: false,
    data: null,
    errors: null
  },

  createCropMutation: {
    isPending: false,
    data: null,
    errors: null
  },

  crops: {},

  step: 1,
  selectedImage: null
};

export default handleActions(
  {
    [loadArtistImagesPending]: state => ({
      ...state,
      artistImageQuery: {
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [loadArtistImagesSuccess]: (state, {payload}) => ({
      ...state,
      artistImageQuery: {
        isPending: false,
        data: payload,
        errors: null
      }
    }),

    [loadArtistImagesFailure]: (state, {payload}) => ({
      ...state,
      artistImageQuery: {
        isPending: false,
        data: null,
        errors: payload
      }
    }),

    [loadUserImagesPending]: state => ({
      ...state,
      userImageQuery: {
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [loadUserImagesSuccess]: (state, {payload}) => ({
      ...state,
      userImageQuery: {
        isPending: false,
        data: payload,
        errors: null
      }
    }),

    [loadUserImagesFailure]: (state, {payload}) => ({
      ...state,
      userImageQuery: {
        isPending: false,
        data: null,
        errors: payload
      }
    }),

    [selectImage]: (state, {payload}) => ({
      ...state,
      selectedImage: payload
    }),

    [goToNextStep]: state => ({
      ...state,
      step: state.step + 1
    }),

    [createCropPending]: state => ({
      ...state,
      createCropMutation: {
        isPending: true,
        data: null,
        error: null
      }
    }),

    [createCropSuccess]: (state, {payload}) => ({
      ...state,
      createCropMutation: {
        isPending: false,
        data: payload,
        error: null
      },
      crops: {
        ...state.crops,
        [payload.crop.type]: payload.crop
      }
    }),

    [createCropFailure]: (state, {payload}) => ({
      ...state,
      createCropMutation: {
        isPending: false,
        data: null,
        error: payload
      }
    }),

    [resetCrops]: state => ({
      ...state,
      step: state.selectedImage ? 2 : 1,
      crops: INITIAL_STATE.crops
    }),

    [resetImageSelection]: state => ({
      ...state,
      step: 1,
      selectedImage: null,
      crops: INITIAL_STATE.crops
    })
  },
  INITIAL_STATE
);
