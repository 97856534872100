define("gigshq/graphql/queries/artist-genres", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ArtistGenres"
      },
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "genres"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "orderParams"
                    },
                    "value": {
                      "kind": "ListValue",
                      "values": [{
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "attribute"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "name"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "order"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "ASC"
                          }
                        }]
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 188,
      "source": {
        "body": "\n  query ArtistGenres {\n    viewer {\n      ... on User {\n        id\n\n        genres(orderParams: [{attribute: name, order: ASC}]) {\n          id\n          name\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
