import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'time',
  attributeBindings: ['scheduledAt:datetime'],
  classNameBindings: ['isPast'],

  isPast: computed('scheduledAt', function() {
    return moment().isAfter(this.scheduledAt);
  })
});
