import { inject as service } from '@ember/service';
import { bool, and, or } from '@ember/object/computed';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { sortByDate } from 'gigshq/utils/array';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    event: T.object.isRequired,
    onDuplicateClick: T.func.isRequired,
    onDeleteClick: T.func.isRequired,
    onCancelClick: T.func.isRequired,
    onNotificateClick: T.func.isRequired
  },

  accountManager: service('managers/account-manager'),
  can: service(),
  media: service(),

  tagName: 'article',
  classNameBindings: ['cancelled'],

  cancelled: bool('event.cancelledAt'),

  isPastEvent: computed('event.startedAt', function() {
    return moment(this.event.startedAt).isBefore(moment().startOf('day'));
  }),

  shouldShowModifyOptions: computed('isPastEvent', 'event.publishedAt', function() {
    return !this.isPastEvent || this.event.publishedAt == null;
  }),

  popoverSide: computed('media.isMobile', function() {
    return this.media.isMobile ? 'left' : 'bottom';
  }),

  cannotEditEvent: computed('event', function() {
    return !this.can.can('edit event', this.event);
  }),

  cannotPublishEvent: computed('event', function() {
    return !this.can.can('publish event', this.event);
  }),

  canViewPublicEvent: computed('event', function() {
    return this.can.can('view public event', this.event);
  }),

  canOnlyViewEvent: and('cannotEditEvent', 'cannotPublishEvent', 'canViewPublicEvent'),

  showPublicPage: computed('canOnlyViewEvent', 'shouldShowModifyOptions', function() {
    return this.canOnlyViewEvent || !this.shouldShowModifyOptions;
  }),

  creatorByLabel: computed('accountManager.id', 'event.creator', function() {
    const userId = this.get('accountManager.id');
    const {id, firstName, lastName} = this.get('event.creator');

    if (userId === id) return this.localTranslation('created_by_me');

    return this.localTranslation('created_by', {
      creator: `${firstName} ${lastName}`
    });
  }),

  viewButtonText: computed('event.publishedAt', function() {
    if (this.get('event.publishedAt'))
      return this.localTranslation('actions.view');
    return this.localTranslation('actions.preview');
  }),

  imageStyle: computed('event.squareCrop.thumbnailImageUrl', function() {
    if (this.event.squareCrop && this.event.squareCrop.thumbnailImageUrl)
      return htmlSafe(`background-image: url('${this.event.squareCrop.thumbnailImageUrl}')`);
    else if (this.event.shareableProfile && this.event.shareableProfile.squareThumbnail)
      return htmlSafe(`background-image: url('${this.event.shareableProfile.squareThumbnail}')`);
  }),

  sortedGigs: computed('event.gigs', function() {
    return sortByDate(this.get('event.gigs'), 'startedAt');
  })
});
