import { readOnly, bool } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'form',

  propTypes: {
    artistId: T.string,
    artistImageQuery: T.shape({
      isPending: T.bool.isRequired,
      errors: T.nullable(T.array).isRequired,
      data: T.nullable(T.object).isRequired
    }).isRequired,
    errors: T.nullable(T.array).isRequired,
    name: T.string.isRequired,
    genres: T.array.isRequired,
    availableGenres: T.array.isRequired,
    profileImage: T.nullable(T.object).isRequired,
    description: T.string,
    contactEmail: T.string,
    socialProfile: T.object.isRequired,
    setProperty: T.func.isRequired,
    setSocialProfileProperty: T.func.isRequired,
    updateGenres: T.func.isRequired,
    markFormAsUploading: T.func.isRequired,
    deleteImage: T.func.isRequired
  },

  images: [],
  pendingUploadCount: 0,
  descriptionCharacterCountLimit: 500,
  socialProfileNetworks: [
    'website',
    'facebook',
    'twitter',
    'instagram',
    'spotify',
    'bandcamp',
    'itunes',
    'soundcloud',
    'playMusic',
    'tidal',
    'youtube'
  ],

  isPending: readOnly('artistImageQuery.isPending'),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  canUploadImages: bool('artistId'),
  shouldDisplayArtistIdBadge: bool('artistId'),
  shouldDisplayArtistProfilePictureUploader: bool('artistId'),

  actions: {
    didCreateProfileImage(image) {
      this.setProperty('profileImage', {
        id: image.id,
        imageUrl: image.thumbnailImageUrl
      });
    },

    didCompleteUpload() {
      this.decrementProperty('pendingUploadCount');
      this.markFormAsUploading(this.pendingUploadCount > 0);
    },

    didStartUpload() {
      this.incrementProperty('pendingUploadCount');
      this.markFormAsUploading(true);
    }
  }
});
