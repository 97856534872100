import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

const MICRODATA_WEEKDAYS = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday'
};

export default Component.extend(Localizable(translations), {
  hasOpenDays: computed('openingHours.[]', function() {
    return this.openingHours.some((day) => !day.closed);
  }),

  openingDays: computed('openingHours.[]', function() {
    return this.openingHours.map(hour => ({
      ...hour,
      microDataDay: `http://schema.org/${MICRODATA_WEEKDAYS[hour.day]}`
    }));
  })
});
