import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    source: T.string.isRequired,
    fillMode: T.string
  },

  attributeBindings: ['style'],

  style: computed('source', function() {
    const style = [
      `background-image: url('${this.source}');`,
      'background-repeat: no-repeat;',
      'background-position: center;'
    ];

    if (this.fillMode) {
      style.push(`background-size: ${this.fillMode};`);
    }

    return htmlSafe(style.join(''));
  }),

  click() {
    if (this.action) this.action();
  }
});
