import { handleActions } from 'redux-actions';
import {
  cancelEventPending,
  cancelEventSuccess,
  cancelEventFailure,
  setFormValue,
  resetForm
} from 'gigshq/actions/cancel-event';

const INITIAL_STATE = {
  form: {
    cancellationReason: ''
  },
  isDirty: false,
  isPending: false,
  data: null,
  errors: null
};

export default handleActions(
  {
    [cancelEventPending]: state => ({
      ...state,
      isPending: true,
      data: null,
      errors: null
    }),

    [cancelEventSuccess]: (state, {payload}) => ({
      ...state,
      isDirty: false,
      isPending: false,
      data: payload.result.event,
      errors: null
    }),

    [cancelEventFailure]: (state, {payload}) => ({
      ...state,
      isPending: false,
      data: null,
      errors: payload.error
    }),

    [resetForm]: () => ({
      ...INITIAL_STATE
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      isDirty: true,
      form: {
        ...state.form,
        [payload.name]: payload.value
      }
    })
  },
  INITIAL_STATE
);
