import { lte } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    inputValue: T.string.isRequired,
    limit: T.number.isRequired,
    label: T.oneOfType([T.string, T.safeString]).isRequired
  },

  classNameBindings: ['limitReached:warning'],
  limitReached: lte('remainingCount', 0),

  remainingCount: computed('limit', 'inputValue.length', function() {
    return this.limit - (this.get('inputValue.length') || 0);
  })
});
