import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isLoggingIn: T.bool.isRequired,
    session: T.nullable(T.object).isRequired,
    error: T.nullable(T.object).isRequired,
    login: T.func.isRequired,
    onLoginSuccess: T.func.isRequired
  },

  didReceiveAttrs() {
    if (this.session) {
      this.onLoginSuccess();
    }
  },

  actions: {
    updateEmail(email) {
      this.set('email', email);
    },

    updatePassword(password) {
      this.set('password', password);
    },

    submit() {
      this.login(this.getProperties('email', 'password'));
    }
  }
});
