import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Controller from '@ember/controller';

export default Controller.extend({
  localeManager: service('managers/locale-manager'),
  router: service(),
  i18n: service(),

  mailchimpModalIsOpen: false,

  alternateLocale: computed('i18n.locale', function() {
    return this.i18n.locale === 'en' ? 'fr' : 'en';
  }),

  actions: {
    changeLocale(locale = this.alternateLocale, skipReload = false) {
      this.localeManager.setLocale(locale, { persist: true });

      if (!skipReload)
        window.location.reload();
    },

    onLogoutSuccess() {
      this.router.transitionTo('web');

      if (this.router.currentRouteName.indexOf('web') >= 0)
        window.location.reload();
    },
  }
});
