import Component from '@ember/component';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  webpageIconPath: AssetsCatalog.WebsiteFeatures.WEB_PAGE.asset,
  editorIconPath: AssetsCatalog.WebsiteFeatures.EDITOR.asset,
  sharingIconPath: AssetsCatalog.WebsiteFeatures.SHARING.asset,
  remindersIconPath: AssetsCatalog.WebsiteFeatures.REMINDERS.asset,
  posterIconPath: AssetsCatalog.WebsiteFeatures.POSTER.asset
});
