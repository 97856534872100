import { inject as service } from '@ember/service';
import Controller, {inject as controller} from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Controller.extend(Localizable(translations), {
  flashMessages: service('flash-messages'),
  eventPublisher: service('publishers/event-publisher'),
  publishController: controller('app/events/publish'),

  countryCodes: [
    {
      value: 1,
      label: 'Canada +1'
    }
  ],

  actions: {
    sendToggleCaptionToolIsOpened() {
      this.publishController.send('toggleCaptionToolIsOpened');
    },

    changeTab(tab) {
      this.transitionToRoute(
        `app.events.publish.${tab}`,
        this.get('model.event.id')
      );
    },

    publish(message, phoneNumber, countryCode) {
      const variables = {
        network: PublishingNetworks.INSTAGRAM.name,
        message,
        phoneNumber,
        countryCode,
        eventId: this.get('model.event.id')
      };

      return this.eventPublisher.publish(variables).then(({errors}) => {
        if (!errors) {
          this.send('refreshModel');
        }

        const message = this.localTranslation(
          `errors.${errors[0].messages[0]}`
        );
        this.flashMessages.error(message);

        return errors;
      });
    }
  }
});
