import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import LocationableStyleable from 'gigshq/mixins/components/locationable-styleable';

const MAX_RANGE = '2 week';

export default Component.extend(Localizable(translations), LocationableStyleable, {
  propTypes: {
    fromDate: T.nullable(T.string),
    toDate: T.nullable(T.string),
    minDate: T.nullable(T.string),
    rangeOptional: T.bool,
    setDateRange: T.func,
  },

  maxRange: computed(() => MAX_RANGE),
  incompleteRange: null,
  center: reads('fromDate'),

  range: computed(
    'incompleteRange',
    'fromDate',
    'toDate',
    function() {
      if (this.incompleteRange) return this.incompleteRange;

      if (!this.fromDate && !this.toDate) return null;

      return {
        start: moment(this.fromDate),
        end: moment(this.toDate)
      };
    }
  ),

  formattedRange: computed('incompleteRange', 'range', function() {
    const range = this.incompleteRange || this.range;

    if (!range) return this.localTranslation('calendar');

    return `${moment(range.start).format(
      'D MMM'
    )} - ${range.end ? moment(range.end).format('D MMM') : ''}`;
  }),

  canClearRange: computed.and('range', 'rangeOptional'),

  toggleDatePicker(toggleFunction) {
    toggleFunction();

    this.set('center', this.range ? this.range.start : this.minDate);
  },

  clearDateRangeFilter(event) {
    event.stopPropagation();

    this.setDateRange({fromDate: null, toDate: null});
  },

  selectDateRange(range) {
    if (!range.start || !range.end) {
      this.set('incompleteRange', range);

      return;
    }

    const fromDate = range.start.format('YYYY-MM-DD');
    const toDate = range.end.format('YYYY-MM-DD');
    this.setDateRange({fromDate, toDate});

    this.set('incompleteRange', null);
  },
});
