import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    title: T.string.isRequired
  },

  tagName: 'h1'
});
