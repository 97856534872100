import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { underscore } from '@ember/string';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

const NETWORK_IMAGES = {
  facebook: 'wide',
  instagram: 'square'
};

export default Controller.extend(Localizable(translations), {
  apollo: service(),
  router: service(),

  captionToolIsOpened: false,

  network: computed('router.currentRouteName', function() {
    const [_match, networkName] = this.get('router.currentRouteName').match(
      /app\.events\.publish\.([a-z\-]+)(\..*)?/
    );
    const network = PublishingNetworks[underscore(networkName).toUpperCase()];

    const activeNetworkFullName = network
      ? networkName
      : PublishingNetworks.GHQ.name;

    return activeNetworkFullName.split('-')[0];
  }),

  image: computed(
    'model.event.shareableProfile.{wide,square}',
    'network',
    function() {
      return this.get(
        `model.event.shareableProfile.${NETWORK_IMAGES[this.network]}`
      );
    }
  ),

  actions: {
    captionedImageWasCreated() {
      this.send('refreshModel');
    },

    toggleCaptionToolIsOpened() {
      this.toggleProperty('captionToolIsOpened');
    },

    closeCaptionTool() {
      this.set('captionToolIsOpened', false);
    }
  }
});
