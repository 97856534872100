import RSVP from 'rsvp';
import moment from 'moment';

export default {
  isLoggedIn() {
    const forceRefresh = true;

    return new RSVP.Promise(resolve => {
      window.FB.getLoginStatus(response => {
        resolve(response.status === 'connected');
      }, forceRefresh);
    });
  },

  login(options) {
    return new RSVP.Promise(resolve => {
      window.FB.login(response => {
        if (response.status === 'connected') {
          // Logged into your app and Facebook.
          resolve(true);
        } else if (response.status === 'not_authorized') {
          // The person is logged into Facebook, but not your app.
          resolve(false);
        } else {
          // The person is not logged into Facebook, so we're not sure if
          // they are logged into this app or not.
          resolve(false);
        }
      }, options);
    });
  },

  logout() {
    return new RSVP.Promise(resolve => {
      window.FB.logout(response => {
        console.log('FB logout', response);
      });
    });
  },

  fetchPermissions() {
    return new RSVP.Promise((resolve, reject) => {
      window.FB.api('/me/permissions', response => {
        if (!response || response.error)
          return reject(response && response.error);

        resolve(response.data);
      });
    });
  },

  fetchUser() {
    return new RSVP.Promise((resolve, reject) => {
      window.FB.api('/me?fields=name,link', response => {
        if (!response || response.error)
          return reject(response && response.error);

        resolve(response);
      });
    });
  },

  fetchAccounts() {
    return new RSVP.Promise((resolve, reject) => {
      window.FB.api(
        '/me/accounts?fields=access_token,name,id,picture{url}',
        response => {
          if (!response || response.error)
            return reject(response && response.error);

          const accounts = response.data.map(account => ({
            id: account.id,
            name: account.name,
            accessToken: account.access_token,
            pictureUrl: account.picture.data.url
          }));

          resolve(accounts);
        }
      );
    });
  },

  fetchEventsForPageAndDate(pageId, date) {
    return new RSVP.Promise((resolve, reject) => {
      // Facebook requires a Unix timestamp which is the number of *seconds* since Epoch
      const since = parseInt(
        moment(date)
          .startOf('day')
          .valueOf() / 1000,
        10
      );
      const until = parseInt(
        moment(date)
          .endOf('day')
          .valueOf() / 1000,
        10
      );

      const limit = 100;
      const fields = 'id,name,start_time.order(reverse_chronological)';

      window.FB.api(
        `/${pageId}/events?since=${since}&until=${until}&limit=${limit}&fields=${fields}`,
        response => {
          if (!response || response.error)
            return reject(response && response.error);

          const filteredEvents = response.data.filter(event =>
            moment(event.start_time).isSame(date, 'day')
          );

          const events = filteredEvents.map(event => ({
            id: event.id,
            name: event.name,
            startTime: event.start_time
          }));

          resolve(events);
        }
      );
    });
  }
};
