import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    network: T.string.isRequired,
    networkUrl: T.string.isRequired
  },

  iconPath: computed('network', function() {
    return `icn-network-${this.network.toLowerCase()}-on`;
  })
});
