import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import pagination from 'gigshq/constants/pagination';
import moment from 'moment';
import RSVP from 'rsvp';

// Queries
import publishedEventListingQuery from 'gigshq/graphql/queries/published-event-listing';
import unpublishedEventListingQuery from 'gigshq/graphql/queries/unpublished-event-listing';

export default Route.extend({
  queryParams: {
    fromDate: {
      refreshModel: true,
      replace: true
    },
    toDate: {
      refreshModel: true,
      replace: true
    },
    filterByPublished: {
      refreshModel: true
    },
    selectedVenueId: {
      refreshModel: true
    }
  },

  apollo: service(),

  async model(params) {
    this.controllerFor('app.events.index').set('isLoading', true);

    const fromDate = params.fromDate
      ? moment(params.fromDate).startOf('day')
      : null;

    const toDate = params.toDate
      ? moment(params.toDate).endOf('day')
      : null;

    const filterByPublished = params.filterByPublished;

    const venueId = params.selectedVenueId;

    const eventsViewer = await this._fetchEvents({
      venueId,
      fromDate,
      toDate,
      filterByPublished
    });

    return {
      events: eventsViewer.events,
      toDate,
      fromDate
    };
  },

  setupController(controller, model) {
    this._super(controller, model);

    controller.setProperties({
      fromDate:
        controller.filterByPublished && model.fromDate
          ? model.fromDate.format('YYYY-MM-DD')
          : null,
      toDate:
        controller.filterByPublished && model.toDate
          ? model.toDate.format('YYYY-MM-DD')
          : null,
      isLoading: false,
      offset: pagination.INITIAL_OFFSET,
      areAllEventsLoaded: false
    });
  },

  _fetchEvents({fromDate, toDate, filterByPublished, venueId}) {
    const query = filterByPublished
      ? publishedEventListingQuery
      : unpublishedEventListingQuery;

    const variables = {
      offset: 0,
      limit: pagination.EVENTS_PER_PAGE,
      filters: this._buildFilters({
        filterByPublished,
        fromDate,
        toDate,
        venueId
      })
    };

    return this.apollo.queryOperation()(
      {
        query,
        variables
      },
      'viewer'
    );
  },

  // eslint-disable-next-line complexity
  _buildFilters({filterByPublished, fromDate, toDate, venueId}) {
    if (!filterByPublished) return [];

    const filters = [{scope: 'with_published'}];

    if (venueId) {
      filters.push({
        scope: 'for_venue_ids',
        arguments: [venueId]
      });
    }

    if (!fromDate && !toDate) {
      filters.push({
        scope: 'started_after_or_at',
        arguments: [
          moment()
            .startOf('day')
            .toISOString()
        ]
      });

      return filters;
    }

    if (fromDate) {
      filters.push({
        scope: 'started_after_or_at',
        arguments: [
          moment(fromDate)
            .startOf('day')
            .toISOString()
        ]
      });
    }

    if (toDate) {
      filters.push({
        scope: 'started_before_or_at',
        arguments: [
          moment(toDate)
            .endOf('day')
            .toISOString()
        ]
      });
    }

    return filters;
  }
});
