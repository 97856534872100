import { combineReducers } from 'redux';

import artistCreationTool from './artist-creation-tool';
import artists from './artists';
import cancelEvent from './cancel-event';
import captionTool from './caption-tool';
import cropTool from './crop-tool';
import duplicateEvent from './duplicate-event';
import events from './events';
import offers from './offers';
import offerRedeem from './offer-redeem';
import passwordReset from './password-reset';
import session from './session';
import users from './users';
import venues from './venues';

export default combineReducers({
  artistCreationTool,
  artists,
  cancelEvent,
  captionTool,
  cropTool,
  duplicateEvent,
  events,
  offers,
  offerRedeem,
  passwordReset,
  session,
  users,
  venues
});
