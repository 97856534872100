import { createAction } from 'redux-actions';
import viewerTypeQuery from 'gigshq/graphql/queries/viewer-type';
import SessionCreator from 'gigshq/utils/session-creator';
import SessionPersister from 'gigshq/utils/session-persister';
import SessionDestroyer from 'gigshq/utils/session-destroyer';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import userCreateMutation from 'gigshq/graphql/mutations/user-create';

export const loginPending = createAction('LOGIN_PENDING');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');

export const login = ({executeQuery, email, password}) => async dispatch => {
  dispatch(loginPending());

  try {
    let session = await SessionCreator.create({email, password});

    await SessionPersister.persist(session);

    // We need to persist the session *before* this call
    // because the apollo-client uses the session to authenticate the call
    const {isAdmin, locale} = await executeQuery(
      {query: viewerTypeQuery},
      'viewer'
    );
    session = {...session, isAdmin, locale};

    await SessionPersister.persist(session);

    dispatch(loginSuccess(session));
  } catch (error) {
    dispatch(loginFailure(error));
  }
};

export const setSession = createAction('SET_SESSION');

export const logoutPending = createAction('LOGOUT_PENDING');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');

export const logout = (clearStore, session) => async dispatch => {
  dispatch(logoutPending());

  try {
    await SessionDestroyer.destroy(session);

    dispatch(setSession(null));
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFailure(error));
  } finally {
    await clearStore();
  }
};

export const signUpPending = createAction('SIGN_UP_PENDING');
export const signUpSuccess = createAction('SIGN_UP_SUCCESS');
export const signUpFailure = createAction('SIGN_UP_FAILURE');

export const signUp = (executeQuery, mutate, user) => async dispatch => {
  dispatch(signUpPending());

  try {
    const mutation = userCreateMutation;
    const {email, password} = user;
    const variables = GraphQLVariablesBuilder.build(mutation, user);

    const result = await mutate({mutation, variables}, 'userCreate');

    if (result.errors) return dispatch(signUpFailure(result.errors));

    await dispatch(login({executeQuery, email, password}));

    dispatch(signUpSuccess());
    dispatch(signUpReset());
  } catch (error) {
    dispatch(signUpFailure(error));
  }
};

export const signUpReset = createAction('SIGN_UP_RESET');
