import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    day: T.object.isRequired,
    maxDate: T.string.isRequired
  },

  classNameBindings: ['isMaxDate', 'isSelected', 'isDisabled'],

  isMaxDate: computed('day.moment', 'maxDate', function() {
    return this.get('day.moment').isSame(this.maxDate, 'day');
  }),

  isSelected: readOnly('day.isSelected'),
  isDisabled: readOnly('day.isDisabled')
});
