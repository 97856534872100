import Controller from '@ember/controller';
import { notEmpty } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  queryParams: {
    venueId: 'venue'
  },

  showAsVenueHistory: notEmpty('venueId'),
});
