import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import config from 'gigshq/config/environment';

export default Route.extend({
  fastboot: service(),

  beforeModel() {
    if (this.get('fastboot.isFastBoot'))
      return;

    if (window.google && window.google.maps)
      return;

    return new RSVP.Promise((resolve, reject) => {
      $.getScript(config.Google.PLACES_SDK_SCRIPT_URL)
        .done(resolve)
        .fail(reject);
    });
  }
});
