/* eslint-disable complexity */

export const calculateWideRatio = ({
  width,
  height,
  ratioWidth,
  ratioHeight,
  maxWidth,
  maxHeight,
  overflow
}) => {
  if (maxWidth && width > maxWidth) width = maxWidth;
  if (maxHeight && height > maxHeight) height = maxHeight;

  const sizes = {
    width,
    height: (width * ratioHeight) / ratioWidth
  };

  if (overflow && sizes.height < height) {
    return calculateTallRatio({
      width,
      height,
      ratioWidth,
      ratioHeight,
      overflow
    });
  }

  if (!overflow && sizes.height > height) {
    return calculateTallRatio({
      width,
      height,
      ratioWidth,
      ratioHeight,
      maxWidth,
      maxHeight
    });
  }

  return sizes;
};

export const calculateTallRatio = ({
  width,
  height,
  ratioWidth,
  ratioHeight,
  maxWidth,
  maxHeight,
  overflow
}) => {
  if (maxWidth && width > maxWidth) width = maxWidth;
  if (maxHeight && height > maxHeight) height = maxHeight;

  const sizes = {
    height,
    width: (height * ratioWidth) / ratioHeight
  };

  if (overflow && sizes.width < width) {
    return calculateWideRatio({
      width,
      height,
      ratioWidth,
      ratioHeight,
      overflow
    });
  }

  if (!overflow && sizes.width > width) {
    return calculateWideRatio({
      width,
      height,
      ratioWidth,
      ratioHeight,
      maxWidth,
      maxHeight
    });
  }

  return sizes;
};
