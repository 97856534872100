import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    artists: T.array.isRequired,
    artistImageQuery: T.nullable(T.object).isRequired,
    allowVenueUpload: T.bool,
    venueId: T.string,
    userId: T.string.isRequired,
    userImageQuery: T.nullable(T.object).isRequired,
    createCropMutation: T.nullable(T.object).isRequired,
    selectedImage: T.nullable(T.object).isRequired,
    loadArtistImages: T.func.isRequired,
    loadUserImages: T.func.isRequired,
    close: T.func.isRequired,
    finish: T.func.isRequired,
    selectImage: T.func.isRequired,
    createCrop: T.func.isRequired,
    goToNextStep: T.func.isRequired,
    resetImageSelection: T.func.isRequired
  },

  steps: computed('step', function() {
    const step = this.step;

    /* eslint-disable no-magic-numbers */
    return [
      {isActive: step === 1, label: this.localTranslation('step_1')},
      {isActive: step === 2, label: this.localTranslation('step_2')},
      {isActive: step === 3, label: this.localTranslation('step_3')}
    ];
    /* eslint-enable no-magic-numbers */
  }),

  stepComponent: computed('step', function() {
    return `gigs-crop-tool/step-${this.step}`;
  })
});
