import { reads } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  internalValue: reads('value'),

  init() {
    this._super(...arguments);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  },

  didInsertElement() {
    const input = this.$('.google-places-autocomplete-input input').get(0);

    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: [ 'address' ]
    });

    const placeListener = window.google.maps.event.addListener(
      autocomplete,
      'place_changed',
      this.onPlaceChanged
    );

    this.setProperties({ autocomplete, placeListener, input });
  },

  willDestroyElement() {
    this.placeListener.remove();
  },

  onPlaceChanged() {
    const RELEVANT_ADDRESS_COMPONENT_TYPES = [
      'street_number',
      'route',
      'locality',
      'country',
      'postal_code',
      'sublocality_level_1'
    ];

    const {
      address_components: addressComponents,
      geometry: { location }
    } = this.autocomplete.getPlace();

    const relevantComponents = addressComponents
      .filter(component =>
        RELEVANT_ADDRESS_COMPONENT_TYPES.includes(component.types[0])
      )
      .reduce(
        (addressObject, component) => ({
          ...addressObject,
          [component.types[0]]: component.long_name
        }),
        {}
      );

    const city = relevantComponents.locality
      ? relevantComponents.locality
      : relevantComponents.sublocality_level_1;

    const address = {
      streetAddress: `${relevantComponents.street_number} ${
        relevantComponents.route
      }`,
      city,
      country: relevantComponents.country,
      postalCode: relevantComponents.postal_code,
      latitude: parseFloat(location.lat()),
      longitude: parseFloat(location.lng()),
      formattedAddress: this.input.value
    };

    this.update(address);
  },

  actions: {
    setValue(value) {
      this.set('internalValue', value);
      this.update({});
    }
  }
});
