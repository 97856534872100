import { createAction } from 'redux-actions';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';
import persistEventMutation from 'gigshq/graphql/mutations/persist-event';
import eventReloadCrops from 'gigshq/graphql/queries/event-reload-crops';
import saveEventPhotoOrderMutation from 'gigshq/graphql/mutations/persist-event-photo-order';
import deleteImageMutation from 'gigshq/graphql/mutations/delete-image';

export const saveEventPending = createAction('EVENTS_SAVE_EVENT_PENDING');
export const saveEventSuccess = createAction('EVENTS_SAVE_EVENT_SUCCESS');
export const saveEventFailure = createAction('EVENTS_SAVE_EVENT_FAILURE');

export const saveEvent = ({form, mutate, event}) => async dispatch => {
  dispatch(saveEventPending({form}));

  try {
    const mutation = persistEventMutation;
    const variables = GraphQLVariablesBuilder.build(mutation, event);

    const result = await mutate({mutation, variables}, 'persistEvent');

    dispatch(saveEventSuccess({form, result}));

    return result;
  } catch (error) {
    dispatch(saveEventFailure({form, error}));
  }
};

export const reloadCropsSuccess = createAction('EVENTS_RELOAD_CROPS_SUCCESS');

export const reloadCrops = ({
  form,
  executeQuery,
  variables
}) => async dispatch => {
  try {
    const query = eventReloadCrops;

    const result = await executeQuery({query, variables}, 'viewer');

    dispatch(reloadCropsSuccess({form, result}));
  } catch (error) {
    // Do nothing, it doesn’t matter if the user don’t see
    // their crop at this point.
  }
};

export const setFormValue = createAction('EVENTS_SET_FORM_VALUE');
export const setStartedAt = createAction('EVENT_SET_STARTED_AT');
export const setVenue = createAction('EVENT_SET_VENUE');
export const resetForm = createAction('EVENTS_RESET_FORM');
export const fillForm = createAction('EVENTS_FILL_FORM');

export const addGig = createAction('EVENTS_ADD_GIG');
export const removeGig = createAction('EVENTS_REMOVE_GIG');
export const updateGig = createAction('EVENTS_UPDATE_GIG');
export const updateGigAtIndex = createAction('EVENTS_UPDATE_GIG_AT_INDEX');
export const refreshGig = createAction('EVENTS_REFRESH_GIG');

export const markFormAsUploading = createAction(
  'EVENTS_MARK_FORM_AS_UPLOADING'
);

export const saveEventPhotoOrderPending = createAction('EVENTS_SAVE_PHOTO_ORDER_PENDING');
export const saveEventPhotoOrderSuccess = createAction('EVENTS_SAVE_PHOTO_ORDER_SUCCESS');
export const saveEventPhotoOrderFailure = createAction('EVENTS_SAVE_PHOTO_ORDER_FAILURE');

export const saveEventPhotoOrder = ({mutate, imageIds}) => async dispatch => {
  dispatch(saveEventPhotoOrderPending());

  const mutation = saveEventPhotoOrderMutation;
  const variables = {imageIds};

  try {
    await mutate(
      {mutation, variables},
      'saveEventPhotoOrder'
    );

    dispatch(saveEventPhotoOrderSuccess());
  } catch (error) {
    dispatch(saveEventPhotoOrderFailure(error));
  }
};

export const deleteImagePending = createAction('EVENTS_DELETE_IMAGE_PENDING');
export const deleteImageSuccess = createAction('EVENTS_DELETE_IMAGE_SUCCESS');
export const deleteImageFailure = createAction('EVENTS_DELETE_IMAGE_FAILURE');

export const deleteImage = ({mutate, imageId}) => async dispatch => {
  dispatch(deleteImagePending());

  const mutation = deleteImageMutation;
  const variables = {imageId};

  try {
    await mutate(
      {mutation, variables, refetchQueries: ['EventImages']},
      'deleteImage'
    );

    dispatch(deleteImageSuccess());
  } catch (error) {
    dispatch(deleteImageFailure(error));
  }
};
