import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import config from 'gigshq/config/environment';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Controller.extend({
  i18n: service(),
  sessionFetcher: service('fetchers/session-fetcher'),
  eventPublisher: service('publishers/event-publisher'),

  actions: {
    publish(format) {
      const variables = {
        network: PublishingNetworks.PRINT.name,
        eventId: this.get('model.event.id')
      };

      const token = this.sessionFetcher.fetch().token;
      const locale = this.get('i18n.locale');

      const url = `${config.API.POSTER_PRINT_URL}/${
        variables.eventId
      }?size=${format}&token=${token}&locale=${locale}`;

      window.open(url);

      return this.eventPublisher.publish(variables).then(({errors}) => {
        if (!errors) {
          this.send('refreshModel');
        }

        return errors;
      });
    }
  }
});
