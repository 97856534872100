import Controller from '@ember/controller';
import { bool, notEmpty } from '@ember/object/computed';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  isCancelled: bool('model.event.cancelledAt'),
  hasEventPhotos: notEmpty('model.event.photos'),
  hasHotelsNearby: notEmpty('model.event.venue.nearbyHotels'),
});
