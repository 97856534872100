import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  router: service(),

  tagName: 'button',
  attributeBindings: ['type', 'disabled'],
  classNameBindings: ['appearance'],

  propTypes: {
    label: T.oneOfType([T.string, T.safeString]),
    iconPath: T.string,
    appearance: T.string,
    route: T.string,
    query: T.array,
    onClick: T.func
  },

  click() {
    if (this.onClick)
      this.onClick();

    if (this.route) {
      const queryParams = this.query;
      this.router.transitionTo(this.route, { queryParams });
    }
  },

  type: 'button',
  disabled: false
});
