import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import { inject as service } from '@ember/service';
import moment from 'moment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { computed } from '@ember/object';
import { empty, notEmpty } from '@ember/object/computed';
import LocationableStyleable from 'gigshq/mixins/components/locationable-styleable';
import { task, timeout } from 'ember-concurrency';
import config from 'gigshq/config/environment';
import { getDistance } from 'geolib';

const MIN_SEARCH_QUERY_LENGTH = 2;

export default Component.extend(Localizable(translations), LocationableStyleable, {
  propTypes: {
    fromDate: T.nullable(T.string),
    toDate: T.nullable(T.string),
    eventsCount: T.number,
    userLocation: T.nullable(
      T.shape({
        latitude: T.number.isRequired,
        longitude: T.number.isRequired
      })
    ),
    rangeOptional: T.bool,
    eventCountByVenue: T.object,
    setDateRange: T.func,
  },

  isPerformingSearch: false,

  favouriteVenuesManager: service('managers/favourite-venues-manager'),
  searchFetcher: service('fetchers/search-fetcher'),
  media: service(),

  searchValue: '',
  isEmptySearch: empty('searchValue'),
  showEventCount: notEmpty('eventsCount'),
  expandSearchBar: false,
  minDateForDateSelector: moment().startOf('day'), // today

  showDateSelector: computed('setDateRange', 'expandSearchBar', function() {
    return this.setDateRange != null && !this.expandSearchBar;
  }),

  venueDropdownHorizontalPosition: computed('media.isMobile', function() {
    return this.media.isMobile ? 'right' : 'left';
  }),

  searchEntities: task(function*(value) {
    this.set('searchValue', value);
    if (value.length <= MIN_SEARCH_QUERY_LENGTH) return;

    yield timeout(config.APP.SEARCH_QUERY_KEY_DELAY);
    this.set('isPerformingSearch', true);
    yield this.searchFetcher.search(value, this.userLocation);
    this.set('isPerformingSearch', false);
  }).restartable(),

  mappedVenues: computed('venues.[]', 'userLocation', function() {
    if (!this.venues) return [];

    return this.venues.map(venue => {
      const distanceFromUser = this.userLocation
        ? getDistance(
          { latitude: this.userLocation.latitude, longitude: this.userLocation.longitude },
          { latitude: venue.latitude, longitude: venue.longitude }) / 1000.0
        : null;

      const distanceFromUserInWords = this.userLocation
        ? `${distanceFromUser.toFixed(2)} ${this.localTranslation('km')}`
        : '';

      return {
        ...venue,
        distanceFromUser,
        distanceFromUserInWords
      };
    });
  }),

  sortedVenues: computed('mappedVenues.[]', 'userLocation', function() {
    const generateValues = this.userLocation
      ? (venueA, venueB) => ({
          valueA: venueA.distanceFromUser,
          valueB: venueB.distanceFromUser
        })
      : (venueA, venueB) => ({
          valueA: venueA.name.toLowerCase(),
          valueB: venueB.name.toLowerCase()
        });

    return this.mappedVenues.sort((venueA, venueB) => {
      const {valueA, valueB} = generateValues(venueA, venueB);

      if (valueA === valueB) return 0;
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    });
  }),

  groupedVenues: computed(
    'sortedVenues.[]',
    'eventCountByVenue',
    'favouriteVenuesManager.favouriteVenueIds.[]',
    function() {
      return this.sortedVenues.reduce(
        (memo, venue) => {
          if (
            this.favouriteVenuesManager.favouriteVenueIds.includes(venue.id)
          ) {
            memo.isInFavourites.push(venue);
          } else if (this.eventCountByVenue.hasOwnProperty(venue.id)) {
            memo.hasEvents.push(venue);
          } else {
            memo.hasNoEvents.push(venue);
          }

          return memo;
        },
        {isInFavourites: [], hasEvents: [], hasNoEvents: []}
      );
    }
  ),

  showDropdown(dropdown) {
    dropdown.actions.toggle();

    if (this.media.isMobile)
      this.set('expandSearchBar', true);
  },

  closeDropdown(dropdown) {
    this.set('expandSearchBar', false);
    this.set('searchTerm', '');

    dropdown.actions.close();
    this.searchFetcher.clearSearch();
  },
});
