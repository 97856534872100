import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';

export default Component.extend(Localizable(translations), {
  propTypes: {
    login: T.func.isRequired
  },

  mascotImagePath: AssetsCatalog.LargeGigiStates.PERPLEX.asset,

  buttonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.connecting')
      : this.localTranslation('buttons.connect');
  }),

  loginTask: task(function*() {
    this.set('isPending', true);

    yield this.login();

    this.set('isPending', false);
  }).drop(),

  actions: {
    handleLogin() {
      this.loginTask.perform();
    }
  }
});
