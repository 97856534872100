import { computed } from '@ember/object';
const CURRENCY_FORMAT_REGEX = /^([0-9]+)?\.?[0-9]{0,2}$/;

export default function(centsProperty, update) {
  let priceString = '';

  return computed(centsProperty, {
    get() {
      if (!this.get(centsProperty)) return '';

      const priceInDollars = parseFloat(this.get(centsProperty)) / 100;

      return String(priceInDollars);
    },

    set(_key, value) {
      if (CURRENCY_FORMAT_REGEX.test(value)) {
        const priceCents = parseInt(parseFloat(value) * 100, 10);

        update.call(this, priceCents);
        priceString = value;

        return value;
      }

      return priceString;
    }
  });
}
