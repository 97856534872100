import { inject as service } from '@ember/service';
import { notEmpty, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';
import { PropTypes as T } from 'ember-prop-types';
import dataURLToBlob from 'dataurl-to-blob';
import fabric from 'fabric';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

const TEXT_FONT_FAMILY = 'Lato';
const GIGS_ACCENT_COLOR = '#feaf1b';
const CANVAS_REDRAW_THROTTLING_DELAY = 50;
const DEFAULT_TEXT_ALIGN = 'LEFT';

export default Component.extend(Localizable(translations), {
  propTypes: {
    image: T.string.isRequired,
    textfields: T.array.isRequired,
    network: T.string.isRequired,
    width: T.number.isRequired,
    height: T.number.isRequired,
    resetTextfields: T.func.isRequired,
    addTextfield: T.func.isRequired,
    updateTextfield: T.func.isRequired,
    removeTextfield: T.func.isRequired,
    persistImage: T.func.isRequired,
    close: T.func.isRequired
  },

  flashMessages: service('flash-messages'),

  imageOpacity: 0.5,
  imageOutputSize: computed('width', 'height', function() {
    return {width: this.width * 2, height: this.height * 2};
  }),

  selectedTextfield: null,

  isShowingPopover: notEmpty('selectedTextfield'),
  popoverPosition: {top: 0, left: 0},
  popoverStyles: computed('popoverPosition.{top,left}', function() {
    const {top, left} = this.popoverPosition;

    return htmlSafe(`top: ${top}px; left: ${left}px;`);
  }),

  textAlignmentIndex: 0,
  textAlignmentIcon: readOnly('textAlignment.asset'),
  textAlignments: computed(() => Object.values(AssetsCatalog.TextAlignment)),
  textAlignment: computed(
    'textAlignments.[]',
    'textAlignmentIndex',
    function() {
      return this.textAlignments[this.textAlignmentIndex];
    }
  ),

  cornerSize: computed(() => {
    const touchCornerSize = 25;
    const defaultCornerSize = 10;

    if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
      return touchCornerSize;
    }

    return defaultCornerSize;
  }),

  frameComponent: computed('network', function() {
    return `gigs-caption-tool/${this.network}-frame`;
  }),

  topBarTitle: computed('network', function() {
    return this.localTranslation(`${this.network}.title`);
  }),

  topBarSubtitle: computed('network', function() {
    return this.localTranslation(`${this.network}.subtitle`);
  }),

  didReceiveAttrs() {
    const textfields = this.textfields;

    const textfieldsToRender = textfields.filter(
      textfield => !textfield.fabricTextfield
    );

    if (textfieldsToRender.length) {
      textfieldsToRender.forEach(textfield => this.renderTextField(textfield));

      this.redrawCanvas();
    }
  },

  didInsertElement() {
    const {width, height} = this;

    const canvas = new fabric.Canvas(this.$('.gigs-caption-tool__canvas')[0], {
      width,
      height,
      selection: false,
      backgrounColor: '#000'
    });

    this.set('canvas', canvas);

    this.renderImage();

    this.setupCanvasListeners();
  },

  willDestroyElement() {
    this.teardownCanvasListeners();
  },

  setupCanvasListeners() {
    const canvas = this.canvas;

    canvas.on('object:selected', ({target}) => {
      this.set('selectedTextfield', target);
      this.positionPopoverOverTarget(target);
      this.setTextFieldAlignment(target);
    });

    canvas.on('selection:cleared', () => {
      this.set('selectedTextfield', null);
    });

    canvas.on('object:moving', ({target}) => {
      run(this, 'positionPopoverOverTarget', target);
    });

    canvas.on('object:scaling', ({target}) => {
      run(this, 'positionPopoverOverTarget', target);
    });

    // Events related to input of text are executed on the next
    // run-loop because when the event is fired the element has not
    // yet been redrawn so its size isn’t up-to-date

    // This event is fired when the user selects
    // a bunch of text and deletes it
    canvas.on('text:changed', ({target}) => {
      run.next(this, 'positionPopoverOverTarget', target);
    });

    // This event is fired when text is entered or deleted
    // one character at a time
    canvas.on('text:selection:changed', ({target}) => {
      run.next(this, 'positionPopoverOverTarget', target);
    });
  },

  teardownCanvasListeners() {
    this.canvas.dispose();
  },

  positionPopoverOverTarget({top, left, width, scaleX}) {
    const centerX = left + (width * scaleX) / 2;

    this.set('popoverPosition', {top, left: centerX});
  },

  renderImage() {
    const {canvas, image, imageOpacity, width, height} = this;

    const imageUrl = image.replace('https://', 'http://');

    const imageOptions = {
      width,
      height,
      src: imageUrl,
      originX: 'left',
      originY: 'top',
      top: 0,
      left: 0,
      opacity: imageOpacity,
      crossOrigin: 'anonymous'
    };

    fabric.Image.fromObject(imageOptions, image => {
      this.set('backgroundImage', image);

      canvas.setBackgroundImage(image, () => this.redrawCanvas());
    });
  },

  renderTextField(textfield) {
    const fabricTextfield = new fabric.IText('Text', {
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 40,
      lineHeight: 1,
      // Disable rotation
      hasRotatingPoint: false,
      // Redraw the text at every frame when resizing
      // This prevents the text from getting blurry
      noScaleCache: false,
      // Always scale the text proportionally
      lockUniScaling: true,
      // Selection styling
      borderColor: GIGS_ACCENT_COLOR,
      cornerColor: GIGS_ACCENT_COLOR,
      cornerSize: this.cornerSize,
      cornerStyle: 'circle',
      transparentCorners: false,
      // Text edition styling
      editingBorderColor: GIGS_ACCENT_COLOR,
      cursorColor: GIGS_ACCENT_COLOR,
      cursorDuration: 500,
      cursorDelay: 100
    });

    fabricTextfield.setColor('#fff');

    this.canvas.add(fabricTextfield);

    fabricTextfield.viewportCenter();
    fabricTextfield.setCoords();

    run.next(() => this.updateTextfield({...textfield, fabricTextfield}));
  },

  setTextFieldAlignment({textAlign}) {
    const currentAlignment = textAlign
      ? textAlign.toUpperCase()
      : DEFAULT_TEXT_ALIGN;
    const index = this.textAlignments
      .map(alignment => alignment.key)
      .indexOf(currentAlignment);

    this.set('textAlignmentIndex', index);
  },

  redrawCanvas() {
    this.canvas.renderAll();
  },

  convertCanvasToBlob() {
    const exportWidth = this.width * this.canvas.getRetinaScaling();
    const multiplier = this.get('imageOutputSize.width') / exportWidth;

    const dataURL = this.canvas.toDataURL({format: 'jpeg', multiplier});
    return dataURLToBlob(dataURL);
  },

  actions: {
    setImageOpacity(opacity) {
      this.set('imageOpacity', opacity);
      this.backgroundImage.setOpacity(opacity);

      run.throttle(this, 'redrawCanvas', CANVAS_REDRAW_THROTTLING_DELAY, false);
    },

    toggleTextAlignment() {
      if (this.textAlignmentIndex < this.get('textAlignments.length') - 1) {
        this.incrementProperty('textAlignmentIndex');
      } else {
        this.set('textAlignmentIndex', 0);
      }

      this.selectedTextfield.exitEditing();
      this.selectedTextfield.setTextAlign(
        this.get('textAlignment.key').toLowerCase()
      );
      this.redrawCanvas();
    },

    removeSelectedTextfield() {
      const textfieldToRemove = this.textfields.find(
        textfield => textfield.fabricTextfield === this.selectedTextfield
      );

      this.canvas.remove(this.selectedTextfield);
      this.removeTextfield(textfieldToRemove);
    },

    cancel() {
      this.resetTextfields();
      this.close();
    },

    async finishAndClose() {
      let image;

      try {
        image = this.convertCanvasToBlob();
      } catch (_error) {
        this.flashMessages.error(
          this.localTranslation('errors.could_not_convert_image')
        );
        return;
      }

      const size = this.imageOutputSize;

      this.set('isPending', true);

      this.persistImage({image, size})
        .then(() => {
          this.resetTextfields();
          this.close();
        })
        .catch(() =>
          this.flashMessages.error(
            this.localTranslation('errors.could_not_save_image')
          )
        );
    }
  }
});
