import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    name: T.string.isRequired,
    contactEmail: T.string,
    errors: T.nullable(T.array).isRequired,
    setFormValue: T.func.isRequired,
    isPending: T.bool.isRequired,
    save: T.func.isRequired,
    close: T.func.isRequired
  },

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  })
});
