// Vendor
import Service, { inject as service } from '@ember/service';
import moment from 'moment';

// Utils
import isAppStandalone from 'gigshq/utils/is-app-standalone';

// Config
import config from 'gigshq/config/environment';

export default Service.extend({
  cookies: service(),
  fastboot: service(),
  router: service(),

  init() {
    this._super(...arguments);

    this.router.on('routeDidChange', () => {
      this.saveLastURL(this.router.currentURL);
    });
  },

  restoreLastURL() {
    if (this.fastboot.isFastBoot) return;
    if (!isAppStandalone()) return;

    const url = this.cookies.read(
      config.StandaloneAppConfiguration.COOKIE_NAME
    );

    if (!url) return;

    return this.router.replaceWith(url);
  },

  saveLastURL(currentURL) {
    if (this.fastboot.isFastBoot) return;
    if (!isAppStandalone()) return;

    const durationInMinutes =
      config.StandaloneAppConfiguration.COOKIE_DURATION_IN_MINUTES;

    const expires = moment()
      .add(durationInMinutes, 'minutes')
      .toDate();

    this.cookies.write(
      config.StandaloneAppConfiguration.COOKIE_NAME,
      currentURL,
      {path: '/', expires}
    );
  }
});
