import { not } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  classNameBindings: ['isOpened'],

  isOpened: not('openingDay.closed'),

  times: computed(() => {
    const HALF_HOURS_IN_A_DAY = 48;

    return Array(HALF_HOURS_IN_A_DAY)
      .fill(null)
      .map((_item, index) => {
        const hours = parseInt(index / 2, 10);
        const minutes = index % 2 === 0 ? '00' : '30';

        return `${hours}:${minutes}`;
      });
  }),

  actions: {
    toggleIsOpened(isOpened) {
      const openingDay = {
        day: this.get('openingDay.day')
      };

      if (isOpened) {
        openingDay.from = '12:00';
        openingDay.to = '3:00';
      } else {
        openingDay.closed = !isOpened;
      }

      this.update(openingDay);
    },

    setFromTime(from) {
      const openingDay = {...this.openingDay, from};

      this.update(openingDay);
    },

    setToTime(to) {
      const openingDay = {...this.openingDay, to};

      this.update(openingDay);
    }
  }
});
