import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import SessionPersister from 'gigshq/utils/session-persister';
import { connect } from 'ember-redux';

// Actions
import { saveUser, saveMembershipFields, resetForm, setFormValue, setMemberField } from 'gigshq/actions/users';

const mapStateToComputed = state => {
  const {
    users: {
      userEdition: {
        isDirty,
        isPending,
        data,
        errors,
        form: {
          userId,
          firstName,
          lastName,
          email,
          locale,

          member: {
            gender,
            birthdate,
            contactNumber,
            address,
            city,
            province,
            postalCode,
            country,
            promoCode,
            stripeProductId
          }
        }
      }
    }
  } = state;

  return {
    isDirty,
    isPending,
    data,
    errors,
    userId,
    firstName,
    lastName,
    gender,
    birthdate,
    contactNumber,
    email,
    address,
    city,
    province,
    postalCode,
    country,
    promoCode,
    stripeProductId,
    locale
  };
};

const mapDispatchToActions = dispatch => ({
  saveUser: (mutate, user) =>
    dispatch(saveUser({form: 'userEdition', mutate, user})),
  saveMembershipFields: (mutate, user) =>
    dispatch(saveMembershipFields({form: 'userEdition', mutate, user})),
  resetForm: () => dispatch(resetForm({form: 'userEdition'})),
  setFormValue: (name, value) =>
    dispatch(setFormValue({form: 'userEdition', name, value})),
  setMemberField: (name, value) =>
    dispatch(setMemberField({form: 'userEdition', name, value})),

});

const UserEditionForm = ReduxContainer.extend({
  apollo: service(),
  localeManager: service('managers/locale-manager'),
  sessionFetcher: service('fetchers/session-fetcher'),
  stripe: service('stripev3'),
  stripeElement: null,
  stripeError: null,

  propTypes: {
    user: T.object.isRequired,
    onSaveComplete: T.func.isRequired
  },

  willDestroyElement() {
    this.get('actions.resetForm')();
  },

  _saveUserFields() {
    const variables = this.getProperties(
      'userId',
      'firstName',
      'lastName',
      'gender',
      'birthdate',
      'contactNumber',
      'email',
      'address',
      'city',
      'province',
      'postalCode',
      'country',
      'locale'
    );

    this.get('actions.saveUser')(
      this.apollo.mutationOperation(),
      variables
    ).then((result) => {
      if (result.user == null)
        return;

      const session = this.sessionFetcher.fetch();
      session.locale = this.locale;

      SessionPersister.persist(session).then(() => {
        this.localeManager.setLocale(this.locale, {persist: true});
        this.onSaveComplete();
      });
    });
  },

  _savePaymentFields() {
    const variables = this.getProperties(
      'userId',
      'promoCode',
      'stripeProductId',
      'stripeCardToken'
    );

    this.get('actions.saveMembershipFields')(
      this.apollo.mutationOperation(),
      variables
    ).then((result) => {
      if (result.user != null)
        this.onSaveComplete();
    });
  },

  actions: {
    setStripeElement(stripeElement) {
      this.set('stripeElement', stripeElement);
    },

    save() {
      if (this.showMembershipPaymentForm) {
        const stripeElementHidden = document.querySelector('.ember-stripe-card') == null;
        if (stripeElementHidden) {
          this._savePaymentFields();
          return;
        }

        // if member is updating, tokenize the credit card first before proceeding with saving
        this.get('stripe')
          .createToken(this.get('stripeElement'))
          .then((result) => {
            if (result.error) {
              this.set('stripeError', result.error.code);
            } else if (result.token && result.token.id) {
              this.set('stripeError', null);
              this.set('stripeCardToken', result.token.id);
              this._savePaymentFields();
            }
          });
      } else
        this._saveUserFields();
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(UserEditionForm);
