import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venues: T.array.isRequired,
    events: T.array.isRequired,
    toDate: T.string.isRequired,
    fromDate: T.string.isRequired,
    city: T.string,
    selectedVenue: T.nullable(T.object),
    userLocation: T.nullable(
      T.shape({
        latitude: T.number.isRequired,
        longitude: T.number.isRequired
      })
    ),
    mapWithinZoomLimits: T.bool.isRequired,
    onMapCenterChanged: T.func.isRequired
  },

  i18n: service(),
  eventsFetcher: service('fetchers/events-fetcher'),

  eventsByVenue: computed('events.[]', function() {
    return this.eventsFetcher.fetchedEvents.reduce((memo, event) => {
      if (!memo.hasOwnProperty(event.venue.id)) {
        memo[event.venue.id] = [];
      }

      memo[event.venue.id].push(event);

      return memo;
    }, {});
  })
});
