import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import AssetsCatalog from 'gigshq/constants/assets-catalog';

export default Component.extend(Localizable(translations), {
  propTypes: {
    facebookUser: T.object.isRequired,
    refreshAccounts: T.func.isRequired,
    login: T.func.isRequired,
    logout: T.func.isRequired
  },

  mascotImagePath: AssetsCatalog.LargeGigiStates.PERPLEX.asset,

  buttonLabel: computed('isPending', function() {
    return this.isPending
      ? this.localTranslation('buttons.refreshing')
      : this.localTranslation('buttons.refresh_account_list');
  }),

  refreshAccountsTask: task(function*() {
    this.set('isPending', true);

    yield this.refreshAccounts();

    this.set('isPending', false);
  }).drop(),

  actions: {
    handleRefreshAccounts() {
      this.refreshAccountsTask.perform();
    }
  }
});
