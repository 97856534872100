import { not, notEmpty, bool } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

export default Component.extend({
  propTypes: {
    disabled: T.bool,
    event: T.looseShape({
      id: T.string.isRequired,
      publishedAt: T.nullable(T.string),
      instagramPublishedAt: T.nullable(T.string),
      printPublishedAt: T.nullable(T.string),
      hasFacebookEventPosts: T.bool.isRequired,
      hasFacebookPagePosts: T.bool.isRequired,
      upcomingFacebookPagePosts: T.array.isRequired
    }).isRequired
  },

  publishingNetworks: PublishingNetworks,

  enableLinks: not('disabled'),
  isPublishedOnInstagram: notEmpty('event.instagramPublishedAt'),
  isPublishedOnGHQ: notEmpty('event.publishedAt'),
  isPublishedOnFacebookPage: bool('event.hasFacebookPagePosts'),
  isPublishedOnFacebookEvent: bool('event.hasFacebookEventPosts'),
  isPublishedOnPrint: notEmpty('event.printPublishedAt'),

  hasUpcomingFacebookPagePosts: notEmpty('event.upcomingFacebookPagePosts'),

  instagramState: computed('isPublishedOnInstagram', function() {
    return {
      published: this.isPublishedOnInstagram,
      pending: false
    };
  }),

  ghqState: computed('isPublishedOnGHQ', function() {
    return {
      published: this.isPublishedOnGHQ,
      pending: false
    };
  }),

  facebookState: computed(
    'isPublishedOnFacebookPage',
    'isPublishedOnFacebookEvent',
    'hasUpcomingFacebookPagePosts',
    function() {
      return {
        published:
          this.isPublishedOnFacebookPage ||
          this.isPublishedOnFacebookEvent ||
          this.hasUpcomingFacebookPagePosts,
        pending: false
      };
    }
  ),

  facebookPageState: computed(
    'isPublishedOnFacebookPage',
    'hasUpcomingFacebookPagePosts',
    function() {
      return {
        published: this.isPublishedOnFacebookPage,
        pending: this.hasUpcomingFacebookPagePosts
      };
    }
  ),

  facebookEventState: computed('isPublishedOnFacebookEvent', function() {
    return {
      published: this.isPublishedOnFacebookEvent,
      pending: false
    };
  }),

  printState: computed('isPublishedOnPrint', function() {
    return {
      published: this.isPublishedOnPrint,
      pending: false
    };
  })
});
