import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';

// Actions
import { login } from 'gigshq/actions/session';

const mapStateToComputed = ({
  session: {
    data,
    loginState: {isPending, error}
  }
}) => {
  return {
    error,
    isLoggingIn: isPending,
    session: data
  };
};

const mapDispatchToActions = dispatch => {
  return {
    login: ({email, password, executeQuery}) =>
      dispatch(login({email, password, executeQuery}))
  };
};

const LoginForm = ReduxContainer.extend({
  propTypes: {
    onLoginSuccess: T.func.isRequired
  },

  apollo: service(),

  actions: {
    handleLogin({email, password}) {
      this.get('actions.login')({
        email,
        password,
        executeQuery: this.apollo.queryOperation()
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(LoginForm);
