import EmberObject from '@ember/object';
import { readOnly, notEmpty, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

const TRUNCATE_DESCRIPTION_REGEX = /^[\s\S]{0,190}.*?([.!?]*(?=\s|$))/;
const DEFAULT_EVENT_DURATION_IN_HOURS = 2;

export default Component.extend(Localizable(translations), {
  classNameBindings: [ 'isCancelled' ],
  showMoreEnabled: false,

  venue: readOnly('event.venue'),
  shouldDisplayTicketLink: computed('event.ticketSources', 'isPastEvent', function() {
    return !this.isPastEvent && this.event.ticketSources.length > 0;
  }),

  isPastEvent: computed('event.startedAt', function() {
    return moment(this.event.startedAt).isBefore(moment().startOf('day'));
  }),

  hasMultipleTicketSources: computed('event.ticketSources', function() {
    return this.ticketSources.length > 1;
  }),

  ticketSources: computed('event.ticketSources', function() {
    return this.event.ticketSources;
  }),

  singleTicketSource: computed('event.ticketSources', function() {
    return this.ticketSources[0];
  }),

  style: computed('event.shareableProfile.wide', function() {
    return htmlSafe(
      `background-image: url('${this.get(
        'event.shareableProfile.widePicture'
      )}')`
    );
  }),

  showMapUrl: computed('event.venue.formattedAddress', function() {
    const address = this.get('event.venue.formattedAddress');

    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  }),

  hasTicketPrice: notEmpty('event.ticketPriceCents'),

  seeSiteForTicketPrice: computed('event.ticketPriceCents', function() {
    return this.event.ticketPriceCents === -1;
  }),

  hasDoorPrice: notEmpty('event.doorPriceCents'),

  firstGigStartedAt: computed('event.gigs.@each.startedAt', function() {
    if (this.event.gigs.length === 0)
      return this.event.startedAt;

    return this.event.gigs[0].startedAt;
  }),

  calEvent: computed(function() {
    return EmberObject.create({
      start: moment(this.event.startedAt),
      end: moment(this.event.startedAt).add(DEFAULT_EVENT_DURATION_IN_HOURS, 'hours'),
      title: this.event.name,
      description: this.event.description,
      location: `${this.event.venue.name} - ${this.event.venue.formattedAddress}`
    });
  }),

  shareOnFacebook() {
    window.FB.ui({
      method: 'share',
      href: window.location.href
    });
  },

  shareLink(event) {
    navigator.share({
      title: event.name,
      text: event.name,
      url: location.href
    },
    { language: this.get('i18n.locale') });
  },

  truncatedDescription: computed('event.description', function() {
    if (!this.event.description) return null;

    // We use a regex to avoid breaking in the middle of words.
    const truncatedDescription = this.event.description.match(
      TRUNCATE_DESCRIPTION_REGEX
    )[0];

    return truncatedDescription.match(/[.!?]$/)
      ? truncatedDescription
      : `${truncatedDescription}…`;
  }),

  hasNoTruncatedDescription: computed(
    'event.description',
    'truncatedDescription',
    function() {
      return this.event.description === this.truncatedDescription;
    }
  ),

  hasTruncatedDescription: not('hasNoTruncatedDescription'),

  showFullDescription: or('showMoreEnabled', 'hasNoTruncatedDescription'),

  actions: {
    toggleDropDown() {
      this.toggleProperty('isOpen');
    }
  }
});
