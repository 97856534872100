import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

// Actions
import { fillForm } from 'gigshq/actions/events';

// Queries
import query from 'gigshq/graphql/queries/event-edition';

export default Route.extend(ConfirmationMixin, {
  apollo: service(),
  can: service(),
  redux: service(),

  model({id}) {
    const variables = {id};

    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  redirect(model) {
    if (this.can.cannot('edit event', model.event, {})) {
      return this.transitionTo('app.events.index');
    }
  },

  afterModel(model) {
    if (!model.event) return this.transitionTo('app.events.index');

    const {
      id,
      name,
      description,
      startedAt,
      publishedAt,
      ticketPriceCents,
      doorPriceCents,
      ticketPurchaseUrl,
      gigs,
      wideCrop,
      squareCrop,
      slug,
      venue
    } = model.event;

    const values = {
      id,
      name,
      description,
      ticketPriceCents,
      doorPriceCents,
      startedAt,
      publishedAt,
      ticketPurchaseUrl,
      gigs,
      wideCrop,
      squareCrop,
      slug,
      venue
    };

    this.redux.dispatch(fillForm({form: 'eventEdition', values}));
  },

  isPageDirty() {
    const {
      events: {
        eventEdition: {isDirty}
      }
    } = this.redux.getState();
    return isDirty;
  }
});
