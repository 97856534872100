import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER } from 'gigshq/constants/user-types';

export default Controller.extend(Localizable(translations), {
  queryParams: {
    passwordResetSuccess: 'passwordResetSuccess',
    sourceUrl: 'src'
  },

  router: service(),
  artistUserType: USER_TYPE_ARTIST,
  tenantUserType: USER_TYPE_TENANT,
  memberUserType: USER_TYPE_MEMBER,

  actions: {
    onLoginSuccess() {
      if (this.sourceUrl)
        this.router.transitionTo(this.sourceUrl);
        // window.location.href = this.sourceUrl;
      else
        this.transitionToRoute('app', { queryParams: { login: true } });
    }
  }
});
