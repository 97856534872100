import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

// Actions
import { fillForm } from 'gigshq/actions/offers';

// Queries
import query from 'gigshq/graphql/queries/venue-offer-edit';

export default Route.extend(ConfirmationMixin, {
  apollo: service(),
  can: service(),
  redux: service(),

  model({id}) {
    const variables = {id};
    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  redirect(model) {
    if (this.can.cannot('edit venue', model.venueOffer.venue, {}))
      return this.transitionTo('app.offers.index');
  },

  afterModel(model) {
    if (!model.venueOffer) return this.transitionTo('app.offers.index');

    const {
      id,
      venue,
      offerType,
      description,
      fineprint,
      blackoutDates,
      image,
      paused,
      deletedAt
    } = model.venueOffer;

    const values = {
      id,
      venue,
      offerType,
      description,
      fineprint,
      blackoutDates,
      image,
      paused,
      deletedAt
    };

    this.redux.dispatch(fillForm({form: 'offerEdition', values}));
  },

  isPageDirty() {
    const {
      offers: {
        offerEdition: {isDirty}
      }
    } = this.redux.getState();
    return isDirty;
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
