import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

const States = {
  COMPLETED: 'completed',
  INSTRUCTIONS: 'instructions'
};

export default Component.extend({
  propTypes: {
    event: T.object.isRequired,
    phoneNumber: T.nullable(T.string),
    countryCode: T.nullable(T.number),
    countryCodes: T.array.isRequired,
    publish: T.func.isRequired,
    changeTab: T.func.isRequired,
    toggleCaptionToolIsOpened: T.func.isRequired
  },

  internalPhoneNumber: reads('phoneNumber'),
  internalCountryCode: reads('countryCode'),

  stateComponent: computed('state', function() {
    return `gigs-publish-tool-tab-instagram/${this.state}`;
  }),

  state: computed('event.instagramPublishedAt', function() {
    const completed = this.get('event.instagramPublishedAt');

    if (completed) return States.COMPLETED;
    return States.INSTRUCTIONS;
  }),

  actions: {
    handleNext() {
      this.changeTab(PublishingNetworks.PRINT.name);
    }
  }
});
