import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import S3Uploader from 'ember-uploader/uploaders/s3';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import configValue from 'gigshq/computed-macros/config-value';
import ImageTypes from 'gigshq/constants/image-types';

// Mutations
import createImageMutation from 'gigshq/graphql/mutations/create-image';

const THUMBNAIL_WIDTH = 184;
const THUMBNAIL_HEIGHT = 138;

export default Component.extend(Localizable(translations), {
  propTypes: {
    artistId: T.string.isRequired,
    file: T.instanceOf(File).isRequired,
    onUploadStart: T.func.isRequired,
    onUploadComplete: T.func.isRequired
  },

  apollo: service(),
  flashMessages: service('flash-messages'),

  signingUrl: configValue('API.IMAGE_SIGNING_URL'),

  isPending: true,
  progress: 0,

  progressStyles: computed('progress', function() {
    return htmlSafe(`width: ${this.progress}%;`);
  }),

  didInsertElement() {
    const uploader = S3Uploader.create({
      signingUrl: this.signingUrl,
      signingMethod: 'POST'
    });

    this.set('uploader', uploader);

    this.startUpload();
  },

  willDestroyElement() {
    this.uploader.destroy();
  },

  startUpload() {
    this.onUploadStart();

    const {file, uploader} = this;

    uploader.upload(file);

    uploader.on('progress', event => {
      this.set('progress', event.percent);
    });

    uploader.on('didError', () => {
      this.flashMessages.error(
        this.localTranslation('errors.could_not_upload')
      );
    });

    uploader.on('didUpload', event => {
      this.didUpload(event);
    });
  },

  didUpload(event) {
    this.set('isPending', false);

    const imageUrl = $(event).find('Location')[0].textContent;

    this.set('imageUrl', imageUrl);

    this.getImageSize(imageUrl).then(imageSize => {
      const mutation = createImageMutation;
      const variables = {
        imageUrl,
        imageSize,
        artistId: this.artistId,
        imageType: ImageTypes.ARTIST_PHOTO,
        thumbnailImageWidth: THUMBNAIL_WIDTH,
        thumbnailImageHeight: THUMBNAIL_HEIGHT
      };

      return this.apollo
        .mutationOperation()(
          {mutation, variables, refetchQueries: ['ArtistImages']},
          'createImage'
        )
        .then(() => this.onUploadComplete());
    });
  },

  getImageSize(imageUrl) {
    return new RSVP.Promise(resolve => {
      const image = new Image();

      image.onload = () => resolve({width: image.width, height: image.height});

      image.src = imageUrl;
    });
  }
});
