export default {
  byPath(path, errors) {
    if (!errors || !errors.length) return [];

    return errors.reduce((memo, error) => {
      if (error.path !== path) return memo;

      return [...memo, ...error.messages];
    }, []);
  },

  byInputName(errors) {
    if (!errors || !errors.length) return {};

    return errors.reduce((memo, error) => {
      const name = error.path.split('/').pop();
      memo[name] = {
        property: name,
        error: error.messages[0]
      };

      return memo;
    }, {});
  }
};
