// Vendor
import { inject as service } from '@ember/service';

import RSVP from 'rsvp';
import { computed } from '@ember/object';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import ApolloService from 'ember-apollo-client/services/apollo';
import { logout } from 'gigshq/actions/session';

const dataIdFromObject = result => {
  if (result.id && result.__typename) {
    return `${result.__typename}${result.id}`;
  }

  // Make sure to return null if this object doesn’t have an ID
  return null;
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Viewer',
          possibleTypes: [
            { name: 'Visitor' },
            { name: 'Tenant' },
            { name: 'Artist' },
            { name: 'Admin' }
          ]
        }
      ]
    }
  }
});

export default ApolloService.extend({
  fastboot: service(),
  redux: service(),
  router: service(),
  sessionFetcher: service('fetchers/session-fetcher'),

  clientOptions: computed(function() {
    return {
      cache: this.cache,
      link: this.link,
      ssrMode: this.get('fastboot.isFastBoot')
    };
  }),

  link: computed(function() {
    const httpLink = this._super();
    const authenticationLink = this.createAuthenticationLink();
    const unauthorizedLink = this.createUnauthorizedLink();

    return authenticationLink.concat(unauthorizedLink).concat(httpLink);
  }),

  cache: computed(() => {
    return new InMemoryCache({ dataIdFromObject, fragmentMatcher });
  }),

  createAuthenticationLink() {
    return setContext(_request => {
      const session = this.sessionFetcher.fetch();

      if (!session) return;

      return {
        headers: {
          authorization: `Bearer ${session.token}`
        }
      };
    });
  },

  createUnauthorizedLink() {
    return new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        if (response.data.viewer === null) this._logout();

        // When we don’t have access to a certain viewer type, the API returns the
        // viewer with only its `__typename` property inside. We logout because the session
        // doesn’t contain the right viewer type
        if (
          response.data.viewer &&
          Object.keys(response.data.viewer).join() === '__typename'
        ) {
          this._logout();
        }

        return response;
      });
    });
  },

  queryOperation() {
    return ({ query, variables, fetchPolicy = 'network-only' }, rootKey) => {
      return new RSVP.Promise((resolve, reject) => {
        this.query({ query, variables, fetchPolicy }, rootKey)
          .then(response => {
            if (response && response.errors) return reject(response.errors);

            resolve(response);
          })
          .catch(error => reject(error));
      });
    };
  },

  watchQueryOperation({
    query,
    variables,
    reducer,
    fetchPolicy = 'network-only'
  }) {
    return this.client.watchQuery({ query, variables, reducer, fetchPolicy });
  },

  mutationOperation() {
    return ({ mutation, variables, refetchQueries }, rootKey) => {
      return new RSVP.Promise((resolve, reject) => {
        this.mutate({ mutation, variables, refetchQueries }, rootKey)
          .then(response => {
            if (response.errors) return reject(response.errors);

            resolve(response);
          })
          .catch(error => reject(error));
      });
    };
  },

  clearStoreOperation() {
    return async () => this.client.resetStore();
  },

  _logout() {
    return this.redux
      .dispatch(logout(() => RSVP.resolve(), this.sessionFetcher.fetch()))
      .then(() => window.location = this.router.urlFor('web.events.index.map'));
  }
});
