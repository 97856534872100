import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  minDate: computed(() =>
    moment()
      .startOf('day')
      .toISOString()
  ),

  actions: {
    handleDatesUpdate(blackoutDates) {
      this.setProperty('blackoutDates', blackoutDates.date);
    },
  },
});
