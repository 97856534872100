import { createAction } from 'redux-actions';
import mutation from 'gigshq/graphql/mutations/duplicate-event';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';

export const duplicateEventPending = createAction('DUPLICATE_EVENT_PENDING');
export const duplicateEventSuccess = createAction('DUPLICATE_EVENT_SUCCESS');
export const duplicateEventFailure = createAction('DUPLICATE_EVENT_FAILURE');

export const duplicateEvent = ({
  mutate,
  eventId,
  dates,
  autoPublishEvent
}) => async dispatch => {
  dispatch(duplicateEventPending());

  try {
    const variables = GraphQLVariablesBuilder.build(mutation, {
      eventId,
      dates,
      autoPublishEvent
    });
    const result = await mutate({mutation, variables}, 'duplicateEvent');

    dispatch(duplicateEventSuccess({result}));

    return result;
  } catch (error) {
    dispatch(duplicateEventFailure({error}));
  }
};

export const setFormValue = createAction('DUPLICATE_EVENT_SET_FORM_VALUE');
export const resetForm = createAction('DUPLICATE_EVENT_RESET_FORM');
