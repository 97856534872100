import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import PublishingNetworks from 'gigshq/constants/publishing-networks';

const States = {
  COMPLETED: 'completed',
  INSTRUCTIONS: 'instructions'
};

export default Component.extend({
  propTypes: {
    event: T.object.isRequired,
    publish: T.func.isRequired,
    changeTab: T.func.isRequired
  },

  stateComponent: computed('state', function() {
    return `gigs-publish-tool-tab-ghq/${this.state}`;
  }),

  state: computed('event.publishedAt', function() {
    const completed = this.get('event.publishedAt');

    if (completed) return States.COMPLETED;
    return States.INSTRUCTIONS;
  }),

  actions: {
    handleNext() {
      this.changeTab(PublishingNetworks.FACEBOOK_TIMELINE.name);
    }
  }
});
