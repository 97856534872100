import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  actions: {
    slide(obj) {
      const $slider = this.$(obj.target);
      const sliderPosition = $slider.val();

      if (sliderPosition > 99
          && window.confirm(this.localTranslation('confirm'))) {
        this.$("#gigs-slide-to-redeem__slider").hide();
        this.submit();
        this.$(".gigs-slide-to-redeem__completed").fadeIn();
      } else
        this.actions.resetSlider($slider);
    },

    resetSlider(slider) {
      setInterval(() => {
        const currValue = slider.val();

        if (currValue <= 0)
          return;

        slider.val(currValue - 1);
      }, 200);
    }
  }
});
