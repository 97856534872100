import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/managed-venues-with-offers';

export default Route.extend({
  apollo: service(),

  model() {
    const variables = { isVenueOfferListing: true };
    return this.apollo.queryOperation()({ query, variables }, 'viewer');
  }
});
