import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { and, bool, or } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';
import pagination from 'gigshq/constants/pagination';

const EVENTS_LISTING_CONTAINER = '.gigs-web-events-listing__container';

export default Component.extend(Localizable(translations), {
  propTypes: {
    events: T.array,
    venue: T.object,
    fromDate: T.nullable(T.string),
    toDate: T.nullable(T.string),
    userLocation: T.nullable(
      T.shape({
        latitude: T.number.isRequired,
        longitude: T.number.isRequired
      })
    ),
    city: T.string,
    totalEventCount: T.number,
    totalPageCount: T.number,
    page: T.number,
    onPageSelect: T.func,
    isCalendarPlugin: T.nullable(T.bool),
    scrollLoadMoreEvents: T.bool
  },

  eventsFetcher: service('fetchers/events-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  isLoadingEventsOrVenues: or('eventsFetcher.isLoadingEvents', 'venuesFetcher.isLoadingVenues'),
  isAppendingEvents: bool('eventsFetcher.isAppendingEvents'),
  hasDateRange: and('fromDate', 'toDate'),

  eventsByMonth: computed('events.[]', function() {
    if (this.events == null)
      return null;

    return this.events.reduce((memo, event) => {
      const eventMonth = moment(event.startedAt).tz(event.venue.timezone).format('YYYY-MM');
      if (!memo[eventMonth]) {
        memo[eventMonth] = [ event ];
      } else {
        memo[eventMonth].push(event);
      }

      return memo;
    }, {});
  }),

  init() {
    this._super(...arguments);
    if (this.scrollLoadMoreEvents) this.onScroll = this.onScroll.bind(this);
  },

  didInsertElement() {
    if (this.scrollLoadMoreEvents)
      document
        .querySelector(EVENTS_LISTING_CONTAINER)
        .addEventListener('scroll', this.onScroll);

    if (this.city) {
      const geocoder = new window.google.maps.Geocoder();
      Promise.resolve(geocoder.geocode({'address': this.city}))
        .then((geocodeResult) => {
          this.venuesFetcher.fetchVenuesByGeolocation({
            latitude: geocodeResult.results[0].geometry.location.lat(),
            longitude: geocodeResult.results[0].geometry.location.lng(),
          }, true);
        });
    } else
      this.venuesFetcher.fetchVenuesByGeolocation(this.userLocation);

  },

  willDestroyElement() {
    if (this.scrollLoadMoreEvents)
      document
        .querySelector(EVENTS_LISTING_CONTAINER)
        .removeEventListener('scroll', this.onScroll);
  },

  onScroll() {
    const element = document.querySelector(EVENTS_LISTING_CONTAINER);
    const scrollTop = element.scrollTop;
    const innerHeight = element.offsetHeight;
    const scrollHeight = element.scrollHeight;

    if (scrollTop + innerHeight >= scrollHeight - pagination.TRIGGER_FROM_BOTTOM_DISTANCE
        && this.scrollLoadMoreEvents)
      this.loadMoreEvents();
  },

  loadMoreEvents() {
    if (this.eventsFetcher.isAppendingEvents
        || this.eventsFetcher.currentPage >= this.eventsFetcher.totalPageCount)
      return;

    this.eventsFetcher.fetchEvents(
      this.venue,
      this.eventsFetcher.currentPage + 1,
      this.fromDate,
      null,
      true);
  },
});
