import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/managed-artists';

export default Route.extend({
  apollo: service(),

  model() {
    return this.apollo.queryOperation()({query}, 'viewer');
  }
});
