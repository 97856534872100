import LobbyBaseRoute from '../route';
import { inject as service } from '@ember/service';
import { USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import ResetScrollMixin from 'gigshq/mixins/reset-scroll-position';

export default LobbyBaseRoute.extend(ResetScrollMixin, {
  fastboot: service(),
  stripe: service('stripev3'),

  queryParams: {
    userType: {
      refreshModel: true
    }
  },

  beforeModel() {
    if (this.fastboot.isFastBoot) return;

    return this.stripe.load();
  },

  model({ userType }) {
    userType = userType || USER_TYPE_MEMBER;
    return { userType };
  },

});
