/* global FastBoot */

import Service, { inject as service } from '@ember/service';

import config from 'gigshq/config/environment';

export default Service.extend({
  cookies: service(),
  fastboot: service(),
  sessionFetcher: service('fetchers/session-fetcher'),

  /* eslint-disable complexity */
  fetchLocale() {
    // We found the language in the cookies, return it
    const cookieLocale = this.cookies.read('locale');
    if (cookieLocale) return cookieLocale;

    // We found a locale set in the tenant session
    const session = this.sessionFetcher.fetch();
    if (session) return session.locale;

    // Otherwise, try to guess preferred language based on browser locale
    if (!this.get('fastboot.isFastBoot')) {
      const browserLocale = this.browserLocale();
      if (browserLocale) return browserLocale;
    } else {
      const headerLocale = this.headerLocale();
      if (headerLocale) return headerLocale;
    }

    // Fallback to the first supported locale if we didn’t find any
    return this.defaultLocale();
  },

  browserLocale() {
    let languages = [];
    let locale = null;

    if (window.navigator.languages)
      languages = languages.concat(window.navigator.languages);
    if (window.navigator.userLanguage)
      languages.push(window.navigator.userLanguage);
    if (window.navigator.language) languages.push(window.navigator.language);

    for (const language of languages) {
      const languageLocale = language.split('-')[0];

      if (config.i18n.locales.includes(languageLocale)) {
        locale = languageLocale;
        break;
      }
    }

    return locale;
  },
  /* eslint-enable complexity */

  headerLocale() {
    const headers = this.get('fastboot.request.headers');
    const acceptLanguageHeader = headers.get('Accept-Language');

    const acceptLanguage = FastBoot.require('accept-language');
    acceptLanguage.languages(config.i18n.locales);

    return acceptLanguage.get(acceptLanguageHeader);
  },

  defaultLocale() {
    return config.i18n.locales[0];
  }
});
