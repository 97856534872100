import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import {
  offerRedeem,
  setFormValue,
  resetForm
} from 'gigshq/actions/offer-redeem';

const mapStateToComputed = ({offerRedeem}) => {
  const {
    isDirty,
    isPending,
    data,
    errors
  } = offerRedeem;

  return {
    isDirty,
    isPending,
    data,
    errors
  };
};

const mapDispatchToActions = dispatch => {
  return {
    offerRedeem: (mutate, venueOfferId) =>
      dispatch(offerRedeem({mutate, venueOfferId})),
    setFormValue: (name, value) => dispatch(setFormValue({name, value})),
    resetForm: () => dispatch(resetForm())
  };
};

const OfferRedemptionModal = ReduxContainer.extend({
  apollo: service(),

  propTypes: {
    offer: T.object.isRequired,
    onOfferRedemption: T.func.isRequired,
    onOfferRedemptionFailure: T.func.isRequired,
    onClose: T.func.isRequired
  },

  actions: {
    close() {
      this.onClose();
    },

    redeem(venueOfferId) {
      this.get('actions.offerRedeem')(
        this.apollo.mutationOperation(),
        venueOfferId
      ).then(() => {
        if (this.errors) {
          this.onOfferRedemptionFailure(this.errors[0].messages[0]);
          return;
        }

        this.get('actions.resetForm')();
        this.onOfferRedemption();
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(OfferRedemptionModal);
