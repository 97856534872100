import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import $ from 'jquery';

//TODO SAUDET duplication code marker
const USER_MARKER = () => ({
  anchor: new window.google.maps.Point(15, 56),
  size: new window.google.maps.Size(51.666666667, 56),
  scaledSize: new window.google.maps.Size(51.666666667, 56),
  url: '/assets/images/img-map-pin-user.png'
});

export default Component.extend(Localizable(translations), {
  propTypes: {
    close: T.func.isRequired
  },

  didInsertElement(){
    var map = window.globalMap;
    var input = document.getElementById('popup-search-bar');

    input.addEventListener("focusout", () => {
      window.scrollTo(0,0);
    });

    const searchBox = new window.google.maps.places.SearchBox(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });
  
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
  
      if (places.length == 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);

      if (this.placeMarker) {
        this.placeMarker.setMap(null);
      }
      this.set('placeMarker', new window.google.maps.Marker({
        position: places[0].geometry.location,
        title: places[0].name,
        icon: USER_MARKER(),
        map: map
      }))

      this.close()
    });
  },

  didRender(){
    $('#popup-search-bar').focus();
  }
});
