import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  i18n: service(),

  compute(cents, _) {
    const price = cents / 100;
    const formattedPrice = price.toLocaleString(this.get('i18n.locale'), {minimumFractionDigits: 2});

    return this.get('i18n.locale') === 'en' ? `$${formattedPrice}` : `${formattedPrice}$`;
  }
});
