import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    venue: T.object.isRequired,
    onDelete: T.func.isRequired
  },

  delete() {
    this.onDelete(this.get('venue.id'));
  }
});
