import Controller from '@ember/controller';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Controller.extend(Localizable(translations), {
  actions: {
    onSignUpSuccess() {
      this.transitionToRoute('app');
    }
  }
});
