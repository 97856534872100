import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  tagName: 'button',
  attributeBindings: ['type', 'disabled'],
  classNameBindings: ['appearance'],

  propTypes: {
    label: T.oneOfType([T.string, T.safeString]).isRequired,
    iconPath: T.string,
    appearance: T.string,
    onClick: T.func
  },

  click() {
    this.onClick();
  },

  type: 'button',
  disabled: false
});
