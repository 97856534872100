import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import config from 'gigshq/config/environment';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import ResetScrollMixin from 'gigshq/mixins/reset-scroll-position';

// Queries
import query from 'gigshq/graphql/queries/venue';

export default Route.extend(Localizable(translations), ResetScrollMixin, {
  accountManager: service('managers/account-manager'),
  apollo: service(),
  can: service(),
  fastboot: service(),

  beforeModel() {
    if (this.get('fastboot.isFastBoot')) return;

    if (window.google && window.google.maps) return;

    return new RSVP.Promise((resolve, reject) => {
      $.getScript(config.Google.PLACES_SDK_SCRIPT_URL)
        .done(resolve)
        .fail(reject);
    });
  },

  model({slug}) {
    const variables = {slug};
    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  titleToken(model) {
    const venue = model.venue;
    return venue.name;
  },

  afterModel(model) {
    if (this.can.cannot('view venue', model.venue, {}))
      return this.transitionTo('web');

    this.accountManager.setAccount(model);
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
