import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  i18n: service(),

  compute([prefix, key], hash) {
    return this.i18n.t(`${prefix}.${key}`, hash).string;
  }
});
