import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

// Mixins
import LocationStyleable from 'gigshq/mixins/components/locationable-styleable';

export default Component.extend(LocationStyleable, {
  propTypes: {
    artistId: T.string.isRequired,
    location: T.string
  }
});
