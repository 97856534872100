import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import { PropTypes as T } from 'ember-prop-types';
import { task } from 'ember-concurrency';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    facebookPageObj: T.object.isRequired,
    login: T.func.isRequired,
    disconnect: T.func.isRequired
  },

  disconnectTask: task(function*() {
    this.set('isPending', true);

    yield this.disconnect();

    this.set('isPending', false);
  }).drop(),

  actions: {
    handleDisconnect() {
      this.disconnectTask.perform();
    }
  }
});
