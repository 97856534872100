import { bool, and } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    phoneNumber: T.nullable(T.string),
    email: T.nullable(T.string)
  },

  shouldDisplayEmail: bool('email'),
  shouldDisplayPhoneNumber: bool('phoneNumber'),
  shouldDisplaySeparator: and('shouldDisplayPhoneNumber', 'shouldDisplayEmail'),

  formattedPhoneNumber: computed('phoneNumber', function() {
    return this.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  })
});
