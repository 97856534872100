/* eslint-disable max-nested-callbacks */
import EmberRouter from '@ember/routing/router';
import config from './config/environment';
import { withHashSupport } from 'ember-url-hash-polyfill';

@withHashSupport
export class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
};
//const Router = EmberRouter.extend({
//});

Router.map(function() {
  this.route('calendar');

  this.route('web', { path: '/' }, function() {
    this.route('about');
    this.route('terms');
    this.route('privacy-policy');

    this.route('events', function() {
      this.route('index', { path: '/' }, function() {
        this.route('map');
        this.route('list');
        this.route('photos');
      });

      this.route('show', { path: ':venue_slug/:slug' });
    });

    this.route('venues', function() {
      this.route('show', { path: ':slug' });
    });

    this.route('artists', function() {
      this.route('show', { path: ':slug' });
    });

    this.route('page-not-found', { path: '/*wildcard' });
  });

  this.route('lobby', function() {
    this.route('login');
    this.route('sign-up');
    this.route('reset-password', { path: 'reset-password/:token' });
    this.route('ask-reset-password-instructions', { path: 'reset-password' });
  });

  this.route('app', function() {
    this.route('artists', function() {
      this.route('new');
      this.route('edit', { path: ':id/edit' });
    });

    this.route('venues', function() {
      this.route('new');
      this.route('edit', { path: ':id/edit' });
    });

    this.route('users', function() {
      this.route('edit', { path: '/edit' });
    });

    this.route('events', function() {
      this.route('new');
      this.route('edit', { path: ':id/edit' });

      this.route('publish', { path: ':id/publish' }, function() {
        this.route('ghq');

        this.route('facebook-timeline', function() {
          this.route('new');
        });

        this.route('facebook-event');
        this.route('instagram');
        this.route('print');
      });
    });

    this.route('offers', function() {
      this.route('new');
      this.route('edit', { path: ':id/edit' });
      this.route('history');
    });
  });

  this.route('admin', function() {
    this.route('tenants', function() {
      this.route('venues', { path: ':user_id/venues' });
    });
  });

  this.route('unauthorized');
});

export default Router;
