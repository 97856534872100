import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    label: T.oneOfType([T.string, T.safeString]).isRequired,
    editButtonLabel: T.oneOfType([T.string, T.safeString]).isRequired,
    image: T.string.isRequired,
    editCover: T.func.isRequired,
    readOnly: T.bool,
  },

  backgroundStyle: computed('image', function() {
    return htmlSafe(`background-image: url('${this.image}')`);
  })
});
