import { handleActions } from 'redux-actions';
import {
  loginPending,
  loginSuccess,
  loginFailure,
  setSession,
  logoutPending,
  logoutSuccess,
  logoutFailure,
  signUpReset,
  signUpPending,
  signUpSuccess,
  signUpFailure
} from 'gigshq/actions/session';

const INITIAL_STATE = {
  loginState: {
    isPending: false,
    error: null
  },

  signUpState: {
    isPending: false,
    isSuccess: false,
    errors: null
  },

  data: null,

  logoutState: {
    isPending: false,
    error: null
  }
};

export default handleActions(
  {
    [loginPending]: state => ({
      ...state,
      loginState: {
        isPending: true,
        error: null
      },
      data: null
    }),

    [loginSuccess]: (state, {payload}) => ({
      ...state,
      loginState: {
        isPending: false,
        error: null
      },
      data: payload
    }),

    [loginFailure]: (state, {payload}) => ({
      ...state,
      loginState: {
        isPending: false,
        error: payload
      },
      data: null
    }),

    [setSession]: (state, {payload}) => ({
      ...state,
      loginState: {
        isPending: false,
        error: null
      },
      data: payload
    }),

    [logoutPending]: state => ({
      ...state,
      logoutState: {
        isPending: true,
        error: null
      }
    }),

    [logoutSuccess]: state => ({
      ...state,
      logoutState: {
        isPending: false,
        error: null
      },
      data: null
    }),

    [logoutFailure]: (state, {payload}) => ({
      ...state,
      logoutState: {
        isPending: false,
        error: payload
      }
    }),

    [signUpReset]: state => ({
      ...state,
      signUpState: {
        isPending: false,
        isSuccess: false,
        errors: null
      }
    }),

    [signUpPending]: state => ({
      ...state,
      signUpState: {
        isPending: true,
        isSuccess: false,
        errors: null
      }
    }),

    [signUpSuccess]: state => ({
      ...state,
      signUpState: {
        isPending: false,
        isSuccess: true,
        errors: null
      }
    }),

    [signUpFailure]: (state, {payload}) => ({
      ...state,
      signUpState: {
        isPending: false,
        isSuccess: false,
        errors: payload
      }
    })
  },
  INITIAL_STATE
);
