import { handleActions } from 'redux-actions';
import {
  askInstructionsPending,
  askInstructionsSuccess,
  askInstructionsFailure,
  resetPasswordPending,
  resetPasswordSuccess,
  resetPasswordFailure
} from 'gigshq/actions/password-reset';

const INITIAL_STATE = {
  askInstructionsForm: {
    isPending: false,
    isSuccess: false,
    errors: null
  },
  resetPasswordForm: {
    isPending: false,
    isSuccess: false,
    errors: null
  }
};

export default handleActions(
  {
    [askInstructionsPending]: state => ({
      ...state,
      askInstructionsForm: {
        isPending: true,
        isSuccess: false,
        errors: null
      }
    }),

    [askInstructionsSuccess]: state => ({
      ...state,
      askInstructionsForm: {
        isPending: false,
        isSuccess: true,
        errors: null
      }
    }),

    [askInstructionsFailure]: (state, {payload}) => ({
      ...state,
      askInstructionsForm: {
        isPending: false,
        isSuccess: false,
        errors: payload
      }
    }),

    [resetPasswordPending]: state => ({
      ...state,
      resetPasswordForm: {
        isPending: true,
        isSuccess: false,
        errors: null
      }
    }),

    [resetPasswordSuccess]: state => ({
      ...state,
      resetPasswordForm: {
        isPending: false,
        isSuccess: true,
        errors: null
      }
    }),

    [resetPasswordFailure]: (state, {payload}) => ({
      ...state,
      resetPasswordForm: {
        isPending: false,
        isSuccess: false,
        errors: payload
      }
    })
  },
  INITIAL_STATE
);
