import { bool } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';

const arrowClass = 'gigs-social-networks-form__network-arrow';

export default Component.extend({
  classNameBindings: ['isSelected', 'isFilled'],

  propTypes: {
    network: T.string.isRequired,
    selectedNetwork: T.string.isRequired,
    value: T.nullable(T.string).isRequired,
    customArrow: T.string,
    onClick: T.func.isRequired
  },

  click() {
    this.onClick(this.network);
  },

  isFilled: bool('value'),
  hasCustomArrow: bool('customArrow'),

  isSelected: computed('selectedNetwork', 'network', function() {
    return this.selectedNetwork === this.network;
  }),

  arrowClass: computed('network', function() {
    return `${arrowClass} ${arrowClass}--${this.network}`;
  }),

  iconPath: computed('isSelected', 'network', function() {
    const state = this.isSelected || this.isFilled ? 'on' : 'off';
    return `icn-network-${this.network.toLowerCase()}-${state}`;
  })
});
