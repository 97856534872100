import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import { PropTypes as T } from 'ember-prop-types';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';

// Actions
import {
  cancelEvent,
  setFormValue,
  resetForm
} from 'gigshq/actions/cancel-event';

const mapStateToComputed = ({cancelEvent}) => {
  const {
    isDirty,
    isPending,
    data,
    errors,
    form: {cancellationReason}
  } = cancelEvent;

  return {
    isDirty,
    isPending,
    data,
    errors,
    cancellationReason
  };
};

const mapDispatchToActions = dispatch => {
  return {
    cancelEvent: (mutate, variables) =>
      dispatch(cancelEvent({mutate, ...variables})),
    setFormValue: (name, value) => dispatch(setFormValue({name, value})),
    resetForm: () => dispatch(resetForm())
  };
};

const EventCancellationModal = ReduxContainer.extend({
  apollo: service(),

  propTypes: {
    eventId: T.string.isRequired,
    onEventCancel: T.func.isRequired,
    onEventCancelFailure: T.func.isRequired,
    onClose: T.func.isRequired
  },

  actions: {
    close() {
      this.onClose();
    },

    cancel() {
      const variables = this.getProperties('cancellationReason', 'eventId');

      this.get('actions.cancelEvent')(
        this.apollo.mutationOperation(),
        variables
      ).then(() => {
        if (this.errors) {
          this.onEventCancelFailure(this.errors[0].messages[0]);
          return;
        }

        this.get('actions.resetForm')();
        this.onEventCancel();
      });
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(EventCancellationModal);
