import { bool } from '@ember/object/computed';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  tagName: 'article',

  propTypes: {
    venueName: T.string.isRequired,
    coverImage: T.string.isRequired,
    message: T.string.isRequired
  },

  shouldDisplayMessage: bool('message')
});
