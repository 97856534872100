import { handleActions } from 'redux-actions';
import {
  resetTextfields,
  addTextfield,
  updateTextfield,
  removeTextfield,
  createCaptionedImagePending,
  createCaptionedImageSuccess,
  createCaptionedImageFailure
} from 'gigshq/actions/caption-tool';

const INITIAL_STATE = {
  textfields: [],
  createCaptionedImageMutation: {
    isPending: false,
    data: null,
    errors: null
  }
};

const uid = (() => {
  let uid = 0;
  return () => uid++;
})();

export default handleActions(
  {
    [resetTextfields]: state => ({
      ...state,
      textfields: INITIAL_STATE.textfields
    }),

    [addTextfield]: state => {
      const id = uid();

      return {
        ...state,
        textfields: [...state.textfields, {id, isRendered: false}]
      };
    },

    [updateTextfield]: (state, {payload}) => {
      const index = state.textfields.findIndex(
        textfield => textfield.id === payload.id
      );
      const textfields = state.textfields;

      const newTextfields = [
        ...textfields.slice(0, index),
        {...textfields[index], ...payload},
        ...textfields.slice(index + 1)
      ];

      return {...state, textfields: newTextfields};
    },

    [removeTextfield]: (state, {payload}) => {
      const index = state.textfields.findIndex(
        textfield => textfield.id === payload.id
      );
      const textfields = state.textfields;

      const newTextfields = [
        ...textfields.slice(0, index),
        ...textfields.slice(index + 1)
      ];

      return {...state, textfields: newTextfields};
    },

    [createCaptionedImagePending]: state => ({
      ...state,
      createCaptionedImageMutation: {
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [createCaptionedImageSuccess]: (state, {payload}) => ({
      ...state,
      createCaptionedImageMutation: {
        isPending: false,
        result: payload,
        errors: null
      }
    }),

    [createCaptionedImageFailure]: (state, {payload}) => ({
      ...state,
      createCaptionedImageMutation: {
        isPending: false,
        result: null,
        errors: payload
      }
    })
  },
  INITIAL_STATE
);
