import { handleActions } from 'redux-actions';
import {
  saveUserPending,
  saveUserSuccess,
  saveUserFailure,
  saveMembershipFieldsPending,
  saveMembershipFieldsSuccess,
  saveMembershipFieldsFailure,
  setFormValue,
  setMemberField,
  resetForm,
  fillForm
} from 'gigshq/actions/users';

const USER_FORM_INITIAL_STATE = {
  form: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    locale: '',
    stripeCardToken: '',

    member: {
      birthdate: '',
      gender: '',
      contactNumber: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      promoCode: '',
      stripeProductId: '',
    }
  },
  isDirty: false,
  isPending: false,
  isUploading: false,
  data: null,
  errors: null
};

const INITIAL_STATE = {
  userEdition: USER_FORM_INITIAL_STATE
};

export default handleActions(
  {
    [saveUserPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveUserSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isPending: false,
        data: payload.result.user,
        errors: null
      }
    }),

    [saveUserFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [saveMembershipFieldsPending]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: true,
        data: null,
        errors: null
      }
    }),

    [saveMembershipFieldsSuccess]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: false,
        isPending: false,
        data: payload.result.user,
        errors: null
      }
    }),

    [saveMembershipFieldsFailure]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isPending: false,
        data: null,
        errors: payload.error
      }
    }),

    [resetForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...INITIAL_STATE[payload.form]
      }
    }),

    [setFormValue]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        isDirty: true,
        form: {
          ...state[payload.form].form,
          [payload.name]: payload.value
        }
      }
    }),

    [fillForm]: (state, {payload}) => ({
      ...state,
      [payload.form]: {
        ...state[payload.form],
        form: payload.values
      }
    }),

    [setMemberField]: (state, {payload}) => {
      const memberFields = state[payload.form].form.member;

      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          isDirty: true,
          form: {
            ...state[payload.form].form,
            member: {
              ...memberFields,
              [payload.name]: payload.value
            }
          }
        }
      }
    },
  },
  INITIAL_STATE
);
