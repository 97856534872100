import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  tagName: 'label',

  propTypes: {
    value: T.bool,
    label: T.oneOfType([T.string, T.safeString]).isRequired,
    disabled: T.bool,
    withHelp: T.bool,
    helpText: T.oneOfType([T.string, T.safeString]),
    update: T.func.isRequired
  },

  classNameBindings: ['disabled']
});
