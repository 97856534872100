import Service, { inject as service } from '@ember/service';
import publishEvent from 'gigshq/graphql/mutations/publish-event';
import createFacebookPostMutation from 'gigshq/graphql/mutations/create-facebook-post';
import publishEventOnInstagram from 'gigshq/graphql/mutations/publish-event-on-instagram';
import publishEventOnPrint from 'gigshq/graphql/mutations/publish-event-on-print';
import PublishingNetworks from 'gigshq/constants/publishing-networks';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';

const PUBLISH_MUTATIONS = {
  [PublishingNetworks.GHQ.name]: publishEvent,
  [PublishingNetworks.FACEBOOK_TIMELINE.name]: createFacebookPostMutation,
  [PublishingNetworks.FACEBOOK_EVENT.name]: createFacebookPostMutation,
  [PublishingNetworks.INSTAGRAM.name]: publishEventOnInstagram,
  [PublishingNetworks.PRINT.name]: publishEventOnPrint
};

const PUBLISH_PAYLOAD_KEY = {
  [PublishingNetworks.GHQ.name]: 'publishEvent',
  [PublishingNetworks.FACEBOOK_TIMELINE.name]: 'createFacebookPost',
  [PublishingNetworks.FACEBOOK_EVENT.name]: 'createFacebookPost',
  [PublishingNetworks.INSTAGRAM.name]: 'publishEventOnInstagram',
  [PublishingNetworks.PRINT.name]: 'publishEventOnPrint'
};

export default Service.extend({
  apollo: service(),

  publish(inputVariables) {
    const network = inputVariables.network;
    const mutation = PUBLISH_MUTATIONS[network];

    const variables = GraphQLVariablesBuilder.build(mutation, inputVariables);

    return (
      this.apollo
        .mutationOperation()(
          {mutation, variables},
          PUBLISH_PAYLOAD_KEY[network]
        )
        .then(() => ({network}))
        // Network errors somehow have a nested `error` key
        // while validation errors have `messages` at the root
        .catch(errors => ({network, errors: errors.errors || errors}))
    );
  }
});
