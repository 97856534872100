import Component from '@ember/component';

export default Component.extend({
  tagName: 'button',
  attributeBindings: ['type', 'disabled'],
  classNameBindings: ['appearance'],

  type: 'button',
  disabled: false
});
