import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';

export default Component.extend({
  propTypes: {
    status: T.string,
    text: T.oneOfType([T.string, T.safeString]).isRequired
  },

  getDefaultProps() {
    return {
      status: AssetsCatalog.GigiStates.NEUTRAL.key
    };
  },

  statusImagePath: computed('status', function() {
    return AssetsCatalog.GigiStates[this.status].asset;
  })
});
