import gql from 'graphql-tag';

export default gql`
  query ManagedVenues($isVenueListing: Boolean, $searchQuery: String) {
    viewer {
      ... on User {
        id

        venues(
          isVenueListing: $isVenueListing,
          searchQuery: $searchQuery,
          orderParams: [{attribute: name, order: ASC, caseInsensitive: true}]
        ) {
          id
          name
          formattedAddress
          timezone

          profileImage {
            id
            imageUrl
          }
        }
      }
    }
  }
`;
/*
          upcomingEvent {
            id
            name
            slug
          }
*/
