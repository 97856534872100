import { createAction } from 'redux-actions';
import mutation from 'gigshq/graphql/mutations/venue-offer-redeem';
import GraphQLVariablesBuilder from 'gigshq/utils/graphql-variables-builder';

export const offerRedeemPending = createAction('REDEEM_OFFER_PENDING');
export const offerRedeemSuccess = createAction('REDEEM_OFFER_SUCCESS');
export const offerRedeemFailure = createAction('REDEEM_OFFER_FAILURE');

export const offerRedeem = ({
  mutate,
  venueOfferId
}) => async dispatch => {
  dispatch(offerRedeemPending());

  try {
    const variables = GraphQLVariablesBuilder.build(mutation, {
      venueOfferId
    });

    const result = await mutate({mutation, variables}, 'venueOfferRedemption');
    dispatch(offerRedeemSuccess({result}));

    return result;
  } catch (error) {
    dispatch(offerRedeemFailure({error}));
  }
};

export const setFormValue = createAction('REDEEM_OFFER_SET_FORM_VALUE');
export const resetForm = createAction('REDEEM_OFFER_RESET_FORM');
