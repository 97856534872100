import { createAction } from 'redux-actions';
import createCaptionedImageMutation from 'gigshq/graphql/mutations/create-captioned-image';

export const resetTextfields = createAction('CAPTION_TOOL_RESET_TEXTFIELDS');
export const addTextfield = createAction('CAPTION_TOOL_ADD_TEXTFIELD');
export const updateTextfield = createAction('CAPTION_TOOL_UPDATE_TEXTFIELD');
export const removeTextfield = createAction('CAPTION_TOOL_REMOVE_TEXTFIELD');

export const createCaptionedImagePending = createAction(
  'CAPTION_TOOL_CREATE_CAPTIONED_IMAGE_PENDING'
);
export const createCaptionedImageSuccess = createAction(
  'CAPTION_TOOL_CREATE_CAPTIONED_IMAGE_SUCCESS'
);
export const createCaptionedImageFailure = createAction(
  'CAPTION_TOOL_CREATE_CAPTIONED_IMAGE_FAILURE'
);

export const createCaptionedImage = (
  mutate,
  imageUrl,
  imageSize,
  imageType,
  eventId,
  socialNetwork
) => async dispatch => {
  dispatch(createCaptionedImagePending());

  const mutation = createCaptionedImageMutation;
  const variables = {imageUrl, imageSize, eventId, imageType, socialNetwork};

  try {
    const result = await mutate({mutation, variables}, 'createCaptionedImage');

    dispatch(createCaptionedImageSuccess(result));

    return result;
  } catch (error) {
    dispatch(createCaptionedImageFailure(error));
  }
};
