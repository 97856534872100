// Vendor
import Mixin from '@ember/object/mixin';

import { computed } from '@ember/object';

export default Mixin.create({
  classNameBindings: [ 'locationClassName' ],

  locationClassName: computed('location', function() {
    if (!this.location) return '';

    return `at-${this.location}`;
  })
});
