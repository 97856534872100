import Component from '@ember/component';
import { gte } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';

const FULL_ROW_CELLS_COUNT = 3;

export default Component.extend({
  propTypes: {
    month: T.string.isRequired,
    events: T.array.isRequired
  },

  hasFullRow: gte('events.length', FULL_ROW_CELLS_COUNT)
});
