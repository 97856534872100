import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    isSaved: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    artistId: T.string.isRequired,
    artistImageQuery: T.oneOfType([T.null, T.object]).isRequired,
    name: T.string.isRequired,
    description: T.string,
    genres: T.array.isRequired,
    availableGenres: T.array.isRequired,
    profileImage: T.nullable(T.object).isRequired,
    contactEmail: T.string,
    socialProfile: T.object.isRequired,
    setFormValue: T.func.isRequired,
    setFormSocialProfileValue: T.func.isRequired,
    updateGenres: T.func.isRequired,
    save: T.func.isRequired,
    markFormAsUploading: T.func.isRequired,
    deleteImage: T.func.isRequired
  },

  mascotState: computed('isSaved', 'isDirty', 'errors', function() {
    if (this.isSaved && !this.isDirty)
      return AssetsCatalog.GigiStates.THUMBS_UP.key;
    if (this.errors) return AssetsCatalog.GigiStates.PERPLEX.key;
    return AssetsCatalog.GigiStates.NEUTRAL.key;
  }),

  actionBoxText: computed('isSaved', 'isDirty', 'errors', function() {
    if (this.isSaved && !this.isDirty) {
      return this.localTranslation('action_box.saved_text');
    }

    if (this.errors) {
      return this.localTranslation('action_box.error_text');
    }

    return this.localTranslation('action_box.editing_text');
  })
});
