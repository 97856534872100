import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

const LOCALIZED_IMAGES = [
  {
    fr: '/assets/images/img-website-screenshot1-fr.png',
    en: '/assets/images/img-website-screenshot1-en.png'
  },
  {
    fr: '/assets/images/img-website-screenshot2-fr.jpg',
    en: '/assets/images/img-website-screenshot2-en.jpg'
  },
  {
    fr: '/assets/images/img-website-screenshot3-fr.jpg',
    en: '/assets/images/img-website-screenshot3-en.jpg'
  }
];

export default Component.extend(Localizable(translations), {
  i18n: service(),

  tutorialStep: 1,
  carouselIntervalMs: 5000,
  stepsCount: 3,
  maximumCarouselWidth: 701,

  /* eslint-disable no-magic-numbers */
  isAtStep1: equal('tutorialStep', 1),
  isAtStep2: equal('tutorialStep', 2),
  isAtStep3: equal('tutorialStep', 3),
  /* eslint-enable no-magic-numbers */

  step1Image: computed(function() {
    return LOCALIZED_IMAGES[0][this.get('i18n.locale')];
  }),

  step2Image: computed(function() {
    return LOCALIZED_IMAGES[1][this.get('i18n.locale')];
  }),

  step3Image: computed(function() {
    return LOCALIZED_IMAGES[2][this.get('i18n.locale')];
  }),

  init() {
    this._super(...arguments);
    this.goToNextCarouselSlideTask.perform();
  },

  goToNextCarouselSlideTask: task(function*() {
    while (true) {
      yield timeout(this.carouselIntervalMs);
      this.goToNextCarouselSlide();
    }
  }).restartable(),

  goToNextCarouselSlide() {
    if (parseInt(this.$().css('width'), 10) < this.maximumCarouselWidth) {
      if (this.tutorialStep < this.stepsCount) {
        this.incrementProperty('tutorialStep');
      } else {
        this.set('tutorialStep', 1);
      }
    }
  },

  actions: {
    setTutorialStep(step) {
      this.set('tutorialStep', step);
    }
  }
});
