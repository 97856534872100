import Service from '@ember/service';

export default Service.extend({
  resetScroll(selector) {
    if (selector) {
      const element = document.querySelector(selector);
      if (element) element.scrollTop = 0;
    } else {

      try {
        window.scrollTo(0, 0);
      // eslint-disable-next-line no-empty
      } catch (error) {}

    }
  }
});
