import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    onClose: T.func.isRequired
  },

  tagName: 'button',

  click() {
    this.onClose();
  }
});
