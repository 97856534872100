// Vendor
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    venue: T.object.isRequired,
    setVenueFilter: T.func.isRequired,
    closeDropdown: T.func.isRequired
  },

  tagName: 'li',

  classNames: ['gigs-app-event-list-filter__venue-item'],

  click() {
    this.closeDropdown();
  }
});
