import fetch from 'fetch';
import config from 'gigshq/config/environment';
import HTTPStatuses from 'gigshq/constants/http-statuses';
import { RuntimeError, NetworkError } from 'gigshq/constants/error-types';

export default {
  create({email, password}) {
    const data = {
      email,
      password,
      // eslint-disable-next-line camelcase
      grant_type: 'password'
    };

    return fetch(config.API.AUTHENTICATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .catch(error => new RuntimeError(error))
      .then(request => {
        switch (request.status) {
          case HTTPStatuses.CREATED:
            return request.json().catch(() => {
              throw new RuntimeError();
            });
          default:
            throw new NetworkError();
        }
      });
  }
};
