define("gigshq/graphql/queries/venue-offer-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "VenueOfferCreate"
      },
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "viewer"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "InlineFragment",
              "typeCondition": {
                "kind": "NamedType",
                "name": {
                  "kind": "Name",
                  "value": "User"
                }
              },
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "tenant"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "venues"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "isEventCreation"
                    },
                    "value": {
                      "kind": "BooleanValue",
                      "value": true
                    }
                  }, {
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "orderParams"
                    },
                    "value": {
                      "kind": "ListValue",
                      "values": [{
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "attribute"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "name"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "order"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "ASC"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "caseInsensitive"
                          },
                          "value": {
                            "kind": "BooleanValue",
                            "value": true
                          }
                        }]
                      }, {
                        "kind": "ObjectValue",
                        "fields": [{
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "attribute"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "id"
                          }
                        }, {
                          "kind": "ObjectField",
                          "name": {
                            "kind": "Name",
                            "value": "order"
                          },
                          "value": {
                            "kind": "EnumValue",
                            "value": "ASC"
                          }
                        }]
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "formattedAddress"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "maxedActiveOffers"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "profileImage"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "imageUrl"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 501,
      "source": {
        "body": "\n  query VenueOfferCreate {\n    viewer {\n      ... on User {\n        id\n\n        tenant {\n          id\n        }\n\n        venues(\n          isEventCreation: true\n          orderParams: [\n            {attribute: name, order: ASC, caseInsensitive: true}\n            {attribute: id, order: ASC}\n          ]\n        ) {\n          id\n          name\n          formattedAddress\n          maxedActiveOffers\n\n          profileImage {\n            id\n            imageUrl\n          }\n        }\n      }\n    }\n  }\n",
        "name": "GraphQL request",
        "locationOffset": {
          "line": 1,
          "column": 1
        }
      }
    }
  };
  _exports.default = _default;
});
