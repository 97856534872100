import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  propTypes: {
    isOpen: T.bool.isRequired
  },

  accountManager: service('managers/account-manager'),
  artistUserType: USER_TYPE_ARTIST,
  tenantUserType: USER_TYPE_TENANT,
  memberUserType: USER_TYPE_MEMBER,

  aboutUserTypes: [
    {
      type: USER_TYPE_MEMBER,
      label: 'members',
    },
    {
      type:USER_TYPE_TENANT,
      label: 'tenants',
    }
  ],

  userTypes: [
    {
      type: USER_TYPE_MEMBER,
      label: 'members',
    },
    {
      type: USER_TYPE_ARTIST,
      label: 'artist_managers',
    },
    {
      type: USER_TYPE_TENANT,
      label: 'tenants',
    }
  ],

  actions: {
    toggleMenu() {
      this.toggleProperty('isOpen');
    },
  }
});
