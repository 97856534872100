import Cookies from 'js-cookie';
import config from 'gigshq/config/environment';

export default {
  async persist(session) {
    Cookies.set(config.Session.STORAGE_KEY, JSON.stringify(session));

    return session;
  }
};
