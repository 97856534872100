import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import config from 'gigshq/config/environment';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  cookies: service(),

  propTypes: {
    close: T.func,
    isOpen: T.bool.isRequired
  },

  didInsertElement() {
    setTimeout(() => {
      this.showPopup();
    }, config.APP.MAILCHIMP_POPUP_DELAY_IN_SECONDS * 1000);
  },

  showPopup() {
    const popupClosedCookie = this.cookies.read('MCPopupClosed');

    if (!popupClosedCookie) {
      this.set('isOpen', true);
    }
  },

  actions: {
    hideMailchimpModal() {
      this.cookies.write(
        'MCPopupClosed',
        true,
        {
          path: '/',
          expires: moment()
            .add(2, 'days')
            .toDate()
        }
      );
      this.set('isOpen', false);
    }
  }
});
