import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import config from 'gigshq/config/environment';
import EventMetaBuilder from 'gigshq/utils/event-meta-builder';
import PageMetaBuilder from 'gigshq/utils/page-meta-builder';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import ResetScrollMixin from 'gigshq/mixins/reset-scroll-position';

// Queries
import query from 'gigshq/graphql/queries/event';

export default Route.extend(Localizable(translations), ResetScrollMixin, {
  accountManager: service('managers/account-manager'),
  apollo: service(),
  can: service(),
  fastboot: service(),
  headData: service('head-data'),
  i18n: service(),
  router: service(),

  beforeModel() {
    if (this.get('fastboot.isFastBoot')) return;

    if (window.google && window.google.maps) return;

    return new RSVP.Promise((resolve, reject) => {
      $.getScript(config.Google.PLACES_SDK_SCRIPT_URL)
        .done(resolve)
        .fail(reject);
    });
  },

  model({slug}) {
    const variables = {slug};

    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  titleToken(model) {
    const {i18n} = this;
    const event = model.event;

    return EventMetaBuilder.title({i18n, event});
  },

  afterModel(model) {
    if (this.can.cannot('view public event', model.event, {})) {
      return this.transitionTo('web');
    }

    this.accountManager.setAccount(model);
    this._setOpenGraphTags(model);
  },

  _setOpenGraphTags(model) {
    const {i18n, fastboot, localizablePrefix} = this;

    const event = model.event;
    const venue = event.venue;
    const shareableProfile = event.shareableProfile;

    const slug = event.slug;
    const venueSlug = event.venue.slug;
    const path = this.router.urlFor('web.events.show', {
      // eslint-disable-next-line camelcase
      venue_slug: venueSlug,
      slug
    });
    const meta = PageMetaBuilder.build({
      i18n,
      localizablePrefix,
      fastboot,
      path
    });

    meta.title = EventMetaBuilder.title({i18n, event});
    meta.description = EventMetaBuilder.description({i18n, event, venue});
    meta.opengraph.image = EventMetaBuilder.image({shareableProfile});
    meta.opengraph.imageWidth = EventMetaBuilder.imageWidth();
    meta.opengraph.imageHeight = EventMetaBuilder.imageHeight();

    this.headData.setProperties(meta);
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
